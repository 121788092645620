/* eslint-disable max-len */

export default {
  title: 'Para continuar, verifica tu número telefónico',
  info: 'Para continuar, ingresa el código que hemos enviado a tu número',
  info2: 'para verificar tu información.',
  noCode: '¿No recibiste el código?',
  changePhone: {
    title: 'Cambiar número',
    info: 'Asegúrate de que tu número sea correcto antes de continuar',
    input: 'Ingresa tu número celular a 10 digitos',
  },
  errors: {
    pinText: 'El código que enviaste no es válido.',
    phone: 'Número de teléfono inválido',
  },
  success: {
    text: 'Código válido',
  },
};
