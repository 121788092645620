/* eslint-disable max-len */

export default {
  title: 'Cuenta bancaria',
  contpaqi: {
    clabeInfo: {
      pf: 'Ingresa la CLABE de tu cuenta bancaria; asegúrate de ingresarla correctamente, ya que  en ella se depositará el monto de tus ventas con CONTAPQi® Ligas de pago. Además, adjunta un estado de cuenta para verificar que la cuenta es tuya.',
      pm: 'Ingresa la CLABE de tu cuenta bancaria; asegúrate de ingresarla correctamente, ya que  en ella se depositará el monto de tus ventas con CONTAPQi® Ligas de pago. Además, adjunta un estado de cuenta para verificar que la cuenta está a nombre de la razón social que estás registrando.',
    },
    uploadFileStep: '2. Estado de cuenta',
  },
  genericPartner: {
    clabeInfo:
      'Ingresa la CLABE de tu cuenta bancaria, en ella se depositará el monto de tus ventas con Oyster, asegurate de ingresarla correctamente, adjunta también la carátula de tu estado de cuenta para asegurarnos que la cuenta pertenece al negocio.',
    uploadFileStep: '2. Carátula del estado de cuenta.',
  },
  clabeInfo: {
    pf: 'Ingresa la CLABE de tu cuenta bancaria, en ella se depositará el monto de tus ventas con Oyster, asegurate de ingresarla correctamente, adjunta también un estado de cuenta para así asegurarnos que la cuenta es tuya. <strong>La cuenta deberá estar a tu nombre.</strong>',
    pm: 'Ingresa la CLABE de tu cuenta bancaria, en ella se depositará el monto de tus ventas con Oyster, asegurate de ingresarla correctamente, adjunta también un estado de cuenta para así asegurarnos que la cuenta es tuya. <strong>La cuenta deberá estar a nombre de la empresa.</strong>',
  },
  clabeStep: '1. Ingresa la CLABE de tu cuenta bancaria',
  uploadFileStep: '2. Estado de cuenta',
  clabe: 'Ingresa la CLABE',
  bank: 'Banco',
  clabeError: 'La cuenta clabe no es válida',
  acceptedFileTypes: 'Archivos permitidos: .jpg, .png, .pdf. Máximo 20 MB.',
  fileSizeError: 'El documento excede el límite de 20 MB',
  termsSection: 'Términos y Condiciones',
  termsDescription: 'Lee cuidadosamente nuestros términos y condiciones y acepta para continuar',
  termsLabel: 'He leído y acepto los términos y condiciones relacionados con el contrato.',
  contract: {
    header:
      'CONTRATO DE USO DE ENLACE DE COBRO A DISTANCIA (EN ADELANTE, EL “CONTRATO”) QUE CELEBRAN EN LA FECHA INDICADA EN LA APLICACIÓN DE OYSTER CORRESPONDIENTE A ESTE INSTRUMENTO (EN ADELANTE, LA “FECHA DE ACEPTACIÓN”), POR UNA PARTE, OSTREA SOLUTIONS, S.A. DE C.V., (EN ADELANTE, “OYSTER”) Y POR OTRA PARTE LA PERSONA FÍSICA O MORAL CUYO NOMBRE COMPLETO, DATOS PERSONALES Y ACEPTACIÓN DEL CONTRATO QUEDARON REGISTRADOS EN LA APLICACIÓN DE OYSTER CORRESPONDIENTE AL PRESENTE CONTRATO, SEGÚN CORRESPONDA, (EN LO SUCESIVO, EL “CLIENTE OYSTER” Y EN CONJUNTO CON OYSTER SE LES DENOMINARÁ LAS “PARTES”), AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:',
    declarations: 'DECLARACIONES',
    declaration1: {
      title: 'Declara Oyster, a través de su representante, que:',
      1: 'Es una sociedad mercantil constituida de conformidad con las leyes de México, legalmente registrada ante las autoridades competentes para operar como una sociedad financiera de objeto múltiple, entidad no regulada;',
      2: 'Su representante cuenta con los poderes, las facultades y las atribuciones necesarias para celebrar el presente Contrato, mismas que no le han sido revocadas o limitadas en forma alguna;',
      3: 'Su clave de Registro Federal de Contribuyentes es OSO1902063A0;',
      4: 'Señala como domicilio convencional para todos los avisos y las notificaciones relacionadas con este Contrato, el ubicado en Puebla número exterior 237, cuarto piso, Roma Norte, C.P. 06700, Cuauhtémoc, Ciudad de México;',
      5: 'La dirección de su sitio web es https://www.oyster.io;',
      6: 'Es su intención otorgar un enlace de cobro de fondos a través de la plataforma web de Oyster al Cliente Oyster conforme a lo dispuesto en el presente Contrato.',
    },
    declaration2: {
      title: 'Declara el Cliente, por su propio derecho, que:',
      1: 'Es una persona física, mayor de edad, o moral, cuyos datos personales se establecen en la Aplicación de Oyster correspondiente al presente Contrato, que cuenta con la capacidad económica y legal para responder del cumplimiento de las obligaciones que derivan a su cargo conforme a este Contrato;',
      2: 'Es su deseo que Oyster le otorgue un enlace de cobro de fondos conforme a lo previsto en el presente Contrato. ',
      3: 'Señala como domicilio convencional para todos los avisos y las notificaciones relacionadas con este Contrato, el que se indica en la Aplicación de Oyster.',
      4: 'Oyster informó y explicó al Cliente Oyster, previo a la aceptación del presente instrumento, todos y cada uno de los derechos y obligaciones contraídos mediante el presente instrumento, así como de aquellos documentos, cargos o comisiones originados por la celebración del presente Contrato.',
      5: 'Los recursos económicos para el cumplimiento de las obligaciones que en términos del Contrato contrae a su cargo, son de su propiedad y provienen de fuentes lícitas.',
      6: 'Toda la información y la documentación proporcionada al Oyster es veraz y auténtica.',
      7: 'El Cliente Oyster proporcionará al Oyster toda aquella información y documentación que sea necesaria para dar cumplimiento oportuno y total a la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, sus reglas de carácter general y demás disposiciones relacionadas vigentes o que entren en vigor en un futuro.',
      8: 'Previamente a la suscripción del presente Contrato, ha aceptado expresamente los términos y condiciones de uso de sitio web y el aviso de privacidad de Oyster.',
    },
    declaration3: {
      title: 'Declaran las Partes conjuntamente que:',
      1: 'Reconocen y manifiestan que no hay lesión, dolo, mala fe, error o violencia, que vicie el consentimiento que expresan, y que el presente Contrato es justo e igualitario para ambas Partes.',
      2: 'Se reconocen recíprocamente la capacidad jurídica con la que se ostentan.',
    },
    clauses: 'CLÁUSULAS',
    chapter1: {
      title: 'Capítulo I',
      subtitle: 'Definiciones, Objeto y Pagos',
      clause1: {
        title: 'PRIMERA.  Definiciones.',
        content: {
          description:
            'Para mejor claridad de lo expresado en el presente instrumento, utilizaremos términos definidos.  Para efectos de este Contrato, los términos que aparezcan definidos en mayúsculas y que no hayan sido definidos de forma distinta en diversas secciones del presente documento, tendrán el significado que a continuación se les atribuye:\n\n',
          definition1: 'Aplicación de Oyster y/o plataforma web de Oyster: ',
          meaning1:
            'es una aplicación de software accesible mediante navegadores de escritorio, para que el Cliente Oyster pueda realizar las siguientes actividades (en adelante los “Servicios”):',
          list:
            'Visualizar y procesar diferentes cobros de fondos a distancia con tarjetas de débito, crédito y efectivo/' +
            'Solicitar pagos a Usuarios o cuentahabientes/' +
            'Realizar pagos de servicios/' +
            'Presentar reclamaciones y aclaraciones',
          footerTextList:
            'Algunas de las funcionalidades o Servicios podrían no estar disponibles durante la consulta del presente Contrato.',
          definition2: 'Oyster: ',
          meaning2: 'Ostrea Solutions S.A. de C.V.: ',
          definition3: 'Plan Oyster: ',
          meaning3:
            'Es el uso mensual por el acceso de la Aplicación de Oyster. El Plan no tendrá costo.',
        },
      },
      clause2: {
        title: 'SEGUNDA.  Objeto.',
        content: {
          description1:
            'Sujeto a los términos y condiciones aquí establecidos, Oyster otorga al Cliente Oyster un enlace de cobro de fondos a través de la plataforma web de Oyster, en el cual podrá agregar los detalles correspondientes al servicio, nombre y correo de la persona física o moral a quien va dirigido, producto, cantidad y precio. Una vez que se tenga la totalidad de los datos, se podrá compartir el enlace recién creado, mismo que contendrá un código escaneable, a través de correo electrónico, Whatsapp o redes sociales.\n\n' +
            'Una vez que la persona física o moral en cuestión haya realizado el fondeo, se reflejarán los fondos en la cuenta establecida por el Cliente Oyster al día hábil siguiente de haber completado dicho fondeo.\n\n' +
            'Oyster realiza un corte de todos los pagos realizados por un enlace de cobro de fondos a distancia en relación a  las cuentas establecidas por cada uno de los Clientes Oyster, todos los días a las 23:59 horas; todos los fondos derivados por pagos capturados se reflejarán al día siguiente hábil al final del día en la cuenta establecida por el Cliente Oyster.\n\n' +
            'El Cliente Oyster podrá dar seguimiento del procesamiento del cobro de fondos a distancia a través de la plataforma de Oyster, en donde tendrá un listado y podrá observar si se encuentra pendiente, pagado o depositado. El Cliente Oyster no podrá solicitar un reembolso de fondos procesados por este medio.\n\n' +
            'Las tiendas de conveniencia tendrán un límite de fondos a procesar, conforme a lo siguiente:',
          table:
            'Tienda-Límites' +
            '/Walmart-$10,000.00 pesos' +
            '/Bodega Aurrera-$10,000.00 pesos' +
            '/Sam’s Club-$10,000.00 pesos' +
            '/Superama-$10,000.00 pesos' +
            '/7 Eleven-$5,000.00 pesos' +
            '/Soriana-$10,000.00 pesos' +
            '/Círculo K-$5,000.00 pesos' +
            '/Extra-$5,000.00 pesos' +
            '/La Comer-$10,000.00 pesos' +
            '/Cali Max-$10,000.00 pesos' +
            '/Farmacias Roma-$5,000.00 pesos' +
            '/BBVA Bancomer-$20,000.00 pesos' +
            '/Banorte-$20,000.00 pesos' +
            '/Farmacias Benavides-$15,000.00 peso' +
            '/Farmacia de Dios-$5,000.00 pesos' +
            '/Farmacentro-$5,000.00 pesos' +
            '/Farmatodo-$5,000.00 pesos' +
            '/Farmacia Nosarco-$5,000.00 pesos' +
            '/Farmacia Santa Cruz-$5,000.00 pesos' +
            '/Farmacia San Francisco de Asis-$5,000.00 pesos' +
            '/Farmacia Unión-$5,000.00 pesos' +
            '/Farmacia Zapotlán-$5,000.00 pesos',

          description2:
            'Respecto a los contracargos que se puedan presentar derivado de la naturaleza de un cobro de fondos a distancia o del resultado del procesamiento en general de cobros de esta índole, Oyster se reserva el derecho a descontar el monto del contracargo del depósito del Usuario en la siguiente dispersión. \n\n' +
            'De la misma manera, Oyster se reserva el derecho a negar este procesamiento a tiendas de conveniencia que no cumplan con las Políticas internas de Oyster. En caso de mal uso, Oyster puede cancelar el servicio sin previo aviso. \n\n' +
            'El funcionamiento del procesamiento de cobro de fondos a distancia a través de enlace está descrito en los términos y condiciones de la aplicación de Oyster, mismos que se encuentran en https://oyster.io/terms/.',
        },
      },
      clause3: {
        title: 'TERCERA.  Obligación de Pago.',
        content: {
          description:
            'El Cliente Oyster se obliga a pagar sin necesidad de requerimiento, notificación, protesto o aviso alguno, la totalidad de las comisiones aplicables. Asimismo, se obliga a pagar los impuestos y demás que al amparo de este clausulado se causen, el Impuesto al Valor Agregado o cualquier otro impuesto aplicable y que se establezca en las leyes respectivas; así como cualquier otro importe que se genere a cargo del Cliente Oyster y que legalmente le corresponda pagar.',
        },
      },
      clause4: {
        title: 'CUARTA. Comisiones.',
        content: {
          description:
            'Las Partes acuerdan que el acceso y uso de la Aplicación de Oyster no tendrá costo.\n\n' +
            'Respecto al procesamiento de cobro de fondos a distancia, las comisiones serán las siguientes:\n\n',
          points1:
            'Pago con tarjeta/' +
            'Tiene una comisión del 0% hasta el 31 de diciembre de 2021, topado a ventas acumuladas de hasta $40 millones de pesos. Se aplicará una comisión de 2.5% + $2 + IVA al excedente de esa cantidad.' +
            '-Posterior al 31 de diciembre de 2021, la comisión será de 2.5% + $2 + IVA.',
          points2:
            'Pago en efectivo/' +
            'Tiene una comisión del 0% hasta el 31 de diciembre de 2021, topado a ventas acumuladas de hasta $40 millones de pesos. Se aplicará una comisión de $10+ IVA al excedente de esa cantidad. La tienda le cobrará una comisión adicional al usuario final.' +
            '-Posterior al 31 de diciembre de 2021, la comisión será de $10 + IVA.',
        },
      },
    },
    chapter2: {
      title: 'Capítulo II',
      subtitle: 'Vigencia',
      clause1: {
        title: 'QUINTA.  Vigencia.',
        content: {
          description:
            'La vigencia del Contrato iniciará a partir de su Fecha de Aceptación, y terminará en la fecha que se cumplan en su totalidad las obligaciones que el Cliente Oyster contrae en el presente instrumento.  El presente Contrato podrá será prorrogable.\n\n',
          definition1: 'Aplicación de Oyster y/o plataforma web de Oyster: ',
        },
      },
    },
    chapter3: {
      title: 'Capítulo III',
      subtitle: 'Notificaciones y Aclaraciones',
      clause1: {
        title: 'SEXTA.  Notificaciones y Domicilios.',
        content: {
          description:
            'Cualquier notificación, comunicación o aviso relacionados con el presente Contrato deberá constar por escrito y estar firmado por las personas autorizadas de las Partes para tal efecto, dirigida a las direcciones señaladas en el apartado de Declaraciones del presente instrumento o bien enviarse por medios electrónicos con confirmación de recibido y de conocimiento del mensaje.\n\n' +
            'El Cliente Oyster reconoce y acepta que cualquier aviso o notificación que Oyster le tenga que dar a conocer relacionada con el Contrato será efectuada mediante: (i) un mensaje contenido en la aplicación de Oyster o un comunicado por escrito enviado al domicilio del Cliente Oyster; o (ii) un mensaje dado a conocer a través del correo electrónico del Cliente Oyster.\n\n' +
            'El Cliente Oyster deberá notificar a Oyster cualquier cambio de domicilio o de correo electrónico mediante escrito entregado en cualquier las oficinas de Oyster o, en su defecto a través del correo electrónico designado por Oyster.\n\n' +
            'En caso de que no sea notificado por las Partes el cambio de domicilio en los términos pactados en el Contrato, las notificaciones que se realicen en los domicilios previamente señalados surtirán plenos efectos legales.',
        },
      },
      clause2: {
        title: 'SÉPTIMA.  Aclaraciones.',
        content: {
          description:
            'En caso de que el Cliente Oyster no esté de acuerdo con alguno de los movimientos que aparezcan en la Aplicación de Oyster, podrá solicitar a Oyster la aclaración correspondiente; Oyster acusará de recibida la aclaración correspondiente sellando la misma o recibirá la aclaración vía telefónica, en ambos casos se generará un número de folio, el cual el Cliente Oyster deberá conservar para seguimiento.\n\n' +
            'Una vez recibida la solicitud de aclaración, Oyster tendrá un plazo de cuarenta y cinco (45) días naturales para entregar al Cliente Oyster el dictamen correspondiente.  Dentro del plazo antes referido, Oyster estará facultado para solicitar al Cliente Oyster información o documentación adicional relacionada con su aclaración, la cual en caso de no ser proporcionada por el Cliente Oyster, dará derecho al Oyster para declararla improcedente.  En el plazo señalado, Oyster entregará al Cliente Oyster la respuesta a través de los medios acordados al momento de la presentación de la aclaración y, en su defecto a su correo electrónico.\n\n' +
            'Para efectos de lo previsto en la presente Cláusula, el Cliente Oyster pone a disposición de Oyster los datos de contacto de Servicio al Cliente:\n\n' +
            'Teléfono:                                55 5350 9088\n\n' +
            'Correo electrónico:             help@oyster.io\n\n' +
            'Asimismo, Oyster pone a disposición del Cliente Oyster los datos de contacto de la Procuraduría Federal del Consumidor:\n\n' +
            'Teléfonos:                           55 55688722 en la Ciudad de México y Área Metropolitana\n' +
            '                                     800 4688722 sin costo para el consumidor\n\n' +
            'Correo electrónico:                asesoria@profeco.gob.mx\n\n' +
            'Página de Internet:                www.profeco.gob.mx',
        },
      },
      clause3: {
        title: 'OCTAVA.  Consultas.',
        content: {
          description:
            'El Cliente Oyster podrá conocer sus operaciones en la Aplicación de Oyster.\n\n ',
        },
      },
    },
    chapter4: {
      title: 'Capítulo IV',
      subtitle: 'Disposiciones Generales',
      clause1: {
        title: 'NOVENA.  Cesión.',
        content: {
          description:
            'El Cliente Oyster podrá ceder los derechos y las obligaciones a su cargo conforme al presente Contrato, siempre y cuando obtenga el consentimiento previo y por escrito de Oyster.\n\n' +
            'Oyster podrá ceder en cualquier momento los derechos y las obligaciones a su cargo, bastando simplemente la notificación al Cliente Oyster.',
        },
      },
      clause2: {
        title: 'DÉCIMA.  Modificaciones.',
        content: {
          description:
            'Las estipulaciones previstas en el presente Contrato podrán ser modificadas, adicionadas o enmendadas por Oyster, en cuyo caso, deberá dar a conocer al Cliente Oyster por correo electrónico las modificaciones correspondientes. En caso de no estar conforme con las modificaciones planteadas, el Cliente Oyster podrá solicitar la terminación anticipada del Contrato.  En caso de que el Cliente Oyster no manifieste oposición alguna, se reputarán aceptadas las modificaciones.',
        },
      },
      clause3: {
        title: 'DÉCIMA PRIMERA.  Nulidad.',
        content: {
          description:
            'La declaración de nulidad de alguna parte o cláusula de este Contrato no afectará de manera alguna la exigibilidad de las obligaciones que quedaren subsistentes.',
        },
      },
      clause4: {
        title: 'DÉCIMA SEGUNDA.  Encabezados.',
        content: {
          description:
            'Los encabezados de las secciones y cláusulas contenidas en el presente Contrato únicamente se encuentran insertos para efectos de referencia, y no se tomarán como parte del presente Contrato para ningún fin, ni definirán ni afectarán en manera alguna la forma, el significado, la interpretación o el alcance de cualquiera de las disposiciones contenidas en el presente.',
        },
      },
      clause5: {
        title: 'DÉCIMA TERCERA.  Suscripción del Contrato.',
        content: {
          description:
            'El presente Contrato será aceptado por las Partes de manera electrónica. El presente Contrato será enviado de manera recíproca entre las Partes, a través de medios electrónicos de comunicaciones por Internet y constituyen un acuerdo de voluntades entre Oyster y el Cliente Oyster, por lo que al acceder a la Aplicación de Oyster y continuar con el proceso de apertura se estará aceptando el presente Contrato y declara ser mayor de edad así como estar en pleno goce y ejercicio de sus facultades para contratar o contraer cualquier clase de obligaciones. ',
        },
      },
      clause6: {
        title: 'VIGÉSIMA.  Datos Personales.',
        content: {
          description:
            'Oyster efectuará el tratamiento de los datos personales del Cliente Oyster de conformidad con su Aviso de Privacidad, así como de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás normativa que resulte aplicable. \n\n' +
            'El Cliente Oyster afirma conocer y entender el aviso de privacidad de Oyster.',
        },
      },
      clause7: {
        title: 'VIGÉSIMA PRIMERA.  Legislación Aplicable y Jurisdicción.',
        content: {
          description:
            'El presente Contrato será regido e interpretado de acuerdo con las leyes mercantiles, sustantivas y adjetivas, aplicables en los Estados Unidos Mexicanos. Para todo lo relacionado con la interpretación, validez y cumplimiento del presente Contrato, las Partes expresamente se someten a la jurisdicción de los tribunales competentes situados en la Ciudad de México, renunciando expresamente a cualquier otro fuero que por razón de sus actuales o futuros domicilios les correspondan o pudieran corresponderles, tanto en el presente como en el futuro.\n\n' +
            'LEÍDO QUE FUE EL PRESENTE CONTRATO POR LAS PARTES Y CONOCIENDO SU VALOR, CONTENIDO Y ALCANCE LEGAL, LAS PARTES LO ACEPTAN EN LA CIUDAD DE MÉXICO EN LA FECHA INDICADA EN LA APLICACIÓN DE OYSTER CORRESPONDIENTE A ESTE INSTRUMENTO.',
        },
      },
    },
  },
};
