import { getSnapshot, applySnapshot, flow, types, Instance } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import endpoints from '@configs/endpoints';
import { initialNextScreen } from '@configs/variables';
import { isEmailValid, isPasswordValid } from '@utils/validators';
import AuthCookie from '@utils/authCookie';
import generateDeviceId from '@utils/generateDeviceId';
import Common from './common';

const initialPassword = isPasswordValid('');
const SUSPEND_KEY = 'suspendida';

export const PasswordValid = types.model({
  ...initialPassword,
});

const Auth = types
  .compose(
    Common,
    types.model({
      fingerprint: '',
      deviceId: '',
      email: '',
      pin: '',
      emailValid: true,
      passwordValid: PasswordValid,
      emailError: '',
      emailBackendValidationError: false,
      preverificationCheckError: types.union(types.boolean, types.null),
      password: '',
      verificationToken: '',
      isAuthenticated: false,
      registerSubmitted: false,
      submitting: false,
      token: '',
      resetCode: '',
      twoFactorAuthEnabled: false,
      errorMsg: '',
    }),
  )
  .views((self) => ({
    get isEmailSet() {
      return self.email.trim().length > 0;
    },
    get duplicatedEmailError() {
      return self.emailBackendValidationError && self.emailError === 'DUPLICATE';
    },
    get isPasswordValid() {
      return (
        self.passwordValid.hasNumber &&
        self.passwordValid.hasCasing &&
        self.passwordValid.validLength &&
        self.passwordValid.hasSpecialCharacter
      );
    },
    get pinCanSubmit() {
      return self.pin.length === 6;
    },
  }))
  .actions((self) => {
    let initialState = {};

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        const email = self.email;
        applySnapshot(self, initialState);
        self.email = email;
        self.isAuthenticated = false;
      },
    };
  })
  .actions((self) => ({
    logoutReset: () => {
      const { user, app, business } = self.globalStore;

      self.resetStore();
      user.resetStore();
      app.resetStore();
      business.resetStore();
    },
    generateDeviceId: (forceGeneration?: boolean) => {
      const deviceIdCookie = AuthCookie.getDeviceIdCookie();
      if (forceGeneration || !deviceIdCookie) {
        const deviceId = self.email ? generateDeviceId(self.email) : uuidv4();
        self.deviceId = deviceId;
        AuthCookie.setDeviceIdCookie(deviceId);
      } else {
        self.deviceId = deviceIdCookie;
      }
      return self.deviceId;
    },
    loginSuccess: (token: string) => {
      AuthCookie.setCookie(token);
      self.twoFactorAuthEnabled = false;
      self.token = token;
      self.password = '';
      self.pin = '';
      self.isAuthenticated = true;
    },
  }))
  .actions((self) => ({
    registerToLogin: () => {
      self.emailValid = true;
      self.emailError = '';
      self.emailBackendValidationError = false;
      self.password = '';
    },
    canSubmitRegister: () => {
      return !(
        self.emailError !== 'DUPLICATE' &&
        self.emailValid &&
        self.isEmailSet &&
        !self.emailBackendValidationError &&
        !self.submitting
      );
    },
    validatePassword: () => {
      self.passwordValid = isPasswordValid(self.password);
    },
    validateEmail: () => {
      self.emailError = '';
      if (self.isEmailSet) {
        self.emailValid = isEmailValid(self.email);
      } else {
        self.emailValid = true;
      }
    },
    setEmail: (email: string) => {
      self.email = email;
      self.emailValid = isEmailValid(self.email);
    },
    generateFingerPrint: flow(function* () {
      try {
        self.submitting = true;
        const fp = yield FingerprintJS.load();
        const { visitorId } = yield fp.get();
        self.fingerprint = visitorId;
      } catch (err) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    checkIfEmailExists: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.checkIfEmailExists(self.email));

        self.emailBackendValidationError = !!data.errorCode;
      } catch (error) {
        self.emailError = error?.errorCode || '';
        self.emailBackendValidationError = true;
      }
    }),
    preverificationRegister: flow(function* () {
      self.generateDeviceId(true);
      self.registerSubmitted = false;
      self.submitting = true;
      try {
        const { data } = yield axios.post(endpoints.preverification(self.email), {});

        if (data.success) {
          self.registerSubmitted = true;
        }
      } catch (error) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    validateUserPreverification: flow(function* (code: string) {
      self.submitting = true;
      try {
        const { data } = yield axios.patch(endpoints.validateUserPreverification(code), {});

        if (data.success) {
          self.preverificationCheckError = false;
        }
      } catch {
        self.preverificationCheckError = true;
      } finally {
        self.submitting = false;
      }
    }),
    createUserAccount: flow(function* () {
      self.submitting = true;
      self.isAuthenticated = false;
      const deviceId = self.generateDeviceId(true);
      try {
        const body = {
          onboardingVersionName: '',
          deviceId,
          verificationToken: self.verificationToken,
          password: self.password,
          nextScreen: initialNextScreen,
          deviceType: 4,
        };

        const { data } = yield axios.post(endpoints.createAccountWithPassword, body);

        const { loginResponse } = data;
        self.loginSuccess(loginResponse.token);
        return data;
      } catch {
        self.isAuthenticated = false;
      } finally {
        self.submitting = false;
      }
    }),

    logout: flow(function* () {
      try {
        yield axios.delete(endpoints.logout, {}).catch((err) => err);
      } finally {
        self.logoutReset();
        AuthCookie.deleteCookie();
      }
    }),
    login: flow(function* () {
      self.generateDeviceId();
      self.errorMsg = '';
      try {
        self.submitting = true;
        const { data } = yield axios.post(endpoints.login, {
          emailPhone: self.email,
          password: self.password,
          deviceInfo: window.navigator.userAgent,
          deviceId: self.deviceId,
          deviceType: 4,
        });
        const { loginResponse, multiFactorAuthLoginResponse } = data;

        AuthCookie.setFingerprintCookie(self.fingerprint);
        self.twoFactorAuthEnabled = !!multiFactorAuthLoginResponse?.twoFactorAuthEnabled;
        if (loginResponse) {
          self.loginSuccess(loginResponse.token);
        }
        return data;
      } catch (error) {
        const isSuspended = error?.msg?.includes(SUSPEND_KEY);
        if (isSuspended) {
          const errorMsg = error.msg.split('Si olvidaste')[0];
          self.errorMsg = errorMsg;
        }
        throw error;
      } finally {
        self.submitting = false;
      }
    }),
    loginWithMfa: flow(function* () {
      try {
        self.submitting = true;
        self.generateDeviceId();
        const { data } = yield axios.post(endpoints.loginWithMfa, {
          loginRequest: {
            emailPhone: self.email,
            password: self.password,
            deviceInfo: window.navigator.userAgent,
            deviceId: self.deviceId,
            deviceType: 4,
          },
          authCode: self.pin,
        });
        if (data.success) {
          const { loginResponse } = data;
          if (loginResponse) {
            self.loginSuccess(loginResponse.token);
          }
        }
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    resendMfaCode: flow(function* () {
      self.errorMsg = '';
      try {
        const deviceId = self.generateDeviceId();
        const { data } = yield axios.post(endpoints.resendMfa, {
          emailPhone: self.email,
          deviceInfo: window.navigator.userAgent,
          deviceType: 4,
          deviceId,
        });
        return data;
      } catch (error) {
        self.errorMsg = error?.msg || '';
        throw error;
      }
    }),
    resetEmail: flow(function* () {
      const deviceId = generateDeviceId(self.email);
      self.deviceId = deviceId;
      try {
        self.submitting = true;
        const { data } = yield axios.post(endpoints.resetEmail, {
          email: self.email,
        });

        return data;
      } finally {
        self.isAuthenticated = false;
        self.submitting = false;
      }
    }),
    verifyResetCode: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.patch(endpoints.verifyResetCode(self.resetCode), {
          code: self.resetCode,
        });
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    resetPassword: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.patch(endpoints.resetPassword, {
          code: self.resetCode,
          newPassword: self.password,
        });
        self.password = '';
        return data;
      } finally {
        self.submitting = false;
      }
    }),
  }));

export interface TAuthStore extends Instance<typeof Auth> {}
export default Auth;
