/* eslint-disable max-len */

export default {
  title: 'Es momento de subir tus documentos',
  identity: {
    title: 'Valida tu identidad',
    info: 'Para este paso, es necesario que cuentes con una identificación oficial a la mano; puede ser tu INE o pasaporte.',
    button: 'Iniciar validación',
  },
  card: {
    title: 'Contáctanos',
    info: 'Uno de nuestros agentes estará disponible para ti, nuestra misión es ayudarte.',
    contact: 'Habla con un agente',
    additionalInfo:
      'Es importante que este paso sea efecturado por el apoderado legal de la empresa.',
  },
  uploadDocument: {
    typeLabel: 'Selecciona el tipo de documento',
    voterId: 'INE',
    passport: 'Pasaporte',
    fileSizeError: 'El documento excede el límite de 20 MB',
    voterIdFrontLabel: 'Selecciona o arrastra la parte frontal de tu INE',
    voterIdBackLabel: 'Selecciona o arrastra la parte trasera de tu INE',
    upload: 'Subir',
    errorMsg: 'Algo salió mal, intenta nuevamente.',
  },
};
