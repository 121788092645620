import axios from 'axios';
import i18n from 'i18next';
import { flow, Instance, types } from 'mobx-state-tree';

import Common from './common';
import endpoints from '../configs/endpoints';

const PhoneVerification = types
  .compose(Common, types.model({}))
  .views((self) => ({
    get app() {
      return self.globalStore.app;
    },
    get user() {
      return self.globalStore.user;
    },
  }))
  .actions((self) => ({
    verifyPhonePin: flow(function* (pin) {
      try {
        self.user.updateField('submitting', true);
        const { data } = yield axios.patch(`${endpoints.verifyPhonePin}${pin}`);
        return data;
      } finally {
        self.user.updateField('submitting', false);
      }
    }),
    savePhone: flow(function* () {
      try {
        const body = {
          phone: self.user.phone.replace(/ /g, ''),
          countryCode: 'MX',
        };
        const { data } = yield axios.patch(endpoints.phone, body);
        self.user.updateField('fullPhoneNumber', `+52${body.phone}`);
        return data;
      } catch (error) {
        const { errorCode = i18n.t('common.errorTitle'), msg = i18n.t('common.errorText') } =
          error as {
            errorCode: string;
            msg: string;
          };
        self.app.openToast({ type: 'error', title: errorCode, message: msg });
      }
    }),
    sendVerificationCode: flow(function* () {
      try {
        yield axios.get(endpoints.sendVerificationCode);
      } catch (error) {
        const { errorCode = i18n.t('common.errorTitle'), msg = i18n.t('common.errorText') } =
          error as {
            errorCode: string;
            msg: string;
          };
        self.app.openToast({ type: 'error', title: errorCode, message: msg });
      }
    }),
    setOnboardingState: flow(function* () {
      try {
        yield axios.patch(endpoints.onboardingState(20));
      } finally {
        /* */
      }
    }),
  }));

export type TPhoneVerification = Instance<typeof PhoneVerification>;

export default PhoneVerification;
