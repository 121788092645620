/* eslint-disable max-len */

export default {
  email: 'Ingresa tu correo electrónico',
  emailExists: 'Tu correo ya está registrado',
  title: 'Iniciemos creando una cuenta para ti',
  info: 'Para comenzar, ingresa tu correo; estos serán tus datos de acceso Oyster. Asegúrate de que tu cuenta de correo esté en uso',
  loginInstruction: 'Inicia sesión para continuar con tu proceso de solicitud de crédito',
  existingRequest: 'Si ya iniciaste tu solicitud,',
  checkEmail: 'Revisa tu correo electrónico',
  registerSuccess: 'Para continuar, haz clic en el enlace que enviaremos a tu correo ',
  registerSuccessCont: ' para verificar tu información',
  noEmailError: '¿No recibiste el correo?',
};
