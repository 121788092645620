import Cookies from 'js-cookie';
import { getExpiration, cookieConfig } from './authCookie';

const cookieName = 'partner';

export default class PartnerCookie {
  static setCookie(value: string): void {
    Cookies.set(cookieName, value, {
      expires: getExpiration(4320),
      ...cookieConfig,
    });
  }

  static getCookie(): string {
    const cookie: string | undefined = Cookies.get(cookieName);
    return cookie || '';
  }

  static deleteCookie(): void {
    Cookies.remove(cookieName, cookieConfig);
  }
}
