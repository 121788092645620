/* eslint-disable max-len */

export default {
  curp: 'CURP',
  name: 'Nombre (s)',
  middleName: 'Apellido paterno',
  lastName: 'Apellido materno',
  birthPlace: 'Lugar de nacimiento',
  male: 'Masculino',
  female: 'Femenino',
  birthDate: 'Fecha de Nacimiento',
  day: 'Día',
  month: 'Mes',
  year: 'Año',
  phone: 'Número telefónico a 10 dígitos',
  curpVerified: 'CURP verificada',
  errors: {
    curp: 'Formato de Curp inválido',
    phone: 'Número de teléfono inválido',
  },
};
