/* eslint-disable max-len */

export default {
  title: 'Iniciar sesión',
  info: 'Ingresa tus datos de usuario',
  forgotPass: 'Olvidé mi contraseña',
  email: 'Ingresa tu correo electrónico',
  password: 'Contraseña',
  noAccount: 'Si aún no tienes cuenta,',
  errorText: 'Correo electrónico o contraseña no válidos.',
  mfa: {
    title: 'Ingresa el código de acceso',
    info: 'Enviamos un código de acceso al correo electrónico asociado a tu cuenta Oyster para asegurarnos de que eres tú.',
    resend: 'Reenviar código',
    password: 'A continuación, ingresa tu contraseña.',
    help: '¿Necesitas ayuda? Contáctanos <0>help@oyster.io</0>',
  },
};
