import loadable from '@loadable/component';
import Loader from '@components/loader';

const Dashboard = loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard" */
      './dashboard'
    ),
  {
    fallback: <Loader />,
  },
);

export default Dashboard;
