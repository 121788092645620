/* eslint-disable max-len */

export default {
  title: 'Es momento de subir tus documentos',
  identity: {
    title: 'Valida tu identidad',
    info: 'Para este paso, es necesario que cuentes con una identificación oficial a la mano; puede ser tu INE o pasaporte.',
    button: 'Iniciar validación',
  },
  card: {
    title: 'Contáctanos',
    info: 'Uno de nuestros agentes estará disponible para ti, nuestra misión es ayudarte.',
    contact: 'Habla con un agente',
    additionalInfo:
      'Recuerda que no podremos proceder con tu registro si no eres el representante legal.',
  },
};
