import { Environments } from '@yaydoo/react-components/lib/utils/Variables';

import screens from '@constants/screens/onboardingScreens';

const env: Exclude<Environments, 'prod1'> =
  (process.env.REACT_APP_ENVIRONMENT as Exclude<Environments, 'prod1'>) || 'local';
const baseUrl: string = process.env.REACT_APP_API_URL || '';
const cookieName: string = process.env.REACT_APP_COOKIE_NAME || '';
const cookieDomain: string = process.env.REACT_APP_COOKIE_DOMAIN || '';
const deviceIdExpMins: number = +(process.env.REACT_APP_DEVICEID_EXP_MINS || 15);
const authApp: string = process.env.REACT_APP_AUTH_APP || '';
const initialNextScreen: string = screens.PERSONAL_INFORMATION_V1;
const hubApp: string = process.env.REACT_APP_HUB_APP || '';

const matiClient: string = process.env.REACT_APP_MATI_CLIENT || '';
const matiGenericFlowId: string = process.env.REACT_APP_GENERIC_MATI_FLOW_ID || '';
const matiPorCobrarFlowId: string = process.env.REACT_APP_POR_COBRAR_MATI_FLOW_ID || '';

const deviceIdFirstChar: string = process.env.REACT_APP_DEVICE_ID_POSITION_FIRST_CHAR || '0';
const deviceIdSecondChar: string = process.env.REACT_APP_DEVICE_ID_POSITION_SECOND_CHAR || '1';

const paymentLinksOnboardingVersionName = 'PAYMENT_LINKS_V1';
const contpaqiOnboardingVersionName = 'CONTPAQI_V1';
const porCobrarOnboardingVersionName = 'POR_COBRAR_V1';

const validOnboardingVersions = [
  paymentLinksOnboardingVersionName,
  contpaqiOnboardingVersionName,
  porCobrarOnboardingVersionName,
];

const fbPixelId: string = process.env.REACT_APP_FB_PIXEL_ID || '';
const gtmId: string = process.env.REACT_APP_GTM_ID || '';
const smartLookId: string = process.env.REACT_APP_SMARTLOOK_ID || '';

export {
  authApp,
  env,
  baseUrl,
  cookieName,
  cookieDomain,
  matiClient,
  matiGenericFlowId,
  matiPorCobrarFlowId,
  initialNextScreen,
  deviceIdFirstChar,
  deviceIdSecondChar,
  deviceIdExpMins,
  fbPixelId,
  gtmId,
  smartLookId,
  hubApp,
  paymentLinksOnboardingVersionName,
  contpaqiOnboardingVersionName,
  porCobrarOnboardingVersionName,
  validOnboardingVersions,
};
