import axios, { AxiosError } from 'axios';
import i18n from 'i18next';
import { flow, Instance, types } from 'mobx-state-tree';

import endpoints from '@configs/endpoints';
import Common from './common';

export const Details = types
  .model({
    bankName: '',
    clabe: '',
    // Track if clabe is valid
    isValid: false,
  })
  .views((self) => ({
    get canSubmit() {
      return self.bankName && self.clabe.length === 18 && self.isValid;
    },
  }))
  .actions((self) => ({
    setValid: (b: boolean) => {
      // We need the negation of b, because its value depends on a error, so:
      // if error is false, that means is valid.
      // if error is true, that means is invaid.
      self.isValid = !b;
    },
  }));

export type DetailsType = Instance<typeof Details>;
export type DetailsKeys = keyof DetailsType;

const DisbursementDetails = types
  .compose(
    Common,
    types.model({
      isLoading: false,
      isSubmitting: false,
      details: types.optional(Details, {}),
    }),
  )
  .views((self) => ({
    get app() {
      return self.globalStore.app;
    },
    get canSubmit() {
      return self.details.canSubmit && !self.isSubmitting;
    },
    get bankClabeMatch() {
      return false;
    },
  }))
  .actions(() => ({
    isString: (field: string | string[] | boolean): field is string => {
      return typeof field === 'string';
    },

    isBoolean: (field: string | string[] | boolean): field is boolean => {
      return typeof field === 'boolean';
    },
  }))
  .actions((self) => ({
    calculateClabeForControlDigit: () => {
      const weightingFactor = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];

      try {
        const changedClabe = self.details.clabe;
        const weightedArrItems = [];
        for (let i = 0; i < changedClabe.length - 1; i++) {
          weightedArrItems.push(parseInt(changedClabe[i]) * weightingFactor[i]);
        }
        let weightedValue = 0;
        weightedArrItems.forEach((item, index) => {
          weightedArrItems[index] = item % 10;
          weightedValue += item % 10;
        });
        const controlDigit = (10 - (weightedValue % 10)) % 10;
        return `${changedClabe.trim().slice(0, -1)}${controlDigit}`;
      } catch (err) {
        throw new Error(err as string);
      }
    },
    updateField: (fieldName: DetailsKeys, value: string | boolean) => {
      const currentValue = self.details[fieldName];
      if (self.isString(currentValue)) {
        (self.details[fieldName] as string) = value as string;
      } else if (self.isBoolean(currentValue)) {
        (self.details[fieldName] as boolean) = value as boolean;
      }
    },
    submitBankAccountDetails: flow(function* (fn?: () => void) {
      try {
        self.isSubmitting = true;
        const bodyClabe = { clabe: self.details.clabe };
        const { data: clabeData } = yield axios.post(endpoints.disbursement, bodyClabe);

        if (!clabeData.success) {
          self.app.openToast({
            type: 'error',
            title: i18n.t('common.errorTitle'),
            message: clabeData.msg || '',
            toastDuration: 4000,
          });
          return;
        }

        self.app.saveNextScreen();

        if (fn) {
          fn();
        }
      } catch (error) {
        const { data = '' } = (error as AxiosError)?.response || {};
        self.app.openToast({
          type: 'error',
          title: i18n.t('common.errorTitle'),
          message: data.msg || error?.msg || '',
          toastDuration: 4000,
        });
      } finally {
        self.isSubmitting = false;
      }
    }),
    validateClabe: flow(function* () {
      try {
        const { data } = yield axios.post(endpoints.getBankFromClabe, {
          clabe: self.details.clabe,
        });
        const { bankInfo } = data;
        const bankName = bankInfo?.bankName;
        if (bankName) {
          self.details.bankName = bankName;
        } else {
          // If there isn't bank name, we set Otro value
          self.details.bankName = 'Otro';
        }
      } catch (error) {
        console.warn(error);
      }
    }),
    submitBankInfoDetails: flow(function* (body) {
      try {
        self.isSubmitting = true;
        const { data: clabeData } = yield axios.post(endpoints.disbursement, body);

        if (!clabeData.success) {
          self.app.openToast({
            type: 'error',
            title: i18n.t('common.errorTitle'),
            message: clabeData.msg || '',
            toastDuration: 4000,
          });
          return;
        }

        self.app.saveNextScreen();
      } catch (error) {
        const { data = '' } = (error as AxiosError)?.response || {};
        self.app.openToast({
          type: 'error',
          title: i18n.t('common.errorTitle'),
          message: data.msg || error?.msg || '',
          toastDuration: 4000,
        });
      } finally {
        self.isSubmitting = false;
      }
    }),
  }));

export type TDisbursementDetailsStore = Instance<typeof DisbursementDetails>;

export default DisbursementDetails;
