export const SPOUSE = 'Esposo/a';
export const PARENT = 'Padre/Madre';
export const GRANDPARENT = 'Abuelo/a';
export const SIBLING = 'Hermano/a';
export const OTHER = 'Otro/a';

export const relation = [
  {
    value: SPOUSE,
    label: SPOUSE,
  },
  {
    value: PARENT,
    label: PARENT,
  },
  {
    value: GRANDPARENT,
    label: GRANDPARENT,
  },
  {
    value: SIBLING,
    label: SIBLING,
  },
  {
    value: OTHER,
    label: OTHER,
  },
];

export type RelationType = 'Esposo/a' | 'Padre/Madre' | 'Abuelo/a' | 'Hermano/a' | 'Otro/a' | '';
