// eslint-disable-next-line max-len
import { TSecurityAuthorizationStore } from '@yaydoo/react-mobx-stores/lib/securityAuthorization';
import i18n from 'i18next';
import { toJS } from 'mobx';
import { applySnapshot, getParent, getRoot, Instance, types } from 'mobx-state-tree';

import { TAppStore } from './app';
import { TAuthStore } from './auth';
import { TBeneficiariesStore } from './beneficiaries';
// import { TBusinessStore } from './business'; This throws typeing error
import { TUIStore } from './ui';
import { TUserStore } from './user';

interface IRootStore {
  auth: TAuthStore;
  ui: TUIStore;
  user: TUserStore;
  app: TAppStore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  business: Instance<any>;
  beneficiaries: TBeneficiariesStore;
  securityAuth: TSecurityAuthorizationStore;
}

const Common = types
  .model({})
  .views((self) => ({
    get globalStore() {
      return getRoot<IRootStore>(self);
    },
  }))
  .actions((self) => {
    let initialState = {};

    return {
      afterCreate: () => {
        initialState = toJS(self);
      },
      getTree<T = Record<string, unknown>>(depth?: number) {
        return getParent(self, depth) as T;
      },
      resetStore<T = typeof self>(currentStore?: T) {
        applySnapshot(self, { ...initialState, ...currentStore });
      },
      replace(data: typeof self) {
        applySnapshot(self, data);
      },
      update<T = typeof self>(data: T) {
        applySnapshot(self, {
          ...self,
          ...data,
        });
      },
      updateField<T = unknown>(field: string, value: T) {
        applySnapshot(self, { ...self, [field]: value });
      },
      handleError: (error: { error?: string; meta?: { message: string } }) => {
        const { app } = self.globalStore;

        app.openToast({
          type: 'error',
          title: 'Error!',
          message: error?.error || error?.meta?.message || i18n.t('common.errorText'),
          toastDuration: 4000,
        });
      },
    };
  });

export default Common;
