import Cookies from 'js-cookie';

export const getExpiration = (minutes: number): Date =>
  new Date(new Date().getTime() + 60 * minutes * 1000);
const cookieName = 'isOysterAutomaticLogout';

/* Automatic logout cookie */
export default class AutomaticLogoutCookie {
  static setCookie(value: string | number): void {
    Cookies.set(cookieName, String(value), { expires: getExpiration(2) });
  }

  static getCookie(): number {
    const cookie: string | undefined = Cookies.get(cookieName);
    return +(cookie || 0);
  }

  static deleteCookie(): void {
    Cookies.remove(cookieName);
  }
}
