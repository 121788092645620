/* eslint-disable max-len */

export default {
  title: 'Recuperar contraseña',
  info: 'Ingresa tu correo electrónico, te enviaremos las instrucciones para cambiar tu contraseña',
  email: 'Ingresa tu correo',
  passwordTitle: 'Ingresa nueva contraseña',
  successDescription: 'Tu contraseña fue cambiada exitosamente',
  resetRequest: {
    title: 'Revisa tu correo',
    info: 'Enviamos las instrucciones para cambiar tu contraseña',
  },
  errors: {
    NOTFOUND: 'El email ingresado no esta registrado.',
    CODE: 'El enlace es incorrecto o a expirado',
  },
};
