import { Instance, types } from 'mobx-state-tree';
import { themePartnerNames } from '@yaydoo/react-components/lib/constants';

import Common from './common';

const devices = {
  android: 'Android',
  winPhone: 'Windows Phone',
  iOS: 'iOS',
};

const UI = types
  .compose(
    Common,
    types.model({
      hideMobileHeader: true,
      isMobileScreen: false,
      deviceType: '',
      isKeyboardPopped: false,
      width: 0,
      height: 0,
      partner: themePartnerNames.NONE,
    }),
  )
  .views((self) => ({
    get headerHeight() {
      const elem = document.getElementById('contentHeader');
      return elem?.offsetHeight || 0;
    },
    get formSpacing() {
      return self.isMobileScreen ? 2 : 3;
    },
  }))
  .actions((self) => ({
    detectDeviceType: () => {
      const userAgent = navigator?.userAgent || navigator?.vendor;

      // Windows Phone must come first because its UA also contains 'Android'
      if (/windows phone/i.test(userAgent)) return (self.deviceType = devices.winPhone);

      if (/android/i.test(userAgent)) return (self.deviceType = devices.android);

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (
        /iPad|iPhone|iPod/.test(userAgent) &&
        !(window as unknown as { MSStream: boolean }).MSStream
      )
        return (self.deviceType = devices.iOS);
    },
    isIOS: () => self.deviceType === devices.iOS,
    /* return android even for windows phone devices */
    isAndroid: () => self.deviceType === devices.android || self.deviceType === devices.winPhone,
    setIsKeyboardPopped: (isKeyboardPopped: boolean) => {
      self.isKeyboardPopped = isKeyboardPopped;
    },
  }));

export type TUIStore = Instance<typeof UI>;

export default UI;
