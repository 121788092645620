import axios from 'axios';
import { flow, types, getRoot } from 'mobx-state-tree';
import { GlobalStore } from '.';
import endpoints from '@configs/endpoints';

export const Stakeholder = types.model({
  name: '',
  onboardingCompleted: false,
});

const StakeholderStore = types
  .model({
    list: types.optional(types.array(Stakeholder), []),
  })
  .views((self) => ({
    get userId(): string {
      return getRoot<typeof GlobalStore>(self).user.userId;
    },
  }))
  .actions((self) => ({
    fetch: flow(function* () {
      const { data } = yield axios.get(endpoints.shareholders);
      if (data.success) {
        (data.stakeholders || []).forEach(
          (st: {
            userId: string;
            legalUserId: string;
            refId: string;
            name: string;
            onboardingCompleted: string;
          }) => {
            if (self.userId !== (st.legalUserId || st.refId)) {
              self.list.push(
                Stakeholder.create({
                  name: st.name,
                  onboardingCompleted: !!st.onboardingCompleted,
                }),
              );
            }
          },
        );
      }
    }),
  }));

export default StakeholderStore;
