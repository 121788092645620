import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import AuthCookie from '../utils/authCookie';
import AutomaticLogoutCookie from '../utils/automaticLogoutCookie';
import { baseUrl } from './variables';
import endpoints from './endpoints';
import GlobalStore from '@store/index';
import redirectToAuthApp from '@utils/redirectToAuthApp';
import routes from '@constants/routes';
import PartnerCookie from '@utils/partnerCookie';

const AUTH_ERROR = 'AUTH_ERROR';
const PERMISSION_ERROR = 'PERMISSION_ERROR';

const axiosConfig = (): void => {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = AuthCookie.getCookie();
    config.headers.Authorization = token;
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept-Language'] = 'es';
    config.headers['Device-ID'] = AuthCookie.getDeviceIdCookie() || GlobalStore.auth.deviceId;
    config.headers['Device-Type'] = 4;
    return config;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const requestUrl = response?.config?.url || '';
      const { error, errorCode, msg } = response.data;
      const noAuthMsg = msg === 'no authorizado';
      const isNoAuth =
        noAuthMsg ||
        errorCode === PERMISSION_ERROR ||
        errorCode === AUTH_ERROR ||
        response.status === 401;
      const isLoginRequest = [
        endpoints.login,
        endpoints.loginWithMfa,
        endpoints.loginWithSut,
        endpoints.logout,
      ].includes(requestUrl);

      if (isNoAuth || noAuthMsg) {
        const token = AuthCookie.getCookie();
        if (token) AuthCookie.deleteCookie();

        if (!isLoginRequest) {
          /* don't reload for login request */
          AutomaticLogoutCookie.setCookie(1);
          const partnerCookie = PartnerCookie.getCookie();
          const partner = partnerCookie || GlobalStore.ui.partner;
          redirectToAuthApp(partner ? `/${partner}${routes.LOGIN}` : '');
          return Promise.reject(AUTH_ERROR);
        }
      }

      if (error) return Promise.reject(response.data);
      return response;
    },
    (error: AxiosError) => Promise.reject(error),
  );
};

export default axiosConfig;
