/* eslint-disable max-len */

export default {
  title: 'Beneficiarios',
  info: 'En caso de fallecimiento, tus beneficiarios podrán ejercer derechos sobre tu cuenta.',
  selectQty: 'Selecciona el número de beneficiarios de tu cuenta',
  beneficiary: 'Beneficiario {{idx}}',
  form: {
    info: 'Ingresa la información de tus beneficiarios',
    firstName: 'Nombre(s)',
    lastName: 'Apellido paterno',
    secondLastName: 'Apellido materno',
    birthdate: 'Fecha de nacimiento',
    address: 'Domicilio',
    phone: 'Teléfono',
    relation: 'Parentesco',
    otherRelation: 'Otro',
    percentage: 'Porcentaje',
  },
  errors: {
    phone: 'Número de teléfono inválido',
  },
};
