import { Component, FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import useStore from '@hooks/useStore';
import routes from '@constants/routes';
import redirectToAuthApp from '@utils/redirectToAuthApp';
import usePartner from '@hooks/usePartner';

interface IRouteProps extends RouteProps {
  component: typeof Component | FC;
  isUnauthenticatedRoute?: boolean;
}

export const AuthenticatedRoute: FC<IRouteProps> = observer(
  ({ component: ChildComponent, ...rest }) => {
    const { auth } = useStore();
    const { partnerCookie: partner } = usePartner({});

    useEffect(() => {
      const isAuthenticated = !!(auth.isAuthenticated || auth.token);
      if (!isAuthenticated) {
        redirectToAuthApp(partner ? `/${partner}${routes.LOGIN}` : '');
      }
    }, [auth.isAuthenticated, auth.token, partner]);
    return <Route {...rest} render={(props) => <ChildComponent {...props} />} />;
  },
);

export const UnauthenticatedRoute: FC<IRouteProps> = observer(
  ({ component: ChildComponent, ...rest }) => {
    const { auth } = useStore();
    const history = useHistory();

    useEffect(() => {
      const isAuthenticated = auth.isAuthenticated || auth.token;
      if (isAuthenticated) {
        history.replace(routes.DASHBOARD);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated, auth.token]);

    return <Route {...rest} render={(props) => <ChildComponent {...props} />} />;
  },
);
