import Cookies from 'js-cookie';
import { cookieName, deviceIdExpMins } from '@configs/variables';

export const getExpiration = (minutes: number): Date =>
  new Date(new Date().getTime() + 60 * minutes * 1000);

/* Don't set subdomain cookie if current hostname is localhsot */
const isLocalhost = window.location.hostname === 'localhost';
export const cookieConfig = isLocalhost ? {} : { domain: process.env.REACT_APP_COOKIE_DOMAIN };
const fingerPrintCookieName = 'oysFingerprint';
const deviceIdCookie = 'oysDeviceId';

/* Auth cookie */
export default class AuthCookie {
  static setCookie(value: string): void {
    Cookies.set(cookieName, value, {
      expires: getExpiration(45),
      ...cookieConfig,
    });
  }

  static getCookie(): string {
    const cookie: string | undefined = Cookies.get(cookieName);
    return cookie || '';
  }

  static deleteCookie(): void {
    Cookies.remove(cookieName, cookieConfig);
  }

  static setFingerprintCookie(value: string): void {
    Cookies.set(fingerPrintCookieName, value, {
      expires: getExpiration(45),
      ...cookieConfig,
    });
  }

  static deleteFingerprintCookie(): void {
    Cookies.remove(fingerPrintCookieName, cookieConfig);
  }

  static getFingerprintCookie(): string {
    const cookie: string | undefined = Cookies.get(fingerPrintCookieName);
    return cookie || '';
  }

  static setDeviceIdCookie(value: string): void {
    Cookies.set(deviceIdCookie, value, {
      expires: getExpiration(deviceIdExpMins),
      ...cookieConfig,
    });
  }

  static deleteDeviceIdCookie(): void {
    Cookies.remove(deviceIdCookie);
  }

  static getDeviceIdCookie(): string {
    const cookie: string | undefined = Cookies.get(deviceIdCookie);
    return cookie || '';
  }
}
