import { useEffect } from 'react';

import { paymentLinksOnboardingVersionName, validOnboardingVersions } from '@configs/variables';
import { themePartnerNames } from '@yaydoo/react-components/lib/constants';
import useCustomThemeContext from '@yaydoo/react-components/lib/hooks/useCustomThemeContext';
import PartnerCookie from '@utils/partnerCookie';

import useStore from './useStore';

export interface UsePartnerData {
  partnerCookie: string;
  setPartner: (partnerName: themePartnerNames) => void;
  partnerByOnboardingVersion: (onboardingVersionName: string) => themePartnerNames;
}

export interface UsePartnerProps {
  initialLoad?: boolean;
}

function usePartner({ initialLoad = false }: UsePartnerProps): UsePartnerData {
  const { ui } = useStore();
  const { setCurrentPartnerTheme } = useCustomThemeContext();
  const arrayValidPartnerNames = Object.values(themePartnerNames);
  const partnerCookie = PartnerCookie.getCookie();

  const partnerByOnboardingVersion = (onboardingVersionName: string) => {
    const onboardingVersion =
      validOnboardingVersions.find((name) => onboardingVersionName.includes(name)) || '';

    let partnerName = themePartnerNames.NONE;

    if (onboardingVersion !== paymentLinksOnboardingVersionName) {
      partnerName = onboardingVersion
        .replace(/_V[0-9]/, '')
        .toLocaleLowerCase() as themePartnerNames;
      partnerName = partnerName.replace('_', '') as themePartnerNames;
    }
    return partnerName;
  };

  useEffect(() => {
    if (initialLoad) {
      const partnerCoockieIsValid = arrayValidPartnerNames.includes(
        partnerCookie as themePartnerNames,
      );
      if (partnerCoockieIsValid) {
        setCurrentPartnerTheme(partnerCookie as themePartnerNames);
        ui.updateField('partner', partnerCookie as themePartnerNames);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPartner = (partnerName: themePartnerNames): void => {
    PartnerCookie.setCookie(partnerName);
    if (arrayValidPartnerNames.includes(partnerName)) {
      setCurrentPartnerTheme(partnerName);
      ui.updateField('partner', partnerName);
    } else {
      setCurrentPartnerTheme(themePartnerNames.NONE);
      ui.updateField('partner', themePartnerNames.NONE);
    }
  };

  return { partnerCookie, setPartner, partnerByOnboardingVersion };
}

export default usePartner;
