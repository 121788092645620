import { types } from 'mobx-state-tree';

import SecurityAuthStore, {
  TSecurityAuthorizationStore,
} from '@yaydoo/react-mobx-stores/lib/securityAuthorization';

import { AddressType, DocumentType } from '@constants/enum';
import AuthCookie from '@utils/authCookie';

import Address from './address';
import App, { TAppStore } from './app';
import Auth, { TAuthStore, PasswordValid } from './auth';
import Beneficiaries, { TBeneficiariesStore } from './beneficiaries';
import Business, { BusinessDocs, TBusinessStore } from './business';
import Shareholders from './shareholders';
import UI, { TUIStore } from './ui';
import User, { TUserStore, Coordinates } from './user';
import UserDocument, { Document } from './userDocument';
import AgentOnboardingDocs, { TAgentOnboardingDocs, MoraleInfo } from './agentOnboardingDocs';
import PhoneVerification, { TPhoneVerification } from './phoneVerification';

const isTest = process.env.NODE_ENV === 'test';

export interface TGlobalStore {
  auth: TAuthStore;
  ui: TUIStore;
  user: TUserStore;
  app: TAppStore;
  business: TBusinessStore;
  beneficiaries: TBeneficiariesStore;
  securityAuth: TSecurityAuthorizationStore;
  agentOnboardingDocs: TAgentOnboardingDocs;
  phoneVerification: TPhoneVerification;
}

export const GlobalStore = types.model({
  auth: Auth,
  ui: UI,
  user: User,
  app: App,
  business: Business,
  beneficiaries: Beneficiaries,
  securityAuth: SecurityAuthStore,
  agentOnboardingDocs: AgentOnboardingDocs,
  phoneVerification: PhoneVerification,
});

export type TPartialGlobalStore = {
  [K in keyof TGlobalStore]?: Partial<TGlobalStore[K]>;
};

const store: TGlobalStore = GlobalStore.create({
  auth: Auth.create({
    passwordValid: PasswordValid.create({}),
    token: isTest ? '12345' : AuthCookie.getCookie(),
    deviceId: isTest ? '12345' : AuthCookie.getDeviceIdCookie(),
    isAuthenticated: !!AuthCookie.getCookie(),
    preverificationCheckError: null,
  }),
  ui: UI.create({}),
  user: User.create({
    phoneValid: null,
    isCurpValid: null,
    locationData: Coordinates.create({}),
    poaDocument: UserDocument.create({
      document: Document.create({}),
      documentType: DocumentType.PROOF_OF_ADDRESS,
    }),
    easDocument: UserDocument.create({
      document: Document.create({}),
      documentType: DocumentType.EXTERNAL_ACCOUNT_STATEMENT,
    }),
    passportDocument: UserDocument.create({
      document: Document.create({}),
      documentType: DocumentType.EXTERNAL_ACCOUNT_STATEMENT,
    }),
    voterIdFrontDocument: UserDocument.create({
      document: Document.create({}),
      documentType: DocumentType.EXTERNAL_ACCOUNT_STATEMENT,
    }),
    voterIdBackDocument: UserDocument.create({
      document: Document.create({}),
      documentType: DocumentType.EXTERNAL_ACCOUNT_STATEMENT,
    }),
    moraleInfo: MoraleInfo.create({}),
  }),
  business: Business.create({
    isTaxIdValid: null,
    numberOfEmployees: '',
    yearsOfOperation: '',
    monthlyAvgIncome: '',
    givesOutInvoices: null,
    onlyShareholder: null,
    isControllingShareholder: null,
    businessAddress: Address.create({
      type: AddressType.HOME,
    }),
    personalAddress: Address.create({
      type: AddressType.PERSONAL,
    }),
    shareholders: Shareholders.create({}),
    businessDocs: BusinessDocs.create({
      aoi: [
        UserDocument.create({
          isMainContent: true,
          document: Document.create({}),
          documentType: DocumentType.ART_OF_INCORPORATION,
        }),
      ],
      cif: UserDocument.create({
        document: Document.create({}),
        documentType: DocumentType.FISCAL_IDENTIFICATION,
      }),
      signature: UserDocument.create({
        document: Document.create({}),
        documentType: DocumentType.PROOF_OF_FIEL,
      }),
      propertyCert: UserDocument.create({
        document: Document.create({}),
        documentType: DocumentType.PUBLIC_REGISTRY_OF_PROPERTY_AND_COMMERCE_PROPERTY,
      }),
    }),
  }),
  beneficiaries: Beneficiaries.create({}),
  app: App.create({
    toastType: 'success',
  }),
  securityAuth: SecurityAuthStore.create({}),
  agentOnboardingDocs: AgentOnboardingDocs.create({}),
  phoneVerification: PhoneVerification.create({}),
});

export type TStore = typeof store;
export default store;
