import axios, { AxiosError } from 'axios';
import { flow, Instance, types, applySnapshot } from 'mobx-state-tree';

import endpoints from '../configs/endpoints';
import { v4 as uuidv4 } from 'uuid';

export const UserDocument = types
  .model({
    name: '',
    size: 0,
    documentId: '',
    documentContentId: '',
    errorMessage: '',
    uploaded: false,
    isUploading: false,
  })
  .volatile(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file: any = null;
    return { file };
  })
  .actions((self) => ({
    setFile(file: File) {
      self.file = file;
    },
    upload: flow(function* (isForUbo?: boolean) {
      try {
        self.isUploading = true;
        self.errorMessage = '';
        const formData = new FormData();
        formData.append('file', self.file);
        isForUbo && formData.append('isForUbo', 'true');
        const { data } = yield axios.post(endpoints.uploadDocument, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (data.success) {
          self.uploaded = true;
        }
        if (data.error) {
          self.errorMessage = data.msg || 'Algo salio mal';
        }
      } catch (error) {
        console.warn((error as AxiosError)?.message);
      } finally {
        self.isUploading = false;
      }
    }),
  }));

const AgentOnboardingDocs = types
  .model({
    files: types.optional(types.array(UserDocument), []),
  })
  .actions((self) => ({
    resetStore: () => {
      self.files.clear();
    },
    onFileAdd(acceptedFiles: File[], isForUbo?: boolean) {
      acceptedFiles.forEach((file) => {
        const userDoc = UserDocument.create({
          documentId: uuidv4(),
          name: file.name,
          size: file.size,
        });
        userDoc.setFile(file);
        self.files.push(userDoc);
      });
      return self.files.reduce(
        (acc, file) => acc.then(() => (file.uploaded ? Promise.resolve() : file.upload(isForUbo))),
        Promise.resolve(),
      );
    },
    removeFile(file: Instance<typeof UserDocument>) {
      const index = self.files.findIndex((e) => {
        if (e.documentId === file.documentId) return e;
      });
      self.files.splice(index, 1);
    },
  }));

export type TAgentOnboardingDocs = Instance<typeof AgentOnboardingDocs>;

const DocumentStatus = types.model({
  type: '',
  documentStatus: '',
});

export type TDocumentStatus = Instance<typeof DocumentStatus>;

const DocumentFile = types.model({
  directoryName: '',
  fileName: '',
  originalName: '',
  publicUrl: '',
});

export const MoraleInfo = types
  .model({
    businessName: '',
    noOfEmployee: '',
    industry: '',
    monthlyIncome: '',
    yearOfOperation: '',
    website: '',
    documentStatus: types.optional(types.array(DocumentStatus), []),
    documents: types.optional(types.array(DocumentFile), []),
  })
  .views((self) => ({
    get canSubmit() {
      return (
        self.businessName &&
        self.noOfEmployee &&
        self.industry &&
        self.monthlyIncome &&
        self.yearOfOperation
      );
    },
  }))
  .actions((self) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateField: (field: string, value: any) => {
      applySnapshot(self, { ...self, [field]: value });
    },
    fetchDocumentStatus: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.getDocumentStatus);
        if (data.success) {
          self.documentStatus = data.kycOfficialDocuments;
        }
      } catch (error) {
        console.warn(error);
      }
    }),
    getDocuments: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.uploadDocument);
        if (data.success) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          self.documents = data.kycOfficialDocuments?.map((doc: any) => {
            const file: { directoryName: string; fileName: string; originalName: string } =
              doc.files ? doc.files[0] : {};
            return DocumentFile.create({
              directoryName: file.directoryName,
              fileName: file.fileName,
              originalName: file.originalName,
              publicUrl: doc.documentHref,
            });
          });
        }
      } catch (error) {
        console.warn(error);
      }
    }),
  }));

export default AgentOnboardingDocs;
