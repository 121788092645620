/* eslint-disable max-len */

export default {
  taxId: 'RFC',
  personalRFC: 'RFC del representante legal',
  other: 'Otro',
  industryOther: '',
  occupation: 'Industria',
  numberOfEmployees: 'Número de empleados',
  monthlyIncome: 'Ingreso promedio mensual',
  yearsOfOperation: 'Años de operación',
  website: 'Sitio web (opcional)',
  submitInvoices: '¿Emites facturas a tus clientes? *',
  onlyShareholder: '¿Eres dueño único de tu negocio o tienes socios?',
  oneOwner: 'Dueño unico',
  manyOwners: 'Tengo socios',
  isControllingShareholder: '¿Eres accionista mayoritario de tu empresa?',
  razonSocial: 'Razón social del negocio',
  businessName: 'Nombre comercial',
  businessNameTooltip: 'Este es el nombre que será utilizado para generar tus links de pago.',
  noEmployees: {
    51: 'Más de 50',
    1: 'Sólo soy yo',
  },
  yearsOfOp: {
    1: '1 año o menos',
    11: 'Más de 10',
  },
  types: {
    0: '',
    1: 'Persona física',
    2: 'Persona moral',
  },
  errors: {
    taxId: 'RFC inválida',
    taxIdUnavailable: 'Este RFC ya está en uso',
    duplicateText:
      'El RFC de Persona Moral ya esta asignado a otra cuenta, continua tu registro anterior.',
  },
};
