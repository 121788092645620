/* eslint-disable max-len */

export default {
  title: 'Documentos del negocio',
  info: 'Para continuar con la contratación de tu línea de crédito, envía los siguientes documentos de tu negocio',
  aoi: {
    info: 'Es un documento legal que de manera obligatoria constituye la base que fundamenta la creación de una empresa, asociación o institución. Detalla el objeto, los accionistas, los apoderados y el tipo de organización. Puedes añadir archivos que complementen tu acta usando la opción “Añadir otro”',
    title: 'Acta constitutiva',
    docInfo:
      'Si aún no la tienes, acude con el notario o corredor público que te certificó y obtén una copia. Si tu acta tiene más de cinco años, deberás hacer el trámite en el archivo general de notarías.',
  },
  cif: {
    info: 'La CIF es un documento oficial que expide la Secretaría de Hacienda y Crédito Público, el cual contiene el Registro Federal de Contribuyentes (RFC, clave de identificación) y los datos personales del contribuyente ya sea persona física o moral.',
    title: 'Cédula de Identificación Fiscal',
    docInfo:
      'Si no la tienes, entra a la página del SAT y busca “Trámites del RFC”, selecciona “Obtén tu cédula de identificación fiscal” y da clic en “Ejecutar en línea”.',
  },
  signature: {
    info: 'Es el conjunto de datos y caracteres que te identifica al realizar trámites y servicios por internet en el SAT, así como en otras Dependencias, Entidades Federativas, Municipios y la iniciativa privada.',
    title: 'Comprobante de registro de Firma electrónica',
    docInfo:
      'Si no tienes el comprobante, realiza una captura de pantalla del portal de SAT que contenga el número de serie y la vigencia.',
  },
  propertyCert: {
    info: 'Es un requisito para las personas que se dedican al comercio y es obligatoria para las sociedades mercantiles.',
    title: 'Certificado del registro público de la propiedad',
    docInfo:
      'Si no lo tienes, acércate a un notario o corredor público autorizado, ya que ellos son los que pueden ayudarte con el registro. Al finalizar el trámite, te proporcionarán una boleta de inscripción con tu Folio Mercantil Electrónico.',
  },
  docInfo: '¿Cómo obtengo este documento?',
  addFile: 'Añadir otro',
};
