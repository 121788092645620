const hasDuplicates = (array: (string | number)[]): boolean => {
  return new Set(array).size !== array.length;
};

export const findDuplicates = (array: string[]): string[] =>
  array.reduce(
    (acc: string[], v, i, arr) =>
      arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
    [],
  );

export default hasDuplicates;
