/* eslint-disable max-len */

import disbursementInformationV1 from '../containers/DisbursementInformationV1/translation';
import phoneVerificationScreen from '../containers/PhoneVerificationV1/translation';
import agentOnboardingScreen from './screens/agentOnboarding';
import beneficiariesScreen from './screens/beneficiaries';
import businessDocsScreen from './screens/businessDocs';
import loginScreen from './screens/login';
import pepInformationScreen from './screens/pepInformation';
import personalDoscScreen from './screens/personalDocs';
import registerScreen from './screens/register';
import resetScreen from './screens/reset';
import shareholderScreen from './screens/shareholder';
import verifyScreen from './screens/verifyEmail';
import agentOnboardingV2 from '../containers/AgentOnboardingV2/translation';
import agentOnboardingV3 from '../containers/AgentOnboardingV3/translation';
import rejectionNotInvoiceScreen from '../views/RejectionNotInvoice/translation';
import helpSidebar from './screens/helpSidebar';
import addressInfo from './screens/addressInfo';
import personalInformationV1 from '../views/PersonalInformationV1/translation';
import businessInformationV1 from '../views/BusinessInformationV1/translation';
import onboardingSuccessV1 from '../views/OnboardingSuccessV1/translation';
import userInfo from './screens/userInfo';
import businessInfo from './screens/businessInfo';
import bankInfo from './screens/bankInfo';

export default {
  translation: {
    common: {
      userLogin: 'Inicia sesión',
      resetLogin: 'Iniciar sesión',
      retryActionClick: 'Haz clic aquí',
      continue: 'Continuar',
      logout: 'Guardar y Salir',
      register: 'Regístrate',
      back: 'Atrás',
      yes: 'Sí',
      no: 'No',
      addFile: 'Selecciona o arrastra el archivo *',
      complete: 'Completado',
      loading: 'Cargando',
      email: 'Correo electrónico',
      total: 'Total',
      errorTitle: 'Hubo un error',
      errorText: 'Intenta otra vez',
      errorVerification: 'Verifica que la información sea correcta e intenta otra vez',
      agentHelp: 'Un agente te puede ayudar ',
      example: 'Ejemplo',
      success: 'Solicitud recibida',
      done: 'Listo',
      clear: 'Borrar',
      missingFields: '*Por favor completa todos los campos solicitados',
    },
    sidebar: {
      stepTitle: 'PASO {{step}}',
      DISBURSEMENT_INFORMATION_V1: 'Cuenta bancaria',
      AGENT_ONBOARDING_V1: 'Documentación',
      PAYMENT_LINKS_SUCCESS_V1: '¡Listo!',
      TERMS_CONDITIONS_V1: 'Términos y condiciones',
      PERSONAL_INFORMATION_V1: 'Información personal',
      BUSINESS_INFORMATION_V1: 'Información de tu negocio',
      PHONE_VERIFICATION_V1: 'Verificación telefónica',
      ONBOARDING_SUCCESS_V1: '¡Listo!',
    },
    success: {
      title: 'Continúa desde la app',
      info: 'Para terminar con tu solicitud descarga la app Oyster en tu dispositivo, inicia sesión y sigue las instrucciones. Haz clic en el botón y te enviaremos un enlace de descarga a tu teléfono.',
      sendLink: 'Enviar link',
    },
    loginScreen,
    verifyScreen,
    resetScreen,
    registerScreen,
    personalDoscScreen,
    shareholderScreen,
    pepInformationScreen,
    businessDocsScreen,
    beneficiariesScreen,
    disbursementInformationV1,
    onboardingSuccessV1,
    agentOnboardingScreen,
    phoneVerificationScreen,
    agentOnboardingV2,
    agentOnboardingV3,
    rejectionNotInvoiceScreen,
    helpSidebar,
    addressInfo,
    userInfo,
    personalInformationV1,
    businessInformationV1,
    businessInfo,
    bankInfo,
  },
};
