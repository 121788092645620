import axios from 'axios';
import i18n from 'i18next';
import { flow, getSnapshot, Instance, types } from 'mobx-state-tree';

import { ScreenObjectType } from '@components/sidebar/types';
import endpoints from '@configs/endpoints';
import Common from './common';
import { paymentLinksOnboardingVersionName } from '@configs/variables';
import sections from '@constants/screens/screenSections';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let onboardingVersionConfig: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let screenObj: any;

const ScreenProgression = types
  .model({
    currentScreen: '',
    nextScreen: '',
  })
  .actions((self) => ({
    updateProgress: (currentScreen: string, nextScreen: string) => {
      self.currentScreen = currentScreen;
      self.nextScreen = nextScreen;
    },
  }));

const App = types
  .compose(
    Common,
    types.model({
      /* App toast state */
      toastOpen: false,
      toastTitle: '',
      toastMessage: '',
      toastType: types.union(types.literal('success'), types.literal('error')),
      toastDuration: 6000,
      tostTimeout: types.union(types.number, types.undefined),
      /* Onboarding info */
      onboardingVersionName: '',
      onboardingVersionConfig: '',
      onboardingVersionId: '',
      lastScreen: '',
      nextSection: '',
      postDashboard: '',
      currentScreen: '',
      nextScreen: '',
      nextScreenObject: '',
      screenObjStr: '',
      nextScreenHasChildren: false,
      nextScreenHasParent: false,
      parentName: '',
      parentPosition: 0,
      screenPosition: 0,
      hasChildren: false,
      hasParent: false,
      screenProgression: types.array(ScreenProgression),
    }),
  )
  .views(() => ({
    get screenObj() {
      return screenObj;
    },
  }))
  .actions((self) => ({
    closeToast: () => {
      self.toastOpen = false;
      if (self.tostTimeout) clearTimeout(self.tostTimeout);
      self.tostTimeout = window.setTimeout(() => {
        self.updateField('toastTitle', '');
        self.updateField('toastMessage', '');
      }, 300);
    },
  }))
  .actions((self) => ({
    openToast: ({
      type = 'success',
      title,
      message,
      toastDuration = 6000,
    }: {
      type?: 'success' | 'error';
      title: string;
      message: string;
      toastDuration?: number;
    }) => {
      self.toastType = type;
      self.toastOpen = true;
      self.toastTitle = title;
      self.toastMessage = message;
      self.toastDuration = toastDuration;
      if (self.tostTimeout) clearTimeout(self.tostTimeout);
      self.tostTimeout = window.setTimeout(() => {
        self.closeToast();
      }, toastDuration);
    },
  }))
  /* onboarding actions */
  .actions((self) => ({
    getScreenProgressionSnapshot: () => {
      return getSnapshot(self.screenProgression);
    },
    getNextScreen: (currentScreen: string): string => {
      const {
        business: { businessTypeStr },
      } = self.globalStore;

      if (businessTypeStr) {
        const screenSequence = onboardingVersionConfig.config_metadata.screens[businessTypeStr];
        return screenSequence[screenSequence.findIndex((el: string) => el === currentScreen) + 1];
      }
      return '';
    },
    setScreenProgress: (
      data: { currentScreen: string; nextScreen: string }[],
      nextSection: string,
    ) => {
      self.nextSection = nextSection;
      if (data.length > 0) {
        self.currentScreen = data[0].currentScreen;
        self.nextScreen = data[0].nextScreen;
        data.forEach((row) => {
          self.screenProgression.push({
            currentScreen: row.currentScreen,
            nextScreen: row.nextScreen,
          });
        });
      }
    },
    setOnboardingData: () => {
      const { business } = self.globalStore;
      const businessType = business.businessTypeStr;

      if (self.onboardingVersionConfig || onboardingVersionConfig) {
        const screenConfig = onboardingVersionConfig || JSON.parse(self.onboardingVersionConfig);
        const { post_dashboard: postDashboard } = screenConfig?.screens;

        if (businessType) {
          self.lastScreen = screenConfig.config_metadata.last_screen[businessType];
          if (postDashboard) {
            const postDashboardScreens = businessType
              ? postDashboard[businessType]
              : postDashboard.pf;
            self.postDashboard = JSON.stringify(postDashboardScreens);
          }
        }

        const screenConfigToBuildObj = screenConfig?.screens;
        if (self.onboardingVersionName === paymentLinksOnboardingVersionName) {
          // we inlclude pre_dashboard and post_dashboard steps to show
          screenObj = Array.isArray(screenConfigToBuildObj['pre_dashboard'])
            ? screenConfigToBuildObj['pre_dashboard']
            : screenConfigToBuildObj['pre_dashboard'][businessType];
          screenObj = [
            ...screenObj,
            ...(postDashboard ? screenConfigToBuildObj['post_dashboard'][businessType] : []),
          ];
        } else {
          if (self.nextSection !== sections.PRE_APPROVED_DASH) {
            screenObj = Array.isArray(screenConfigToBuildObj[self.nextSection])
              ? screenConfigToBuildObj[self.nextSection]
              : screenConfigToBuildObj[self.nextSection][businessType];
          } else {
            screenObj = screenConfigToBuildObj[sections.PRE_DASH];
          }
        }

        self.screenObjStr = JSON.stringify(screenObj);
        const nextScreenObject = screenObj?.find(
          (screen: ScreenObjectType) => screen.name === self.nextScreen,
        );

        const nextScreenHasChildren = !!nextScreenObject?.hasChildren;
        const nextScreenHasParent = !!nextScreenObject?.parent;
        self.screenPosition = nextScreenObject ? nextScreenObject.position : 0;
        self.nextScreenObject = JSON.stringify(nextScreenObject);
        self.nextScreenHasParent = nextScreenHasParent;
        self.nextScreenHasChildren = nextScreenHasChildren;

        if ((!nextScreenHasParent && !nextScreenHasChildren) || nextScreenHasChildren) {
          self.parentPosition = self.screenPosition;
        } else if (nextScreenHasParent) {
          const parent = screenObj.find(
            (screen: ScreenObjectType) => screen?.name === nextScreenObject?.parent,
          );
          if (parent) {
            self.parentPosition = parent.position;
            self.parentName = parent.name;
          }
        }
      }
    },
  }))
  /* onboarding actions */
  .actions((self) => ({
    setCurrentScreen: (screen: string) => (self.currentScreen = screen),
    setNextScreen: flow(function* (screen?: string) {
      const nextScreen = screen || self.getNextScreen(self.nextScreen);

      self.screenProgression.unshift({
        currentScreen: self.nextScreen,
        nextScreen,
      });
      self.currentScreen = self.nextScreen;
      self.nextScreen = nextScreen;
      self.setOnboardingData();
      yield Promise.resolve('ok');
    }),
    setConfig: (data: {
      onboardingVersionId: string;
      onboardingVersionName: string;
      onboardingVersionConfig: string;
    }) => {
      onboardingVersionConfig = JSON.parse(data.onboardingVersionConfig);
      self.onboardingVersionId = data.onboardingVersionId;
      self.onboardingVersionName = data.onboardingVersionName;
      self.onboardingVersionConfig = data.onboardingVersionConfig;
      self.setOnboardingData();
    },
  }))
  .actions((self) => ({
    // To be used by endpoints that do not go through onboarding service
    // and need a 2nd call to save the progress
    saveNextScreen: flow(function* (args?: {
      manualErrorHandle?: boolean;
      skipApp?: boolean;
      crntScreen?: string;
      nxtScreen?: string;
    }) {
      try {
        const currentScreen = args?.crntScreen || self.nextScreen;
        const nextScreen = args?.nxtScreen || self.getNextScreen(currentScreen);
        const body = {
          progress: {
            currentScreen,
            nextScreen,
          },
        };
        const { data } = yield axios.post(endpoints.onboardingProgress, body);
        self.updateField('nextSection', data.nextSection);
        if (!args?.skipApp) self.setNextScreen(nextScreen);
        return data;
      } catch (error) {
        if (args?.manualErrorHandle) {
          throw error;
        } else {
          const { msg = '' } = error as { msg: string };
          self.openToast({
            type: 'error',
            title: i18n.t('common.errorText'),
            message: msg || i18n.t('common.errorText'),
          });
        }
      }
    }),
  }));

export type TAppStore = Instance<typeof App>;

export default App;
