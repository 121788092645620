import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';

import MobxCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/AuthCookie';
import ClientFactory from '@yaydoo/react-mobx-stores/lib/http/factory';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';

import { AuthenticatedRoute, UnauthenticatedRoute } from '@components/authentication';
import Toast from '@components/common/Toast';
import { baseUrl, cookieDomain, cookieName, env as enviroment } from '@configs/variables';
import routes from '@constants/routes';
import Dashboard from '@containers/dashboard';
import PartnerDashboard from '@containers/PartnerDashboard';
import usePartner from '@hooks/usePartner';
import useStore from '@hooks/useStore';
import AuthCookie from '@utils/authCookie';
import redirectToAuthApp from '@utils/redirectToAuthApp';

interface AppProps {}

const App: FC<AppProps> = () => {
  const history = useHistory();
  const { app, ui, auth, securityAuth } = useStore();
  const { updateService: updateSecurityService } = securityAuth;
  const token = AuthCookie.getCookie();
  usePartner({ initialLoad: true });

  useEffect(() => {
    if (token) {
      auth.updateField('token', token);
      auth.updateField('isAuthenticated', true);
    }
  }, [token, history, auth]);

  useEffect(() => {
    MobxCookie.init({ enviroment, cookieDomain, cookieName });
    ClientFactory.addConfig(OysterService.DEFAULT_CONNECTION, baseUrl);
    ui.detectDeviceType();
    auth.generateFingerPrint();

    const handleResize = () => {
      ui.updateField('width', window.innerWidth);
      ui.updateField('height', window.innerHeight);
      if (window.innerWidth <= 640 && !ui.isMobileScreen) {
        ui.updateField('isMobileScreen', true);
      } else if (window.innerWidth > 640 && ui.isMobileScreen) {
        ui.updateField('isMobileScreen', false);
      }
      if (window.innerHeight <= 500) {
        ui.setIsKeyboardPopped(true);
      } else {
        ui.setIsKeyboardPopped(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    updateSecurityService();
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toast
        type={app.toastType}
        title={app.toastTitle}
        message={app.toastMessage}
        onClose={app.closeToast}
        open={app.toastOpen}
      />
      <Switch>
        <AuthenticatedRoute exact path={routes.DASHBOARD} component={Dashboard} />
        <UnauthenticatedRoute
          exact
          path={routes.LOGIN}
          component={() => {
            redirectToAuthApp(routes.LOGIN);
            return null;
          }}
        />
        <UnauthenticatedRoute
          exact
          path={routes.RESET}
          component={() => {
            redirectToAuthApp(routes.RESET);
            return null;
          }}
        />
        <UnauthenticatedRoute
          exact
          path={routes.RESET_PASSWORD}
          component={() => {
            redirectToAuthApp(routes.RESET_PASSWORD);
            return null;
          }}
        />
        <UnauthenticatedRoute
          exact
          path={routes.REGISTER}
          component={() => {
            redirectToAuthApp(routes.REGISTER);
            return null;
          }}
        />
        <AuthenticatedRoute exact path={routes.CONTPAQI} component={PartnerDashboard} />
        <Redirect from="*" to={{ ...history.location, pathname: routes.DASHBOARD }} />
      </Switch>
    </>
  );
};

export default observer(App);
