export const personaFisica = 'fisica';
export const personaMoral = 'moral';

const regularMonthDayRegex = '(?:0[469]|11)(?:0[1-9]|[1-2][0-9]|30)';
const extendedMonthDayRegex = '(?:0[13578]|1[02])(?:0[1-9]|[1-2][0-9]|30|31)';
const februaryRegex = '(?:02)(?:0[1-9]|[1-2][0-9])';

const yearRegex = '[0-9]{2}';
const monthDayRegex = `(?:${regularMonthDayRegex}|${extendedMonthDayRegex}|${februaryRegex})`;
const homoclaveRegex = '[A-Z\\d]{2}[A\\d]';

const entityRegex = '[A-ZÑ&\\d]{3}';
const isMoralEntityRegex = new RegExp(
  `^(${entityRegex})(${yearRegex}${monthDayRegex})(${homoclaveRegex})$`,
);

const personaFisicaRegex = '[A-ZÑ&]{4}';
const isPersonaFisicaRegex = new RegExp(
  `^(${personaFisicaRegex})(${yearRegex}${monthDayRegex})(${homoclaveRegex})$`,
);

export const isRFCFisica = (rfc: string): boolean => {
  let isRFC: RegExpMatchArray | boolean | null = true;
  if (rfc) isRFC = rfc.toUpperCase().match(isPersonaFisicaRegex);
  return !!isRFC;
};

export const isRFCMoral = (rfc: string): boolean => {
  if (!rfc) return false;
  let isRFC: RegExpMatchArray | boolean | null = true;
  if (rfc) isRFC = rfc.toUpperCase().match(isMoralEntityRegex);
  return !!isRFC;
};
