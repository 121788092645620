export interface CountryCode {
  mask: string;
  label: string;
  name: string;
  isoCode: string;
  countryCode: string;
  label2: string;
  nameES?: string;
}

export const countryCodeData = (countryCode: string): CountryCode | undefined =>
  countryCodes.find((codeObj) => codeObj.countryCode === countryCode);

const countryCodes: CountryCode[] = [
  {
    mask: '99 9999 9999',
    label: 'México (+52)',
    name: 'México',
    isoCode: '+52',
    countryCode: 'MX',
    label2: 'MX (+52)',
  },
  {
    mask: '9999999999',
    label: 'Afghanistan (+93)',
    name: 'Afghanistan',
    isoCode: '+93',
    countryCode: 'AF',
    label2: 'AF (+93)',
  },
  {
    mask: '9 999 9999',
    label: 'Albania (+355)',
    name: 'Albania',
    isoCode: '+355',
    countryCode: 'AL',
    label2: 'AL (+355)',
  },
  {
    mask: '99 999 99 99',
    label: 'Algeria (+213)',
    name: 'Algeria',
    isoCode: '+213',
    countryCode: 'DZ',
    label2: 'DZ (+213)',
  },
  {
    mask: '999 999',
    label: 'Andorra (+376)',
    name: 'Andorra',
    isoCode: '+376',
    countryCode: 'AD',
    label2: 'AD (+376)',
  },
  {
    mask: '999 999 999',
    label: 'Angola (+244)',
    name: 'Angola',
    isoCode: '+244',
    countryCode: 'AO',
    label2: 'AO (+244)',
  },
  {
    mask: '999 999 9999',
    label: 'Anguilla (+1)',
    name: 'Anguilla',
    isoCode: '+1',
    countryCode: 'AI',
    label2: 'AI (+1)',
  },
  {
    mask: '999 9999',
    label: 'Antigua and Barbuda (+1268)',
    name: 'Antigua and Barbuda',
    isoCode: '+1268',
    countryCode: 'AG',
    label2: 'AG (+1268)',
  },
  {
    mask: '9 999 999 9999',
    label: 'Argentina (+54)',
    name: 'Argentina',
    isoCode: '+54',
    countryCode: 'AR',
    label2: 'AR (+54)',
  },
  {
    mask: '99 999 999',
    label: 'Armenia (+374)',
    name: 'Armenia',
    isoCode: '+374',
    countryCode: 'AM',
    label2: 'AM (+374)',
  },
  {
    mask: '999 9999',
    label: 'Aruba (+297)',
    name: 'Aruba',
    isoCode: '+297',
    countryCode: 'AW',
    label2: 'AW (+297)',
  },
  {
    mask: '9 9999 9999',
    label: 'Australia (+61)',
    name: 'Australia',
    isoCode: '+61',
    countryCode: 'AU',
    label2: 'AU (+61)',
  },
  {
    mask: '999 999 9999',
    label: 'Austria (+43)',
    name: 'Austria',
    isoCode: '+43',
    countryCode: 'AT',
    label2: 'AT (+43)',
  },
  {
    mask: '99 999 99 99',
    label: 'Azerbaijan (+994)',
    name: 'Azerbaijan',
    isoCode: '+994',
    countryCode: 'AZ',
    label2: 'AZ (+994)',
  },
  {
    mask: '999 9999',
    label: 'Bahamas (+1242)',
    name: 'Bahamas',
    isoCode: '+1242',
    countryCode: 'BS',
    label2: 'BS (+1242)',
  },
  {
    mask: '9999 9999',
    label: 'Bahrain (+973)',
    name: 'Bahrain',
    isoCode: '+973',
    countryCode: 'BH',
    label2: 'BH (+973)',
  },
  {
    mask: '99 999 999',
    label: 'Bangladesh (+880)',
    name: 'Bangladesh',
    isoCode: '+880',
    countryCode: 'BD',
    label2: 'BD (+880)',
  },
  {
    mask: '999 9999',
    label: 'Barbados (+1246)',
    name: 'Barbados',
    isoCode: '+1246',
    countryCode: 'BB',
    label2: 'BB (+1246)',
  },
  {
    mask: '99 999 99 99',
    label: 'Belarus (+375)',
    name: 'Belarus',
    isoCode: '+375',
    countryCode: 'BY',
    label2: 'BY (+375)',
  },
  {
    mask: '9999 99 99 99',
    label: 'Belgium (+32)',
    name: 'Belgium',
    isoCode: '+32',
    countryCode: 'BE',
    label2: 'BE (+32)',
  },
  {
    mask: '999 9999',
    label: 'Belize (+501)',
    name: 'Belize',
    isoCode: '+501',
    countryCode: 'BZ',
    label2: 'BZ (+501)',
  },
  {
    mask: '99 99 9999',
    label: 'Benin (+229)',
    name: 'Benin',
    isoCode: '+229',
    countryCode: 'BJ',
    label2: 'BJ (+229)',
  },
  {
    mask: '999 9999',
    label: 'Bermuda (+1441)',
    name: 'Bermuda',
    isoCode: '+1441',
    countryCode: 'BM',
    label2: 'BM (+1441)',
  },
  {
    mask: '99 999 999',
    label: 'Bhutan (+975)',
    name: 'Bhutan',
    isoCode: '+975',
    countryCode: 'BT',
    label2: 'BT (+975)',
  },
  {
    mask: '9 999 9999',
    label: 'Bolivia, Plurinational State of (+591)',
    name: 'Bolivia, Plurinational State of',
    isoCode: '+591',
    countryCode: 'BO',
    label2: 'BO (+591)',
  },
  {
    mask: '99 99999',
    label: 'Bosnia and Herzegovina (+387)',
    name: 'Bosnia and Herzegovina',
    isoCode: '+387',
    countryCode: 'BA',
    label2: 'BA (+387)',
  },
  {
    mask: '99 999 999',
    label: 'Botswana (+267)',
    name: 'Botswana',
    isoCode: '+267',
    countryCode: 'BW',
    label2: 'BW (+267)',
  },
  {
    mask: '99 9999 9999',
    label: 'Brazil (+55)',
    name: 'Brazil',
    isoCode: '+55',
    countryCode: 'BR',
    label2: 'BR (+55)',
  },
  {
    mask: '999 9999',
    label: 'British Indian Ocean Territory (+246)',
    name: 'British Indian Ocean Territory',
    isoCode: '+246',
    countryCode: 'IO',
    label2: 'IO (+246)',
  },
  {
    mask: '999 9999',
    label: 'Brunei Darussalam (+673)',
    name: 'Brunei Darussalam',
    isoCode: '+673',
    countryCode: 'BN',
    label2: 'BN (+673)',
  },
  {
    mask: '999 999 999',
    label: 'Bulgaria (+359)',
    name: 'Bulgaria',
    isoCode: '+359',
    countryCode: 'BG',
    label2: 'BG (+359)',
  },
  {
    mask: '99 99 9999',
    label: 'Burkina Faso (+226)',
    name: 'Burkina Faso',
    isoCode: '+226',
    countryCode: 'BF',
    label2: 'BF (+226)',
  },
  {
    mask: '99 99 9999',
    label: 'Burundi (+257)',
    name: 'Burundi',
    isoCode: '+257',
    countryCode: 'BI',
    label2: 'BI (+257)',
  },
  {
    mask: '99 999 999',
    label: 'Cambodia (+855)',
    name: 'Cambodia',
    isoCode: '+855',
    countryCode: 'KH',
    label2: 'KH (+855)',
  },
  {
    mask: '9999 9999',
    label: 'Cameroon (+237)',
    name: 'Cameroon',
    isoCode: '+237',
    countryCode: 'CM',
    label2: 'CM (+237)',
  },
  {
    mask: '999 999 9999',
    label: 'Canada (+1)',
    name: 'Canada',
    isoCode: '+1',
    countryCode: 'CA',
    label2: 'CA (+1)',
  },
  {
    mask: '999 99 99',
    label: 'Cape Verde (+238)',
    name: 'Cape Verde',
    isoCode: '+238',
    countryCode: 'CV',
    label2: 'CV (+238)',
  },
  {
    mask: '+1345 999 9999',
    label: 'Cayman Islands (+345)',
    name: 'Cayman Islands',
    isoCode: '+345',
    countryCode: 'KY',
    label2: 'KY (+345)',
  },
  {
    mask: '99 99 9999',
    label: 'Central African Republic (+236)',
    name: 'Central African Republic',
    isoCode: '+236',
    countryCode: 'CF',
    label2: 'CF (+236)',
  },
  {
    mask: '99 99 99 99',
    label: 'Chad (+235)',
    name: 'Chad',
    isoCode: '+235',
    countryCode: 'TD',
    label2: 'TD (+235)',
  },
  {
    mask: '9 9999 9999',
    label: 'Chile (+56)',
    name: 'Chile',
    isoCode: '+56',
    countryCode: 'CL',
    label2: 'CL (+56)',
  },
  {
    mask: '999 9999 9999',
    label: 'China (+86)',
    name: 'China',
    isoCode: '+86',
    countryCode: 'CN',
    label2: 'CN (+86)',
  },
  {
    mask: '999 999 9999',
    label: 'Colombia (+57)',
    name: 'Colombia',
    isoCode: '+57',
    countryCode: 'CO',
    label2: 'CO (+57)',
  },
  {
    mask: '99 99999',
    label: 'Comoros (+269)',
    name: 'Comoros',
    isoCode: '+269',
    countryCode: 'KM',
    label2: 'KM (+269)',
  },
  {
    mask: '99 999 9999',
    label: 'Congo (+242)',
    name: 'Congo',
    isoCode: '+242',
    countryCode: 'CG',
    label2: 'CG (+242)',
  },
  {
    mask: '999 999 999',
    label: 'Congo, The Democratic Republic of the Congo (+243)',
    name: 'Congo, The Democratic Republic of the Congo',
    isoCode: '+243',
    countryCode: 'CD',
    label2: 'CD (+243)',
  },
  {
    mask: '99 999',
    label: 'Cook Islands (+682)',
    name: 'Cook Islands',
    isoCode: '+682',
    countryCode: 'CK',
    label2: 'CK (+682)',
  },
  {
    mask: '9999 9999',
    label: 'Costa Rica (+506)',
    name: 'Costa Rica',
    isoCode: '+506',
    countryCode: 'CR',
    label2: 'CR (+506)',
  },
  {
    mask: '99 999 999',
    label: "Cote d'Ivoire (+225)",
    name: "Cote d'Ivoire",
    isoCode: '+225',
    countryCode: 'CI',
    label2: 'CI (+225)',
  },
  {
    mask: '99 999 999',
    label: 'Croatia (+385)',
    name: 'Croatia',
    isoCode: '+385',
    countryCode: 'HR',
    label2: 'HR (+385)',
  },
  {
    mask: '9 999 9999',
    label: 'Cuba (+53)',
    name: 'Cuba',
    isoCode: '+53',
    countryCode: 'CU',
    label2: 'CU (+53)',
  },
  {
    mask: '99 999 999',
    label: 'Cyprus (+357)',
    name: 'Cyprus',
    isoCode: '+357',
    countryCode: 'CY',
    label2: 'CY (+357)',
  },
  {
    mask: '999 999 999',
    label: 'Czech Republic (+420)',
    name: 'Czech Republic',
    isoCode: '+420',
    countryCode: 'CZ',
    label2: 'CZ (+420)',
  },
  {
    mask: '99 99 99 99',
    label: 'Denmark (+45)',
    name: 'Denmark',
    isoCode: '+45',
    countryCode: 'DK',
    label2: 'DK (+45)',
  },
  {
    mask: '99 99 99 99',
    label: 'Djibouti (+253)',
    name: 'Djibouti',
    isoCode: '+253',
    countryCode: 'DJ',
    label2: 'DJ (+253)',
  },
  {
    mask: '999 9999',
    label: 'Dominica (+1767)',
    name: 'Dominica',
    isoCode: '+1767',
    countryCode: 'DM',
    label2: 'DM (+1767)',
  },
  {
    mask: '999 9999',
    label: 'Dominican Republic (+1849)',
    name: 'Dominican Republic',
    isoCode: '+1849',
    countryCode: 'DO',
    label2: 'DO (+1849)',
  },
  {
    mask: '99 999 9999',
    label: 'Ecuador (+593)',
    name: 'Ecuador',
    isoCode: '+593',
    countryCode: 'EC',
    label2: 'EC (+593)',
  },
  {
    mask: '999 999 9999',
    label: 'Egypt (+20)',
    name: 'Egypt',
    isoCode: '+20',
    countryCode: 'EG',
    label2: 'EG (+20)',
  },
  {
    mask: '99 99 9999',
    label: 'El Salvador (+503)',
    name: 'El Salvador',
    isoCode: '+503',
    countryCode: 'SV',
    label2: 'SV (+503)',
  },
  {
    mask: '99 999 9999',
    label: 'Equatorial Guinea (+240)',
    name: 'Equatorial Guinea',
    isoCode: '+240',
    countryCode: 'GQ',
    label2: 'GQ (+240)',
  },
  {
    mask: '9 999 999',
    label: 'Eritrea (+291)',
    name: 'Eritrea',
    isoCode: '+291',
    countryCode: 'ER',
    label2: 'ER (+291)',
  },
  {
    mask: '9999 9999',
    label: 'Estonia (+372)',
    name: 'Estonia',
    isoCode: '+372',
    countryCode: 'EE',
    label2: 'EE (+372)',
  },
  {
    mask: '99 999 9999',
    label: 'Ethiopia (+251)',
    name: 'Ethiopia',
    isoCode: '+251',
    countryCode: 'ET',
    label2: 'ET (+251)',
  },
  {
    mask: '99999',
    label: 'Falkland Islands (Malvinas) (+500)',
    name: 'Falkland Islands (Malvinas)',
    isoCode: '+500',
    countryCode: 'FK',
    label2: 'FK (+500)',
  },
  {
    mask: '999 999',
    label: 'Faroe Islands (+298)',
    name: 'Faroe Islands',
    isoCode: '+298',
    countryCode: 'FO',
    label2: 'FO (+298)',
  },
  {
    mask: '99 99999',
    label: 'Fiji (+679)',
    name: 'Fiji',
    isoCode: '+679',
    countryCode: 'FJ',
    label2: 'FJ (+679)',
  },
  {
    mask: '99 99 99 99 99',
    label: 'France (+33)',
    name: 'France',
    isoCode: '+33',
    countryCode: 'FR',
    label2: 'FR (+33)',
  },
  {
    mask: '99999 9999',
    label: 'French Guiana (+594)',
    name: 'French Guiana',
    isoCode: '+594',
    countryCode: 'GF',
    label2: 'GF (+594)',
  },
  {
    mask: '99 99 99',
    label: 'French Polynesia (+689)',
    name: 'French Polynesia',
    isoCode: '+689',
    countryCode: 'PF',
    label2: 'PF (+689)',
  },
  {
    mask: '9 99 99 99',
    label: 'Gabon (+241)',
    name: 'Gabon',
    isoCode: '+241',
    countryCode: 'GA',
    label2: 'GA (+241)',
  },
  {
    mask: '999 99 99',
    label: 'Gambia (+220)',
    name: 'Gambia',
    isoCode: '+220',
    countryCode: 'GM',
    label2: 'GM (+220)',
  },
  {
    mask: '999 999 999',
    label: 'Georgia (+995)',
    name: 'Georgia',
    isoCode: '+995',
    countryCode: 'GE',
    label2: 'GE (+995)',
  },
  {
    mask: '9999 999 9999',
    label: 'Germany (+49)',
    name: 'Germany',
    isoCode: '+49',
    countryCode: 'DE',
    label2: 'DE (+49)',
  },
  {
    mask: '999 999 999',
    label: 'Ghana (+233)',
    name: 'Ghana',
    isoCode: '+233',
    countryCode: 'GH',
    label2: 'GH (+233)',
  },
  {
    mask: '999 99999',
    label: 'Gibraltar (+350)',
    name: 'Gibraltar',
    isoCode: '+350',
    countryCode: 'GI',
    label2: 'GI (+350)',
  },
  {
    mask: '999 999 9999',
    label: 'Greece (+30)',
    name: 'Greece',
    isoCode: '+30',
    countryCode: 'GR',
    label2: 'GR (+30)',
  },
  {
    mask: '99 99 99',
    label: 'Greenland (+299)',
    name: 'Greenland',
    isoCode: '+299',
    countryCode: 'GL',
    label2: 'GL (+299)',
  },
  {
    mask: '+1 473 999 9999',
    label: 'Grenada (+1473)',
    name: 'Grenada',
    isoCode: '+1473',
    countryCode: 'GD',
    label2: 'GD (+1473)',
  },
  {
    mask: '999 9999',
    label: 'Guam (+1671)',
    name: 'Guam',
    isoCode: '+1671',
    countryCode: 'GU',
    label2: 'GU (+1671)',
  },
  {
    mask: '9 999 9999',
    label: 'Guatemala (+502)',
    name: 'Guatemala',
    isoCode: '+502',
    countryCode: 'GT',
    label2: 'GT (+502)',
  },
  {
    mask: '99 999 999',
    label: 'Guinea (+224)',
    name: 'Guinea',
    isoCode: '+224',
    countryCode: 'GN',
    label2: 'GN (+224)',
  },
  {
    mask: '9 999999',
    label: 'Guinea-Bissau (+245)',
    name: 'Guinea-Bissau',
    isoCode: '+245',
    countryCode: 'GW',
    label2: 'GW (+245)',
  },
  {
    mask: '+592 999 9999',
    label: 'Guyana (+595)',
    name: 'Guyana',
    isoCode: '+595',
    countryCode: 'GY',
    label2: 'GY (+595)',
  },
  {
    mask: '99 99 9999',
    label: 'Haiti (+509)',
    name: 'Haiti',
    isoCode: '+509',
    countryCode: 'HT',
    label2: 'HT (+509)',
  },
  {
    mask: '99 999xxxxx',
    label: 'Holy See (Vatican City State) (+379)',
    name: 'Holy See (Vatican City State)',
    isoCode: '+379',
    countryCode: 'VA',
    label2: 'VA (+379)',
  },
  {
    mask: '9999 9999',
    label: 'Honduras (+504)',
    name: 'Honduras',
    isoCode: '+504',
    countryCode: 'HN',
    label2: 'HN (+504)',
  },
  {
    mask: '9999 9999',
    label: 'Hong Kong (+852)',
    name: 'Hong Kong',
    isoCode: '+852',
    countryCode: 'HK',
    label2: 'HK (+852)',
  },
  {
    mask: '999 999 999',
    label: 'Hungary (+36)',
    name: 'Hungary',
    isoCode: '+36',
    countryCode: 'HU',
    label2: 'HU (+36)',
  },
  {
    mask: '999 9999',
    label: 'Iceland (+354)',
    name: 'Iceland',
    isoCode: '+354',
    countryCode: 'IS',
    label2: 'IS (+354)',
  },
  {
    mask: '9999 999 999',
    label: 'India (+91)',
    name: 'India',
    isoCode: '+91',
    countryCode: 'IN',
    label2: 'IN (+91)',
  },
  {
    mask: '999 999 99 999',
    label: 'Indonesia (+62)',
    name: 'Indonesia',
    isoCode: '+62',
    countryCode: 'ID',
    label2: 'ID (+62)',
  },
  {
    mask: '999 999 9999',
    label: 'Iran, Islamic Republic of Persian Gulf (+98)',
    name: 'Iran, Islamic Republic of Persian Gulf',
    isoCode: '+98',
    countryCode: 'IR',
    label2: 'IR (+98)',
  },
  {
    mask: '999 999 9999',
    label: 'Iraq (+964)',
    name: 'Iraq',
    isoCode: '+964',
    countryCode: 'IQ',
    label2: 'IQ (+964)',
  },
  {
    mask: '999 999 999',
    label: 'Ireland (+353)',
    name: 'Ireland',
    isoCode: '+353',
    countryCode: 'IE',
    label2: 'IE (+353)',
  },
  {
    mask: '99 999 9999',
    label: 'Israel (+972)',
    name: 'Israel',
    isoCode: '+972',
    countryCode: 'IL',
    label2: 'IL (+972)',
  },
  {
    mask: '999 9999 999',
    label: 'Italy (+39)',
    name: 'Italy',
    isoCode: '+39',
    countryCode: 'IT',
    label2: 'IT (+39)',
  },
  {
    mask: '999 9999',
    label: 'Jamaica (+1876)',
    name: 'Jamaica',
    isoCode: '+1876',
    countryCode: 'JM',
    label2: 'JM (+1876)',
  },
  {
    mask: '99 9999 9999',
    label: 'Japan (+81)',
    name: 'Japan',
    isoCode: '+81',
    countryCode: 'JP',
    label2: 'JP (+81)',
  },
  {
    mask: '9 9999 9999',
    label: 'Jordan (+962)',
    name: 'Jordan',
    isoCode: '+962',
    countryCode: 'JO',
    label2: 'JO (+962)',
  },
  {
    mask: '99 999 99 99',
    label: 'Kazakhstan (+77)',
    name: 'Kazakhstan',
    isoCode: '+77',
    countryCode: 'KZ',
    label2: 'KZ (+77)',
  },
  {
    mask: '999 999999',
    label: 'Kenya (+254)',
    name: 'Kenya',
    isoCode: '+254',
    countryCode: 'KE',
    label2: 'KE (+254)',
  },
  {
    mask: '99 999',
    label: 'Kiribati (+686)',
    name: 'Kiribati',
    isoCode: '+686',
    countryCode: 'KI',
    label2: 'KI (+686)',
  },
  {
    mask: '99 999 9999',
    label: 'Korea, Republic of South Korea (+82)',
    name: 'Korea, Republic of South Korea',
    isoCode: '+82',
    countryCode: 'KR',
    label2: 'KR (+82)',
  },
  {
    mask: '9999 9999',
    label: 'Kuwait (+965)',
    name: 'Kuwait',
    isoCode: '+965',
    countryCode: 'KW',
    label2: 'KW (+965)',
  },
  {
    mask: '999 999 999',
    label: 'Kyrgyzstan (+996)',
    name: 'Kyrgyzstan',
    isoCode: '+996',
    countryCode: 'KG',
    label2: 'KG (+996)',
  },
  {
    mask: '9999 999 999',
    label: 'Laos (+856)',
    name: 'Laos',
    isoCode: '+856',
    countryCode: 'LA',
    label2: 'LA (+856)',
  },
  {
    mask: '99 999 999',
    label: 'Latvia (+371)',
    name: 'Latvia',
    isoCode: '+371',
    countryCode: 'LV',
    label2: 'LV (+371)',
  },
  {
    mask: '99 999 999',
    label: 'Lebanon (+961)',
    name: 'Lebanon',
    isoCode: '+961',
    countryCode: 'LB',
    label2: 'LB (+961)',
  },
  {
    mask: '9 999 9999',
    label: 'Lesotho (+266)',
    name: 'Lesotho',
    isoCode: '+266',
    countryCode: 'LS',
    label2: 'LS (+266)',
  },
  {
    mask: '99 999 999',
    label: 'Liberia (+231)',
    name: 'Liberia',
    isoCode: '+231',
    countryCode: 'LR',
    label2: 'LR (+231)',
  },
  {
    mask: '99 999 999',
    label: 'Libyan Arab Jamahiriya (+218)',
    name: 'Libyan Arab Jamahiriya',
    isoCode: '+218',
    countryCode: 'LY',
    label2: 'LY (+218)',
  },
  {
    mask: '999 999 9999',
    label: 'Liechtenstein (+423)',
    name: 'Liechtenstein',
    isoCode: '+423',
    countryCode: 'LI',
    label2: 'LI (+423)',
  },
  {
    mask: '999 99 999',
    label: 'Lithuania (+370)',
    name: 'Lithuania',
    isoCode: '+370',
    countryCode: 'LT',
    label2: 'LT (+370)',
  },
  {
    mask: '999 999 999',
    label: 'Luxembourg (+352)',
    name: 'Luxembourg',
    isoCode: '+352',
    countryCode: 'LU',
    label2: 'LU (+352)',
  },
  {
    mask: '9999 9999',
    label: 'Macao (+853)',
    name: 'Macao',
    isoCode: '+853',
    countryCode: 'MO',
    label2: 'MO (+853)',
  },
  {
    mask: '99 999 999',
    label: 'Macedonia (+389)',
    name: 'Macedonia',
    isoCode: '+389',
    countryCode: 'MK',
    label2: 'MK (+389)',
  },
  {
    mask: '99 99 99999',
    label: 'Madagascar (+261)',
    name: 'Madagascar',
    isoCode: '+261',
    countryCode: 'MG',
    label2: 'MG (+261)',
  },
  {
    mask: '9 9999 9999',
    label: 'Malawi (+265)',
    name: 'Malawi',
    isoCode: '+265',
    countryCode: 'MW',
    label2: 'MW (+265)',
  },
  {
    mask: '99 999 9999',
    label: 'Malaysia (+60)',
    name: 'Malaysia',
    isoCode: '+60',
    countryCode: 'MY',
    label2: 'MY (+60)',
  },
  {
    mask: '999 9999',
    label: 'Maldives (+960)',
    name: 'Maldives',
    isoCode: '+960',
    countryCode: 'MV',
    label2: 'MV (+960)',
  },
  {
    mask: '99 99 9999',
    label: 'Mali (+223)',
    name: 'Mali',
    isoCode: '+223',
    countryCode: 'ML',
    label2: 'ML (+223)',
  },
  {
    mask: '9999 9999',
    label: 'Malta (+356)',
    name: 'Malta',
    isoCode: '+356',
    countryCode: 'MT',
    label2: 'MT (+356)',
  },
  {
    mask: '999 9999',
    label: 'Marshall Islands (+692)',
    name: 'Marshall Islands',
    isoCode: '+692',
    countryCode: 'MH',
    label2: 'MH (+692)',
  },
  {
    mask: '999 99 99 99',
    label: 'Martinique (+596)',
    name: 'Martinique',
    isoCode: '+596',
    countryCode: 'MQ',
    label2: 'MQ (+596)',
  },
  {
    mask: '99 99 9999',
    label: 'Mauritania (+222)',
    name: 'Mauritania',
    isoCode: '+222',
    countryCode: 'MR',
    label2: 'MR (+222)',
  },
  {
    mask: '999 9999',
    label: 'Mauritius (+230)',
    name: 'Mauritius',
    isoCode: '+230',
    countryCode: 'MU',
    label2: 'MU (+230)',
  },
  {
    mask: '999 9999',
    label: 'Micronesia, Federated States of Micronesia (+691)',
    name: 'Micronesia, Federated States of Micronesia',
    isoCode: '+691',
    countryCode: 'FM',
    label2: 'FM (+691)',
  },
  {
    mask: '9999 9999',
    label: 'Moldova (+373)',
    name: 'Moldova',
    isoCode: '+373',
    countryCode: 'MD',
    label2: 'MD (+373)',
  },
  {
    mask: '999 999 999',
    label: 'Monaco (+377)',
    name: 'Monaco',
    isoCode: '+377',
    countryCode: 'MC',
    label2: 'MC (+377)',
  },
  {
    mask: '99 99 9999',
    label: 'Mongolia (+976)',
    name: 'Mongolia',
    isoCode: '+976',
    countryCode: 'MN',
    label2: 'MN (+976)',
  },
  {
    mask: '99 999 999',
    label: 'Montenegro (+382)',
    name: 'Montenegro',
    isoCode: '+382',
    countryCode: 'ME',
    label2: 'ME (+382)',
  },
  {
    mask: '999 9999',
    label: 'Montserrat (+1664)',
    name: 'Montserrat',
    isoCode: '+1664',
    countryCode: 'MS',
    label2: 'MS (+1664)',
  },
  {
    mask: '99 9999 999',
    label: 'Morocco (+212)',
    name: 'Morocco',
    isoCode: '+212',
    countryCode: 'MA',
    label2: 'MA (+212)',
  },
  {
    mask: '99 999 999',
    label: 'Mozambique (+258)',
    name: 'Mozambique',
    isoCode: '+258',
    countryCode: 'MZ',
    label2: 'MZ (+258)',
  },
  {
    mask: '99 999 999',
    label: 'Myanmar (+95)',
    name: 'Myanmar',
    isoCode: '+95',
    countryCode: 'MM',
    label2: 'MM (+95)',
  },
  {
    mask: '99 999 9999',
    label: 'Namibia (+264)',
    name: 'Namibia',
    isoCode: '+264',
    countryCode: 'NA',
    label2: 'NA (+264)',
  },
  {
    mask: '999 9999',
    label: 'Nauru (+674)',
    name: 'Nauru',
    isoCode: '+674',
    countryCode: 'NR',
    label2: 'NR (+674)',
  },
  {
    mask: '9999 999999',
    label: 'Nepal (+977)',
    name: 'Nepal',
    isoCode: '+977',
    countryCode: 'NP',
    label2: 'NP (+977)',
  },
  {
    mask: '99 999 9999',
    nameES: 'Paises Bajos',
    label: 'Netherlands (+31)',
    name: 'Netherlands',
    isoCode: '+31',
    countryCode: 'NL',
    label2: 'NL (+31)',
  },
  {
    mask: '99 9999',
    label: 'New Caledonia (+687)',
    name: 'New Caledonia',
    isoCode: '+687',
    countryCode: 'NC',
    label2: 'NC (+687)',
  },
  {
    mask: '999 999 9999',
    label: 'New Zealand (+64)',
    name: 'New Zealand',
    isoCode: '+64',
    countryCode: 'NZ',
    label2: 'NZ (+64)',
  },
  {
    mask: '9999 9999',
    label: 'Nicaragua (+505)',
    name: 'Nicaragua',
    isoCode: '+505',
    countryCode: 'NI',
    label2: 'NI (+505)',
  },
  {
    mask: '99 99 9999',
    label: 'Niger (+227)',
    name: 'Niger',
    isoCode: '+227',
    countryCode: 'NE',
    label2: 'NE (+227)',
  },
  {
    mask: '999 999 9999',
    label: 'Nigeria (+234)',
    name: 'Nigeria',
    isoCode: '+234',
    countryCode: 'NG',
    label2: 'NG (+234)',
  },
  {
    mask: '9999',
    label: 'Niue (+683)',
    name: 'Niue',
    isoCode: '+683',
    countryCode: 'NU',
    label2: 'NU (+683)',
  },
  {
    mask: '999 999',
    label: 'Norfolk Island (+672)',
    name: 'Norfolk Island',
    isoCode: '+672',
    countryCode: 'NF',
    label2: 'NF (+672)',
  },
  {
    mask: '999 9999',
    label: 'Northern Mariana Islands (+1670)',
    name: 'Northern Mariana Islands',
    isoCode: '+1670',
    countryCode: 'MP',
    label2: 'MP (+1670)',
  },
  {
    mask: '999 99 999',
    label: 'Norway (+47)',
    name: 'Norway',
    isoCode: '+47',
    countryCode: 'NO',
    label2: 'NO (+47)',
  },
  {
    mask: '99 999 999',
    label: 'Oman (+968)',
    name: 'Oman',
    isoCode: '+968',
    countryCode: 'OM',
    label2: 'OM (+968)',
  },
  {
    mask: '999 999 9999',
    label: 'Pakistan (+92)',
    name: 'Pakistan',
    isoCode: '+92',
    countryCode: 'PK',
    label2: 'PK (+92)',
  },
  {
    mask: '999 9999',
    label: 'Palau (+680)',
    name: 'Palau',
    isoCode: '+680',
    countryCode: 'PW',
    label2: 'PW (+680)',
  },
  {
    mask: '99 999 9999',
    label: 'Palestinian Territory, Occupied (+970)',
    name: 'Palestinian Territory, Occupied',
    isoCode: '+970',
    countryCode: 'PS',
    label2: 'PS (+970)',
  },
  {
    mask: '999 9999',
    label: 'Panama (+507)',
    name: 'Panama',
    isoCode: '+507',
    countryCode: 'PA',
    label2: 'PA (+507)',
  },
  {
    mask: '999 99 999',
    label: 'Papua New Guinea (+675)',
    name: 'Papua New Guinea',
    isoCode: '+675',
    countryCode: 'PG',
    label2: 'PG (+675)',
  },
  {
    mask: '999 999 999',
    label: 'Paraguay (+595)',
    name: 'Paraguay',
    isoCode: '+595',
    countryCode: 'PY',
    label2: 'PY (+595)',
  },
  {
    mask: '999 999 999',
    label: 'Peru (+51)',
    name: 'Peru',
    isoCode: '+51',
    countryCode: 'PE',
    label2: 'PE (+51)',
  },
  {
    mask: '999 999 9999',
    label: 'Philippines (+63)',
    name: 'Philippines',
    isoCode: '+63',
    countryCode: 'PH',
    label2: 'PH (+63)',
  },
  {
    mask: '999 999 999',
    label: 'Poland (+48)',
    name: 'Poland',
    isoCode: '+48',
    countryCode: 'PL',
    label2: 'PL (+48)',
  },
  {
    mask: '99 999 9999',
    label: 'Portugal (+351)',
    name: 'Portugal',
    isoCode: '+351',
    countryCode: 'PT',
    label2: 'PT (+351)',
  },
  {
    mask: '999 9999',
    label: 'Puerto Rico (+1939)',
    name: 'Puerto Rico',
    isoCode: '+1939',
    countryCode: 'PR',
    label2: 'PR (+1939)',
  },
  {
    mask: '9999 9999',
    label: 'Qatar (+974)',
    name: 'Qatar',
    isoCode: '+974',
    countryCode: 'QA',
    label2: 'QA (+974)',
  },
  {
    mask: '99999 9999',
    label: 'Reunion (+262)',
    name: 'Reunion',
    isoCode: '+262',
    countryCode: 'RE',
    label2: 'RE (+262)',
  },
  {
    mask: '99 999 9999',
    label: 'Romania (+40)',
    name: 'Romania',
    isoCode: '+40',
    countryCode: 'RO',
    label2: 'RO (+40)',
  },
  {
    mask: '999 999 99 99',
    label: 'Russia (+7)',
    name: 'Russia',
    isoCode: '+7',
    countryCode: 'RU',
    label2: 'RU (+7)',
  },
  {
    mask: '999 999 999',
    label: 'Rwanda (+250)',
    name: 'Rwanda',
    isoCode: '+250',
    countryCode: 'RW',
    label2: 'RW (+250)',
  },
  {
    mask: '9999',
    label: 'Saint Helena, Ascension and Tristan Da Cunha (+290)',
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    isoCode: '+290',
    countryCode: 'SH',
    label2: 'SH (+290)',
  },
  {
    mask: '999 9999',
    label: 'Saint Kitts and Nevis (+1869)',
    name: 'Saint Kitts and Nevis',
    isoCode: '+1869',
    countryCode: 'KN',
    label2: 'KN (+1869)',
  },
  {
    mask: '999 9999',
    label: 'Saint Lucia (+1758)',
    name: 'Saint Lucia',
    isoCode: '+1758',
    countryCode: 'LC',
    label2: 'LC (+1758)',
  },
  {
    mask: '999 9999',
    label: 'Saint Vincent and the Grenadines (+1784)',
    name: 'Saint Vincent and the Grenadines',
    isoCode: '+1784',
    countryCode: 'VC',
    label2: 'VC (+1784)',
  },
  {
    mask: '99 9999',
    label: 'Samoa (+685)',
    name: 'Samoa',
    isoCode: '+685',
    countryCode: 'WS',
    label2: 'WS (+685)',
  },
  {
    mask: '9999 999999',
    label: 'San Marino (+378)',
    name: 'San Marino',
    isoCode: '+378',
    countryCode: 'SM',
    label2: 'SM (+378)',
  },
  {
    mask: '99 99999',
    label: 'Sao Tome and Principe (+239)',
    name: 'Sao Tome and Principe',
    isoCode: '+239',
    countryCode: 'ST',
    label2: 'ST (+239)',
  },
  {
    mask: '99999 9999',
    label: 'Saudi Arabia (+966)',
    name: 'Saudi Arabia',
    isoCode: '+966',
    countryCode: 'SA',
    label2: 'SA (+966)',
  },
  {
    mask: '99 999 9999',
    label: 'Senegal (+221)',
    name: 'Senegal',
    isoCode: '+221',
    countryCode: 'SN',
    label2: 'SN (+221)',
  },
  {
    mask: '99 999 9999',
    label: 'Serbia (+381)',
    name: 'Serbia',
    isoCode: '+381',
    countryCode: 'RS',
    label2: 'RS (+381)',
  },
  {
    mask: '9 999 999',
    label: 'Seychelles (+248)',
    name: 'Seychelles',
    isoCode: '+248',
    countryCode: 'SC',
    label2: 'SC (+248)',
  },
  {
    mask: '99 999999',
    label: 'Sierra Leone (+232)',
    name: 'Sierra Leone',
    isoCode: '+232',
    countryCode: 'SL',
    label2: 'SL (+232)',
  },
  {
    mask: '9999 9999',
    label: 'Singapore (+65)',
    name: 'Singapore',
    isoCode: '+65',
    countryCode: 'SG',
    label2: 'SG (+65)',
  },
  {
    mask: '999 999 999',
    label: 'Slovakia (+421)',
    name: 'Slovakia',
    isoCode: '+421',
    countryCode: 'SK',
    label2: 'SK (+421)',
  },
  {
    mask: '99 999 999',
    label: 'Slovenia (+386)',
    name: 'Slovenia',
    isoCode: '+386',
    countryCode: 'SI',
    label2: 'SI (+386)',
  },
  {
    mask: '999 9999',
    label: 'Solomon Islands (+677)',
    name: 'Solomon Islands',
    isoCode: '+677',
    countryCode: 'SB',
    label2: 'SB (+677)',
  },
  {
    mask: '99 999 999',
    label: 'Somalia (+252)',
    name: 'Somalia',
    isoCode: '+252',
    countryCode: 'SO',
    label2: 'SO (+252)',
  },
  {
    mask: '99 999 9999',
    label: 'South Africa (+27)',
    name: 'South Africa',
    isoCode: '+27',
    countryCode: 'ZA',
    label2: 'ZA (+27)',
  },
  {
    mask: '999 999 9999',
    label: "Korea, Democratic People's Republic of Korea",
    name: 'South Korea (+850)',
    isoCode: '+850',
    countryCode: 'KR',
    label2: 'KR (+850)',
  },
  {
    mask: '99 999 9999',
    label: 'South Sudan (+211)',
    name: 'South Sudan',
    isoCode: '+211',
    countryCode: 'SS',
    label2: 'SS (+211)',
  },
  {
    mask: '999 999 999',
    label: 'Spain (+34)',
    name: 'Spain',
    isoCode: '+34',
    countryCode: 'ES',
    label2: 'ES (+34)',
  },
  {
    mask: '99 999 9999',
    label: 'Sri Lanka (+94)',
    name: 'Sri Lanka',
    isoCode: '+94',
    countryCode: 'LK',
    label2: 'LK (+94)',
  },
  {
    mask: '99 999 9999',
    label: 'Sudan (+249)',
    name: 'Sudan',
    isoCode: '+249',
    countryCode: 'SD',
    label2: 'SD (+249)',
  },
  {
    mask: '999 9999',
    label: 'Suriname (+597)',
    name: 'Suriname',
    isoCode: '+597',
    countryCode: 'SR',
    label2: 'SR (+597)',
  },
  {
    mask: '99 99 9999',
    label: 'Swaziland (+268)',
    name: 'Swaziland',
    isoCode: '+268',
    countryCode: 'SZ',
    label2: 'SZ (+268)',
  },
  {
    mask: '99 999 9999',
    label: 'Sweden (+46)',
    name: 'Sweden',
    isoCode: '+46',
    countryCode: 'SE',
    label2: 'SE (+46)',
  },
  {
    mask: '99 999 9999',
    label: 'Switzerland (+41)',
    name: 'Switzerland',
    isoCode: '+41',
    countryCode: 'CH',
    label2: 'CH (+41)',
  },
  {
    mask: '99 9999 999',
    label: 'Syrian Arab Republic (+963)',
    name: 'Syrian Arab Republic',
    isoCode: '+963',
    countryCode: 'SY',
    label2: 'SY (+963)',
  },
  {
    mask: '9 9999 9999',
    label: 'Taiwan (+886)',
    name: 'Taiwan',
    isoCode: '+886',
    countryCode: 'TW',
    label2: 'TW (+886)',
  },
  {
    mask: '99 999 9999',
    label: 'Tajikistan (+992)',
    name: 'Tajikistan',
    isoCode: '+992',
    countryCode: 'TJ',
    label2: 'TJ (+992)',
  },
  {
    mask: '99 999 9999',
    label: 'Tanzania, United Republic of Tanzania (+255)',
    name: 'Tanzania, United Republic of Tanzania',
    isoCode: '+255',
    countryCode: 'TZ',
    label2: 'TZ (+255)',
  },
  {
    mask: '99 999 9999',
    label: 'Thailand (+66)',
    name: 'Thailand',
    isoCode: '+66',
    countryCode: 'TH',
    label2: 'TH (+66)',
  },
  {
    mask: '999 9999',
    label: 'Timor-Leste (+670)',
    name: 'Timor-Leste',
    isoCode: '+670',
    countryCode: 'TL',
    label2: 'TL (+670)',
  },
  {
    mask: '99 999 999',
    label: 'Togo (+228)',
    name: 'Togo',
    isoCode: '+228',
    countryCode: 'TG',
    label2: 'TG (+228)',
  },
  {
    mask: '9999',
    label: 'Tokelau (+690)',
    name: 'Tokelau',
    isoCode: '+690',
    countryCode: 'TK',
    label2: 'TK (+690)',
  },
  {
    mask: '99999',
    label: 'Tonga (+676)',
    name: 'Tonga',
    isoCode: '+676',
    countryCode: 'TO',
    label2: 'TO (+676)',
  },
  {
    mask: '999 9999',
    label: 'Trinidad and Tobago (+1868)',
    name: 'Trinidad and Tobago',
    isoCode: '+1868',
    countryCode: 'TT',
    label2: 'TT (+1868)',
  },
  {
    mask: '99 999 999',
    label: 'Tunisia (+216)',
    name: 'Tunisia',
    isoCode: '+216',
    countryCode: 'TN',
    label2: 'TN (+216)',
  },
  {
    mask: '999 999 9999',
    label: 'Turkey (+90)',
    name: 'Turkey',
    isoCode: '+90',
    countryCode: 'TR',
    label2: 'TR (+90)',
  },
  {
    mask: '9 999 9999',
    label: 'Turkmenistan (+993)',
    name: 'Turkmenistan',
    isoCode: '+993',
    countryCode: 'TM',
    label2: 'TM (+993)',
  },
  {
    mask: '999 9999',
    label: 'Turks and Caicos Islands (+1649)',
    name: 'Turks and Caicos Islands',
    isoCode: '+1649',
    countryCode: 'TC',
    label2: 'TC (+1649)',
  },
  {
    mask: '999999',
    label: 'Tuvalu (+688)',
    name: 'Tuvalu',
    isoCode: '+688',
    countryCode: 'TV',
    label2: 'TV (+688)',
  },
  {
    mask: '999 999 999',
    label: 'Uganda (+256)',
    name: 'Uganda',
    isoCode: '+256',
    countryCode: 'UG',
    label2: 'UG (+256)',
  },
  {
    mask: '99 999 99 99',
    label: 'Ukraine (+380)',
    name: 'Ukraine',
    isoCode: '+380',
    countryCode: 'UA',
    label2: 'UA (+380)',
  },
  {
    mask: '9 999 9999',
    label: 'United Arab Emirates (+971)',
    name: 'United Arab Emirates',
    isoCode: '+971',
    countryCode: 'AE',
    label2: 'AE (+971)',
  },
  {
    mask: '999 999 9999',
    label: 'United States (+1)',
    name: 'United States',
    isoCode: '+1',
    countryCode: 'US',
    label2: 'US (+1)',
  },
  {
    mask: '9 999 99 99',
    label: 'Uruguay (+598)',
    name: 'Uruguay',
    isoCode: '+598',
    countryCode: 'UY',
    label2: 'UY (+598)',
  },
  {
    mask: '99 999 9999',
    label: 'Uzbekistan (+998)',
    name: 'Uzbekistan',
    isoCode: '+998',
    countryCode: 'UZ',
    label2: 'UZ (+998)',
  },
  {
    mask: '99 99999',
    label: 'Vanuatu (+678)',
    name: 'Vanuatu',
    isoCode: '+678',
    countryCode: 'VU',
    label2: 'VU (+678)',
  },
  {
    mask: '999 999 9999',
    label: 'Venezuela, Bolivarian Republic of Venezuela (+58)',
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    isoCode: '+58',
    countryCode: 'VE',
    label2: 'VE (+58)',
  },
  {
    mask: '99 9999 999',
    label: 'Vietnam (+84)',
    name: 'Vietnam',
    isoCode: '+84',
    countryCode: 'VN',
    label2: 'VN (+84)',
  },
  {
    mask: '999 9999',
    label: 'Virgin Islands, British (+1284)',
    name: 'Virgin Islands, British',
    isoCode: '+1284',
    countryCode: 'VG',
    label2: 'VG (+1284)',
  },
  {
    mask: '999 9999',
    label: 'Virgin Islands, U.S. (+1340)',
    name: 'Virgin Islands, U.S.',
    isoCode: '+1340',
    countryCode: 'VI',
    label2: 'VI (+1340)',
  },
  {
    mask: '99 9999',
    label: 'Wallis and Futuna (+681)',
    name: 'Wallis and Futuna',
    isoCode: '+681',
    countryCode: 'WF',
    label2: 'WF (+681)',
  },
  {
    mask: '999 999 999',
    label: 'Yemen (+967)',
    name: 'Yemen',
    isoCode: '+967',
    countryCode: 'YE',
    label2: 'YE (+967)',
  },
  {
    mask: '99 999 9999',
    label: 'Zambia (+260)',
    name: 'Zambia',
    isoCode: '+260',
    countryCode: 'ZM',
    label2: 'ZM (+260)',
  },
  {
    mask: '9 999999',
    label: 'Zimbabwe (+263)',
    name: 'Zimbabwe',
    isoCode: '+263',
    countryCode: 'ZW',
    label2: 'ZW (+263)',
  },
];

export default countryCodes;
