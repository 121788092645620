import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import 'mobx-react-lite/batchingForReactDom';
import TagManager from 'react-gtm-module';

import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './containers';
import store from '@store/index';
import axiosConfig from '@configs/axiosConfig';
import i18nInit from '@translations/i18ninit';
import { StoreContext } from '@hooks/useStore';
import ReactPixel from 'react-facebook-pixel';
import { fbPixelId, gtmId, smartLookId } from '@configs/variables';
import SmartlookClient from 'smartlook-client';
import { CustomThemeProvider } from '@yaydoo/react-components/lib/theme/CustomThemeProvider';

configure({ enforceActions: 'observed' });

i18nInit();
axiosConfig();

ReactPixel.init(fbPixelId, undefined, {
  autoConfig: true,
  debug: false,
});

TagManager.initialize({ gtmId });

SmartlookClient.init(smartLookId);

ReactDOM.render(
  <CustomThemeProvider>
    <StoreContext.Provider value={store}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </StoreContext.Provider>
  </CustomThemeProvider>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
