import React from 'react';
import { CircularProgress, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface LoaderProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    spinner: {
      color: theme.palette.secondary.dark,
    },
  }),
);

const Loader: React.FC<LoaderProps> = () => {
  const css = useStyles();
  return (
    <div className={css.container} data-testid="loader">
      <CircularProgress className={css.spinner} size={50} thickness={5} />
    </div>
  );
};

export default Loader;
