import axios, { AxiosError } from 'axios';
import i18n from 'i18next';
import { flow, Instance, types } from 'mobx-state-tree';

interface IErrors extends AxiosError {
  error: boolean;
  msg: string;
  errorCode: string;
}

import endpoints from '@configs/endpoints';
import {
  AddressType,
  BusinessType,
  businessTypeNames,
  DocumentType,
  VerificationStatus,
} from '@constants/enum';
import { isRFCFisica, isRFCMoral } from '@utils/taxIdValidator';
import {
  monthlyAvgIncomeOption,
  numberOfEmployeesOption,
  yearsOfOperatioOption,
} from '@utils/utils';
import Address from './address';
import Common from './common';
import DisbursementDetails from './disbursementDetails';
import FinancialQuestions from './financialQuestions';
import Shareholders, { TAXID_LENGTH } from './shareholders';
import UserDocument, {
  DocCatalog,
  Document,
  IUserDocument,
  TUserDocumentStore,
} from './userDocument';
import StakeholderStore from './stakeholders';

const BUSINESS_UNAVAILABLE = 'BUSINESS_UNAVAILABLE';

export type VerificationStatusStrings = keyof typeof VerificationStatus;

const Phone = types.model({
  countryCode: '',
  fullPhoneNumber: '',
});

export const BusinessDocs = types
  .compose(
    Common,
    types.model({
      aoi: types.optional(types.array(UserDocument), []),
      cif: UserDocument,
      signature: UserDocument,
      propertyCert: UserDocument,
    }),
  )
  .views((self) => ({
    get canSubmitAoiFiles(): boolean {
      return self.aoi.every((file: TUserDocumentStore) => file.canSubmit);
    },
    get documentsArray() {
      return ['aoi', 'cif', 'signature', 'propertyCert'];
    },
  }))
  .views((self) => ({
    get lastAoiFileIdx(): number {
      return self.aoi.length - 1;
    },
    get canSubmit(): boolean {
      return !!(
        self.cif.uploaded &&
        self.signature.uploaded &&
        self.propertyCert.uploaded &&
        self.canSubmitAoiFiles
      );
    },
  }))
  .actions((self) => ({
    addAoiFile: () => {
      self.aoi.push(
        UserDocument.create({
          isMainContent: false,
          document: Document.create({}),
          documentType: DocumentType.ART_OF_INCORPORATION,
        }),
      );
    },
    removeAoiFile: flow(function* () {
      yield self.aoi[self.lastAoiFileIdx].deleteDocument();
      if (self.lastAoiFileIdx > 0) {
        self.aoi.pop();
      }
    }),
  }))
  .actions((self) => ({
    getAllDocuments: flow(function* () {
      const { data } = yield axios.get(endpoints.getDocument(DocumentType.ART_OF_INCORPORATION));
      const { kycOfficialDocuments: docs } = data;

      const hasAoIFiles = docs?.some(
        (row: { documentContentType: [string] }) =>
          row.documentContentType[0] === DocumentType[DocumentType.ART_OF_INCORPORATION],
      );
      if (hasAoIFiles) {
        self.update({
          aoi: [],
        });
      }

      docs?.forEach((doc: TUserDocumentStore & IUserDocument) => {
        const [documentContentType] = doc.documentContentType;
        const modelDocumentKey = self[DocCatalog[documentContentType]];

        if (documentContentType === DocumentType[DocumentType.ART_OF_INCORPORATION]) {
          self.aoi.push(
            UserDocument.create({
              isMainContent: false,
              document: Document.create({
                name: doc.files[0].originalName || '',
                type: doc.files[0].mimeType || '',
                size: +(doc.files[0].size || 0),
              }),
              documentType: DocumentType.ART_OF_INCORPORATION,
              side: doc?.side || '',
              uploaded: !!doc,
              documentId: doc?.documentId || '',
              documentHref: doc?.documentHref || '',
              documentContentId: doc?.documentContentId || '',
            }),
          );
        } else if (modelDocumentKey?.updateField) {
          modelDocumentKey.updateField('uploaded', true);
          modelDocumentKey.updateField('documentId', doc?.documentId);
          modelDocumentKey.updateField('documentHref', doc?.documentHref);
          modelDocumentKey.updateField('documentContentId', doc?.documentContentId);
          modelDocumentKey.document.updateField('name', doc.files[0].originalName);
          modelDocumentKey.document.updateField('type', doc.files[0].mimeType);
          modelDocumentKey.document.updateField('size', +(doc.files[0].size || 0));
        }
      });
    }),
    addAoiFile: () => {
      self.aoi.push(
        UserDocument.create({
          isMainContent: false,
          document: Document.create({}),
          documentType: DocumentType.ART_OF_INCORPORATION,
        }),
      );
    },
  }));

const Business = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      isFetching: false,
      submitting: false,
      businessId: '',
      country: '',
      businessType: BusinessType.UNKNOWN_BUSINESS_TYPE,
      businessName: '',
      razonSocial: '',
      taxId: '',
      isTaxIdValid: types.union(types.boolean, types.null),
      taxIdStatus: VerificationStatus.UNKNOWN_VERIFICATION_STATUS,
      businessAddress: Address,
      personalAddress: Address,
      status: '',
      createdAt: '',
      service: '',
      phone: types.maybeNull(Phone),
      isLoading: false,
      namefromRfc: '',
      isUserPMDocSupported: types.maybeNull(types.boolean),
      chatWithChaClosed: false,
      numberOfEmployees: types.optional(types.string, '', [null]),
      yearsOfOperation: types.optional(types.string, '', [null]),
      monthlyAvgIncome: types.optional(types.string, '', [null]),
      website: '',
      taxIdAvailability: '',
      givesOutInvoices: types.union(types.boolean, types.null),
      onlyShareholder: types.union(types.boolean, types.null),
      isControllingShareholder: types.union(types.boolean, types.null),
      copyPersonalAddress: false,
      shareholders: Shareholders,
      shareCapital: 0,
      financialQuestions: types.optional(FinancialQuestions, {}),
      disbursementDetails: types.optional(DisbursementDetails, {}),
      businessDocs: BusinessDocs,
      isVerified: false,
      stakeholders: types.optional(StakeholderStore, {}),
    }),
  )
  .views((self) => ({
    get taxIdUnavailable(): boolean {
      return self.taxIdAvailability === BUSINESS_UNAVAILABLE;
    },
    get taxIdLength(): number {
      return self.taxId.length;
    },
    get businessTypeStr(): businessTypeNames {
      return self.businessType === 2 ? businessTypeNames.pm : businessTypeNames.pf;
    },
  }))
  .views((self) => ({
    get taxIdError(): boolean {
      if (self.taxIdUnavailable) return true;
      return !!(TAXID_LENGTH.includes(self.taxIdLength) ? !self.isTaxIdValid : false);
    },
    get canSubmitBusinessInformation(): boolean {
      const { user } = self.globalStore;
      const pfCanSubmit: boolean = typeof self.givesOutInvoices === 'boolean';
      const pmCanSubmit: boolean =
        pfCanSubmit && self.razonSocial.length > 0 && user.taxId.length > 0 && !!user.isTaxIdValid;
      const questionnaireCanSubmit: boolean =
        self.businessType === BusinessType.PERSONA_FISICA ? pfCanSubmit : pmCanSubmit;
      return !!(
        self.isTaxIdValid &&
        user.occupation.length > 0 &&
        self.businessName.length > 0 &&
        self.monthlyAvgIncome.length > 0 &&
        self.yearsOfOperation.length > 0 &&
        self.numberOfEmployees.length > 0 &&
        self.businessAddress.canSubmit &&
        questionnaireCanSubmit
      );
    },
    get isPM(): boolean {
      return self.businessType === BusinessType.PERSONA_MORALE;
    },
  }))
  .actions((self) => ({
    setTaxId: (taxId: string) => {
      self.taxIdAvailability = '';
      const taxIdUpperCase = taxId.toUpperCase();
      self.taxId = taxIdUpperCase;
      self.isTaxIdValid = self.taxIdLength
        ? isRFCFisica(taxIdUpperCase) || isRFCMoral(taxIdUpperCase)
        : false;
    },
  }))
  .actions((self) => ({
    update: (data: typeof self) => {
      self.businessId = data.businessId;
      self.setTaxId(data.taxId);
      self.businessType = BusinessType[data.businessType as BusinessType] as unknown as number;

      if (data.businessAddress) {
        self.businessAddress.update(data.businessAddress);
      }

      self.isFetched = data.isFetched || self.isFetched;

      if (data.shareCapital) {
        self.shareCapital = +data.shareCapital;
      }

      if (self.taxIdUnavailable) {
        self.isTaxIdValid = false;
      } else {
        self.isTaxIdValid = true;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateBusinessDetail: (businessDetail: any) => {
      const detail = JSON.parse(businessDetail.detail || '{}');
      self.updateField('givesOutInvoices', !!detail?.givesOutInvoices);
      self.updateField('businessName', businessDetail?.businessName);
      self.updateField('razonSocial', businessDetail?.razonSocial);
      self.updateField('service', businessDetail?.industry);
      self.updateField(
        'monthlyAvgIncome',
        monthlyAvgIncomeOption(businessDetail?.minMonthlyIncome, businessDetail?.maxMonthlyIncome),
      );
      self.updateField(
        'numberOfEmployees',
        numberOfEmployeesOption(
          businessDetail?.minEmployeesNumber,
          businessDetail?.maxEmployeesNumber,
        ),
      );
      self.updateField(
        'yearsOfOperation',
        yearsOfOperatioOption(
          businessDetail?.minYearsOfOperation,
          businessDetail?.maxYearsOfOperation,
        ),
      );
      self.updateField('website', businessDetail?.siteUrl);
    },
    checkVerification: (data: { status: VerificationStatusStrings }[]) => {
      const [{ status }] = data;
      const statusNumber = VerificationStatus[status] as number;

      self.isVerified = [VerificationStatus.VERIFIED, VerificationStatus.CHA_VERIFIED].includes(
        statusNumber,
      );
    },
  }))
  .actions((self) => ({
    getBusiness: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.business);

        if (data.success && data.businesses) {
          self.checkVerification(data.businesses);
          self.update(data.businesses[0]);
          self.updateBusinessDetail(data?.businessDetail);
          self.isFetched = true;
        }
      } catch (error) {
        //
      }
    }),
    fetchTaxIdDetails: flow(function* () {
      try {
        self.isLoading = true;
        self.businessType = BusinessType.UNKNOWN_BUSINESS_TYPE;
        self.givesOutInvoices = null;
        const encodeTaxId = encodeURIComponent(self.taxId);
        const { data } = yield axios.get(endpoints.checkRfc(encodeTaxId));

        if (data.success) {
          self.businessType = BusinessType[data.business.businessType] as unknown as number;
          self.namefromRfc = data.business.name;
          self.taxIdStatus = VerificationStatus[data.business.taxIdStatus] as unknown as number;
          self.taxIdAvailability = data.msg;

          if (self.isPM) {
            self.businessAddress.updateField('type', AddressType.LEGAL);
          } else {
            self.businessAddress.updateField('type', AddressType.HOME);
          }
        }
      } catch (error) {
        const { app } = self.globalStore;
        const { errorCode, msg } = error as IErrors;
        app.openToast({
          type: 'error',
          title: errorCode,
          message: msg || 'Error trying to validate RFC',
        });
        self.isTaxIdValid = false;
      } finally {
        self.isLoading = false;
      }
    }),
    getAddresses: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.addresses);

        data.addresses.forEach((addr: { type: string }) => {
          if (addr.type === 'PERSONAL') {
            self.personalAddress.update(addr);
          }

          if (addr.type === 'LEGAL') {
            self.businessAddress.update(addr);
          }
        });
      } catch (error) {
        // TODO: define what to do in case of error
      }
    }),
    createBusiness: flow(function* (businessBody, businessDetailBody) {
      const { app } = self.globalStore;
      try {
        self.submitting = true;
        const { data } = yield axios.post(endpoints.business, businessBody);
        const { businessId } = data.business;
        self.businessId = businessId;

        businessDetailBody.businessDetail.businessId = businessId;

        const { data: detailData } = yield axios.post(endpoints.onboardingData, businessDetailBody);
        if (detailData.error) throw detailData;

        app.setNextScreen();
      } catch (error) {
        if ((error as { errorCode: string })?.errorCode === 'DUPLICATE') {
          app.openToast({
            type: 'error',
            title: i18n.t('common.errorTitle'),
            message: i18n.t('businessInfo.errors.duplicateText'),
            toastDuration: 5000,
          });
        }
      } finally {
        self.submitting = false;
      }
    }),
    addShares: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield axios.patch(endpoints.business, {
          shareCapital: self.shareCapital,
        });
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    verifyKycDocuments: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.post(endpoints.verifyKycDocuments, {});
        return data;
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TBusinessStore = Instance<typeof Business>;

export default Business;
