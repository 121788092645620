/* eslint-disable max-len */

export default {
  example: 'Ejemplo:',
  chatText: 'Un agente te puede ayudar',
  header: {
    title: '¡Tu registro está casi listo!',
    description:
      'Mientras revisamos tus datos, por favor compártenos los documentos de tu negocio. Un agente te ayudará a hacerlo paso a paso.',
    chat: 'Chatear con un agente ',
  },
  documents: {
    title: 'Documentos',
    upload: 'Subir',
  },
  docList: {
    title: 'documentos subidos',
  },
  oficialIdentification: {
    title: 'Identificación oficial',
    link: '¿Cómo la obtengo?',
  },
  applicantSelfieVideo: {
    title: 'Video selfie',
    link: '¿Cómo lo obtengo?',
  },
  proofOfAddress: {
    title: 'Comprobante de domicilio',
    link: '¿Cómo lo obtengo?',
  },
  personalDocumentation: 'Documentación personal',
  businessDocumentation: 'Documentación De tu negocio',
  artOfIncorporation: {
    title: 'Acta constitutiva',
    link: '¿Cómo la obtengo?',
  },
  fiscalIdentification: {
    title: 'Cédula de Identificación fiscal',
    link: '¿Cómo la obtengo?',
  },
  publicRegistryOfProperty: {
    title: 'Certificado del registro público de la propiedad',
    link: '¿Cómo lo obtengo?',
  },
  proofOfFiel: {
    title: 'E-firma vigente (antes Firma electrónica)',
    link: '¿Cómo la obtengo?',
  },
  stakeholder: {
    title: 'Información de tus socios',
    description:
      'Si tu empresa tiene otros dueños además de ti, necesitaremos verificar su identidad. Un agente te ayudará a cada paso del camino.',
    titleSt: 'Información de tus socios',
    titleDoc: 'Documentación de tus socios',
    empty:
      'La información de tus socios aparecerá aquí una vez que el agente verifique la información',
  },
  fileUpload: {
    title: 'Cargar archivos',
    description: 'Arrastra uno o varios archivos PDF, o presiona para explorar tus documentos.',
  },
  fileUploader: {
    title: 'Sube tus documentos',
    send: 'Enviar',
  },
  actaConstitutiva: {
    title: 'Acta constitutiva',
    description:
      ' Si aún no la tienes, acude con el notario o corredor público que te certificó y obtén una copia. Si tu acta tiene más de cinco años, deberás hacer el trámite en el archivo general de notarías.',
  },
  identification: {
    title: 'Identificación oficial',
    description: 'Sube tu credencial del INE o pasaporte.',
    idTitle: 'Credencial del INE',
    idDescription: 'Captura el frente y el reverso de tu credencial.',
    passportTitle: 'Pasaporte',
    passportDescription: 'Escanea el interior de tu pasaporte.',
  },
  identificationFisica: {
    title: 'Cédula de identificación fiscal',
    description:
      'Si no la tienes, entra a la página del SAT y busca “Trámites del RFC”, selecciona “Obtén tu cédula de identificación fiscal” y da clic en “Ejecutar en línea”.',
  },
  poa: {
    title: 'Comprobante de domicilio',
    description1: 'Deberá contener la dirección desde donde opera tu negocio.',
    description2: 'Deberá contener la dirección desde donde opera tu negocio.',
    description3: 'Asegúrate de que el documento esté bien iluminado y sin reflejos.',
    description4: 'Antigüedad menor a tres meses.',
  },
  deFirma: {
    title: 'Comprobante de registro de Firma electrónica',
    description:
      'Si no tienes el comprobante, realiza una captura de pantalla del portal de SAT que contenga el número de serie y la vigencia.',
  },
  registroPublic: {
    title: 'Certificado del registro público de la propiedad',
    description:
      'Si no lo tienes, acércate a un notario o corredor público autorizado, ya que ellos son los que pueden ayudarte con el registro. Al finalizar el trámite, te proporcionarán una boleta de inscripción con tu Folio Mercantil Electrónico.',
  },
  videoSelfie: {
    title: 'Cómo subir tu video',
    description1:
      'Retira cualquier accesorio que cubra tu rostro. Coloca tu rostro dentro del marco y gíralo dos veces para capturar todos los ángulos.',
    description2: 'Puedes subir tu video directamente o enviárselo a un agente a través del chat.',
  },
  snackbar: {
    title: 'Archivo enviado',
    description: 'Revisaremos tus documentos.',
  },
  status: {
    approved: 'Aprobado',
    notReceived: 'No recibido',
    received: 'Recibido',
    verified: 'Verificado',
    pending: 'Pendiente',
  },
};
