import { authApp } from '@configs/variables';
import routes from '@constants/routes';

const redirectToAuthApp = (routeStr?: string): void => {
  const redirectUri = encodeURIComponent(window.location.href);
  const route = routeStr || routes.LOGIN;
  // to avoid unwanted onboarding records, onboarding_type query param is not sent
  const replaceUri = `${authApp}${route}?redirect_uri=${redirectUri}`;
  window.location.replace(replaceUri);
};

export default redirectToAuthApp;
