import axios from 'axios';
import { applyPatch, flow, IJsonPatch, Instance, types } from 'mobx-state-tree';

import endpoints from '@configs/endpoints';
import getSaveFinancialBody from '@utils/getSaveFinancialBody';
import utils from '@utils/utils';
import Common from './common';

export const Answers = types
  .model({
    financialOperationQuestionnaireId: '',
    bankName: '',
    hasBankAccountNumber: true,
    clabe: '',
    monthlyDepositAmount: '',
    monthlyDepositAmountMin: '',
    monthlyDepositAmountMax: '',
    monthlyDepositAmountCurrency: '$',
    monthlyDepositQuantity: '',
    monthlyDepositQuantityMin: '',
    monthlyDepositQuantityMax: '',
    monthlyDepositFrequency: '',
    incomeSource: types.optional(types.array(types.string), []),
    expenditureNature: types.optional(types.array(types.string), []),
    accountOperationTypes: types.optional(types.array(types.string), []),
    accountOperationNature: types.optional(types.array(types.string), []),
  })
  .views((self) => ({
    get canSubmit() {
      return (
        ((self.clabe && self.bankName) || !self.hasBankAccountNumber) &&
        self.monthlyDepositAmount &&
        self.monthlyDepositQuantity &&
        self.monthlyDepositFrequency &&
        self.incomeSource.length &&
        self.expenditureNature.length &&
        self.accountOperationTypes.length &&
        self.accountOperationNature.length
      );
    },
  }))
  .actions((self) => ({
    update: (data: typeof self) => {
      const validKeys = Object.keys(data).filter((key: string) => self.hasOwnProperty(key));
      const patches: IJsonPatch[] = validKeys.map((key) => ({
        op: 'replace',
        path: `/${key}`,
        value: Array.isArray(data[key]) ? data[key] : data[key] + '',
      }));
      applyPatch(self, patches);
    },
  }));

export type AnswerType = Instance<typeof Answers>;
export type AnswerKeys = keyof AnswerType;

const FinancialQuestions = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      isLoading: false,
      isSubmitting: false,
      bankFetchFromClabe: false,
      answers: types.optional(Answers, {}),
    }),
  )
  .views((self) => ({
    get businessId() {
      return self.globalStore.business.businessId;
    },
    get userId() {
      return self.globalStore.user.userId;
    },
    get app() {
      return self.globalStore.app;
    },
    get canSubmit() {
      return self.answers.canSubmit && !self.isSubmitting;
    },
  }))
  .actions((self) => ({
    update: (data: { financialOperationQuestionnaire: AnswerType }) => {
      self.answers.update(data.financialOperationQuestionnaire);

      self.answers.monthlyDepositQuantity = utils.getStringFromMinMax(
        self.answers.monthlyDepositQuantityMin,
        self.answers.monthlyDepositQuantityMax,
        '',
      );
      self.answers.monthlyDepositAmount = utils.getStringFromMinMax(
        self.answers.monthlyDepositAmountMin,
        self.answers.monthlyDepositAmountMax,
        '$',
      );
    },
  }))
  .actions((self) => ({
    fetchAnswers: flow(function* () {
      try {
        self.isLoading = true;
        const { data } = yield axios.get(endpoints.getQuestionaire);
        if (data.success) {
          self.update(data);
          self.isFetched = true;
        }
      } catch (error) {
        console.warn(error);
      } finally {
        self.isLoading = false;
      }
    }),
    calculateClabeForControlDigit: () => {
      const weightingFactor = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];

      try {
        const changedClabe = self.answers.clabe;
        const weightedArrItems = [];
        for (let i = 0; i < changedClabe.length - 1; i++) {
          weightedArrItems.push(parseInt(changedClabe[i]) * weightingFactor[i]);
        }
        let weightedValue = 0;
        weightedArrItems.forEach((item, index) => {
          weightedArrItems[index] = item % 10;
          weightedValue += item % 10;
        });
        const controlDigit = (10 - (weightedValue % 10)) % 10;
        return `${changedClabe.trim().slice(0, -1)}${controlDigit}`;
      } catch (err) {
        throw new Error(err as string);
      }
    },
    updateField: (fieldName: AnswerKeys, value: string | boolean) => {
      const currentValue = self.answers[fieldName];

      if (typeof currentValue === 'string') {
        (self.answers[fieldName] as string) = value as string;
      } else if (typeof currentValue === 'boolean') {
        (self.answers[fieldName] as boolean) = value as boolean;
      } else {
        if (currentValue.includes(value as string)) {
          (self.answers[fieldName] as string[]) = currentValue.filter(
            (val: string) => val !== value,
          );
        } else {
          (self.answers[fieldName] as string[]) = currentValue.concat(value as string);
        }
      }
    },
    submitAnswer: flow(function* () {
      try {
        self.isSubmitting = true;
        const body = getSaveFinancialBody({
          ...self.answers,
          businessId: self.businessId,
          userId: self.userId,
          currentScreen: self.app.nextScreen,
          nextScreen: self.app.getNextScreen(self.app.nextScreen),
        });
        const { data } = yield axios.post(endpoints.onboardingData, body);

        if (data.nextSection) {
          self.app.updateField('nextSection', data.nextSection);
        }

        self.app.setNextScreen();
      } catch (error) {
        console.warn(error);
      } finally {
        self.isSubmitting = false;
      }
    }),
    validateClabe: flow(function* () {
      try {
        const { data } = yield axios.post(endpoints.getBankFromClabe, {
          clabe: self.answers.clabe,
        });

        if (data.bankInfo) {
          self.answers.bankName = data.bankInfo.bankName;
          self.bankFetchFromClabe = true;
        } else {
          if (self.bankFetchFromClabe) {
            self.bankFetchFromClabe = false;
            self.answers.bankName = '';
          }
        }
      } catch (error) {
        console.warn(error);
      }
    }),
  }));

export type TFinancialQuestionsStore = Instance<typeof FinancialQuestions>;

export default FinancialQuestions;
