import axios from 'axios';
import { flow, getSnapshot, Instance, types } from 'mobx-state-tree';

import endpoints from '@configs/endpoints';
import { DocumentFileType, DocumentType } from '@constants/enum';
import Common from './common';

const SIDE_FRONT = 'front';

export const DocCatalog = {
  [DocumentType[DocumentType.ART_OF_INCORPORATION]]: 'aoi',
  [DocumentType[DocumentType.FISCAL_IDENTIFICATION]]: 'cif',
  [DocumentType[DocumentType.PROOF_OF_FIEL]]: 'signature',
  [DocumentType[DocumentType.PUBLIC_REGISTRY_OF_PROPERTY_AND_COMMERCE_PROPERTY]]: 'propertyCert',
};

const PM_BUSINESS_FILETYPES = [
  DocumentType.ART_OF_INCORPORATION,
  DocumentType.FISCAL_IDENTIFICATION,
  DocumentType.PROOF_OF_FIEL,
  DocumentType.PUBLIC_REGISTRY_OF_PROPERTY_AND_COMMERCE_PROPERTY,
];

export const Document = types.compose(
  Common,
  types.model({
    name: '',
    size: 0,
    type: '',
  }),
);

export interface IUserDocument {
  documentContentType: string[];
  files: Array<{
    originalName: string;
    mimeType: string;
    size: string;
  }>;
}

const UserDocument = types
  .compose(
    Common,
    types.model({
      documentId: '',
      documentHref: '',
      documentContentId: '',
      document: Document,
      documentType: DocumentType.UNKNOWN_DOCUMENT,
      uploading: false,
      uploaded: false,
      error: false,
      submitting: false,
      side: types.optional(types.string, SIDE_FRONT),
      isMainContent: false,
    }),
  )
  .views((self) => ({
    get canSubmit(): boolean {
      if (self.document.name) return self.uploaded;
      return true;
    },
    get documentSnapshot() {
      return getSnapshot(self.document);
    },
    get docType() {
      return DocumentType[self.documentType];
    },
    get uplodType() {
      if (
        self.document.type === 'application/pdf' ||
        self.documentType === DocumentType.EXTERNAL_ACCOUNT_STATEMENT
      ) {
        return DocumentFileType.DOCUMENT;
      }
      return DocumentFileType.IMAGE;
    },
    get isPmType() {
      return PM_BUSINESS_FILETYPES.includes(self.documentType);
    },
    get canSubmitManually(): boolean {
      return !!self.document.name;
    },
  }))
  .actions((self) => ({
    update: (doc: typeof self & IUserDocument) => {
      self.side = doc.side;
      self.uploaded = true;
      self.documentId = doc.documentId;
      self.documentHref = doc.documentHref;
      self.documentContentId = doc.documentContentId;
      self.document.updateField('name', doc.files[0].originalName);
      self.document.updateField('type', doc.files[0].mimeType);
      self.document.updateField('size', +doc.files[0].size);
    },
  }))
  .actions((self) => ({
    uploadFileV3: flow(function* (file: File) {
      self.error = false;
      self.uploaded = false;
      self.uploading = true;

      try {
        const formData = new FormData();
        /* Note: all documents that are related to business
         * have the uploadType of ART_OF_INCORPORATION
         */
        const uploadType = String(self.documentType);
        formData.append('file', file);
        formData.append('side', self.side);
        formData.append('type', uploadType);
        formData.append('documentContentType', String(self.documentType));
        formData.append('documentUploadType', String(self.uplodType));

        const { data } = yield axios.post(endpoints.kycDocumentV3, formData);
        console.log(data);
        //const { kycOfficialDocument: doc } = data;
        //self.update(doc);
        self.uploaded = true;
      } catch (error) {
        self.error = true;
        throw error;
      } finally {
        self.uploading = false;
      }
    }),
    uploadFile: flow(function* (file: File) {
      self.error = false;
      self.uploaded = false;
      self.uploading = true;
      try {
        const formData = new FormData();
        /* Note: all documents that are related to business
         * have the uploadType of ART_OF_INCORPORATION
         */
        const uploadType = String(
          self.isPmType ? DocumentType.ART_OF_INCORPORATION : self.documentType,
        );
        formData.append('file', file);
        formData.append('side', self.side);
        formData.append('type', uploadType);
        formData.append('documentContentType', String(self.documentType));
        formData.append('documentUploadType', String(self.uplodType));
        if (self.isMainContent) {
          formData.append('isMainContent', String(self.isMainContent));
        }

        const { data } = yield axios.post(endpoints.kycDocument, formData);

        const { kycOfficialDocument: doc } = data;
        self.update(doc);
        self.uploaded = true;
      } catch (error) {
        self.error = true;
      } finally {
        self.uploading = false;
      }
    }),
    getDocument: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield axios.get(endpoints.getDocument(self.documentType));
        const { kycOfficialDocuments: docs } = data;

        if (docs) {
          const doc = docs.find((row: { type: string; documentContentType: [string] }) => {
            if (!self.isPmType) {
              return row.type === self.docType;
            }
            return row.documentContentType[0] === self.docType;
          });
          self.update(doc);
        }

        return docs;
      } catch (error) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    deleteDocument: flow(function* (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      const canDeleteBE =
        self.documentType === DocumentType.ART_OF_INCORPORATION &&
        self.documentId &&
        self.documentContentId;

      if (e) e.preventDefault();
      self.uploading = true;
      self.uploaded = false;
      if (canDeleteBE) {
        self.submitting = true;
        const body = {
          type: self.documentType,
          documentId: self.documentId,
          documentContentId: self.documentContentId,
        };
        try {
          yield axios.delete(endpoints.kycDocument, {
            data: body,
          });
          self.resetStore();
        } catch (error) {
          //
        } finally {
          self.submitting = false;
          self.uploading = false;
        }
      } else {
        self.resetStore();
        self.uploading = false;
      }
      return true;
    }),
  }));

export type TUserDocumentStore = Instance<typeof UserDocument>;

export default UserDocument;
