/* eslint-disable max-len */

export default {
  continue: 'Guardar',
  addPep: 'Añadir cargo',
  addAnother: 'Anadir otro cargo',
  form: {
    personName: 'Nombre del familiar',
    publicOfficeName: 'Cargo Público',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de fin',
    present: 'Presente',
  },
  pf: {
    pepExposed: {
      title: 'Personas políticamente expuestas',
      formTitle: 'Detalles de los cargos',
      description:
        '¿Desempeñas o has desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional?',
    },
    pepRelation: {
      title: 'Personas Políticamente Expuestas entre familares',
      formTitle: 'Datos de familiares',
      description:
        '¿Eres cónyuge, concubina, concubinario o mantienes parentesco por consanguinidad o afinidad hasta el segundo grado con una Persona Políticamente Expuesta?',
    },
    pepLink: {
      title: 'Vínculos con Personas Políticamente Expuestas',
      formTitle: 'Datos de tus socios',
      description: '¿Eres socio o asociado de una Persona Políticamente Expuesta?',
    },
    yes: 'Sí',
    no: 'No',
  },
  pm: {
    social:
      'Señala las personas que desempeñan un rol directivo o tienen control sobre las decisiones de la sociedad.',
    pepExposed: {
      title: 'Personas políticamente expuestas',
      formTitle: 'Detalles de los cargos',
      description:
        'Elige a aquellos socios o accionistas que desempeñan o hayan desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional.',
    },
    pepRelation: {
      title: 'Personas Políticamente Expuestas entre familares',
      formTitle: 'Datos de familiares',
      description:
        'Señala los socios o accionistas que son cónyuges, concubinos, concubinarios o mantienen parentesco por consanguinidad o afinidad hasta el segundo grado con una Persona Políticamente Expuesta',
    },
    pepLink: {
      title: 'Vínculos con Personas Políticamente Expuestas',
      formTitle: 'Datos de tus socios',
      description:
        'Indica los socios o accionistas que son socios o están asociados a una Persona Políticamente Expuesta en alguna empresa.',
    },
  },
};
