import { DocumentType, VerificationStatus } from '@constants/enum';

const { KYC_PROCESS_DOCUMENT_SUBMISSION_SUCCESS } = VerificationStatus;

// collection of endpoints goes here
export default {
  login: '/auth/login/mfa',
  loginWithMfa: '/auth/verify/mfa',
  resendMfa: '/auth/verify/mfa/resend',
  loginWithSut: '/auth/single-use-token',
  resetEmail: '/user/password/reset/request',
  resetPassword: '/user/password/reset',
  verifyResetCode: (code: string): string => `/user/verification/password/${code}`,
  preverification: (email: string): string => `/user/preverification/create/${email}`,
  checkIfEmailExists: (email: string): string => `/user/verification/email/${email}`,
  validateUserPreverification: (code: string): string => `/user/validate/usercreation/${code}`,
  createAccountWithPassword: '/user/account/create',
  logout: '/auth/logout',
  getUser: '/user',
  checkCurp: '/user/curp',
  checkRfc: (rfc: string): string => `/business?rfc=${rfc}`,
  submitRfc: '/rfc-business',
  addressFromZipCode: (zip: string): string => `/business/location?postalCode=${zip}`,
  submitAddress: '/business/address',
  business: '/business',
  businessQuestions: '/user/businesstype/questionnaire',
  userInteraction: (actionName: string): string => `/interaction/last/${actionName}`,
  validatePhone: '/user/phone/validation',
  getDocument: (documentType: number | string): string =>
    `/kyc/business/document?documentType=${documentType}`,
  getOccupation: '/business/occupations',
  onboardingProgress: '/user/onboarding/progress',
  onboardingData: '/user/onboarding/data',
  addresses: '/user/addresses',
  kycDocument: '/kyc/business/document',
  kycDocumentV3: '/kyc/document',
  getApplicantId: '/kyc/applicant',
  verifyKycDocuments: '/kyc/initiate/verification/document',
  shareholders: '/business/stakeholders',
  shareholder: '/business/stakeholder',
  getKycApplicantState: `/onboarding/applicant/state?documentType=${DocumentType.PASSPORT}`,
  getDocumentStatus: '/onboarding/morale/document/status',
  updateKycApplicantStatus: `/kyc/applicant/status/${KYC_PROCESS_DOCUMENT_SUBMISSION_SUCCESS}`,
  stakeholderByTaxId: (taxId: string): string => `/business/stakeholder/${taxId}`,
  deleteShareholder: (stakeholderId: string): string => `/business/stakeholder/${stakeholderId}`,
  sendStakeholderEmails: '/business/stakeholder/process/email',
  getBankFromClabe: '/clabe/bankname',
  getQuestionaire: '/business/financial/questionaire',
  // PEP Questionaire PF
  getPepQuestionaire: (questionaireType: number): string =>
    `/business/pep/questionnaire?questionnaireType=${questionaireType}`,
  updatePepQuestionaire: '/business/pep/questionnaire',
  removePepQuestionaire: '/business/pep/questionnaire',
  // PEP Questionaire PM
  getStakeholderPepQuestionaire: (stkId: string, type: number): string =>
    `/business/stakeholder/pep/questionnaire?stakeholderId=${stkId}&questionnaireType=${type}`,
  updateStakeholderPepQuestionaire: '/business/stakeholder/pep/questionnaire',
  removeStakeholderPepQuestionaire: '/business/stakeholder/pep/questionnaire',
  saveBeneficiaries: '/business/beneficiary',
  // send link
  sendLinkInSMS: '/user/onboarding/web/complete',
  // disbursement
  disbursement: '/loan/disbursement/clabe/external',
  getGeolocationByIp: '/geo-by-ip',
  // agent onboarding
  uploadDocument: '/onboarding/morale/document',
  // phone verification
  verifyPhonePin: '/user/verification/phone/',
  phone: '/user/phone',
  sendVerificationCode: '/user/verification/phone/code',
  onboardingState: (state: number): string => `/onboarding/state/${state}`,
  // partner business data (WIP)
  partnerBusiness: '/partner/business/detail',
};
