const ICONS = {
  CHECKBOX_FILLED: '/assets/images/checkbox-filled.svg',
  CHECKBOX_CIRCLE: '/assets/images/checkbox-circle.svg',
  CHECKBOX_SQUARE_FILLED: '/assets/images/checkbox-squarefilled.svg',
  CHECKBOX_SQUARE: '/assets/images/checkbox-square.svg',
  LIST_CLOSED: '/assets/images/list-closed.svg',
  LIST_OPEN: '/assets/images/list-opened.svg',
  ERROR_FILLED: '/assets/images/error-filled.svg',
  ATTACTH: '/assets/images/attach.svg',
  REMOVE: '/assets/images/remove.svg',
  CROSS: '/assets/images/cross-icon.svg',
  ADD: '/assets/images/add-grey.svg',
};

const CTA = {
  CHATTING: '/assets/images/chatting.svg',
  SUCCESS_ONB: '/assets/images/sucess-onb.png',
  PLAY_STORE: '/assets/images/play-store.svg',
  APP_STORE: '/assets/images/app-store.svg',
  VERIFICATION_PROCESS: '/assets/images/verification-process.svg',
};

const ANIMATIONS = {
  LOADER: '/assets/images/loader-variant.gif',
};

export { ICONS, CTA, ANIMATIONS };
