/* eslint-disable max-len */

const PERSONA_FISICA = 'pf';
const PERSONA_MORAL = 'pm';

const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const curpReg =
  /[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}(0?[0][1-9]|1?[1][0-2])(0?[0][1-9]|1?[1][0-9]|2?[2][0-9]|3?[3][0-1])[M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]/g;

export interface PasswordValid {
  hasNumber: boolean;
  hasCasing: boolean;
  validLength: boolean;
  hasSpecialCharacter: boolean;
}

export const isPhoneNumber = (numbStr: string): boolean => {
  if (numbStr.length === 0) return true;
  return /^(?=.*\d)[\d ]+$/.test(numbStr);
};

export const isCurpValid = (curp: string): boolean => {
  const curpRegex = new RegExp(curpReg);
  return curpRegex.test(curp?.toUpperCase());
};

export const isEmailValid = (email: string): boolean => {
  return emailReg.test(email?.toLowerCase());
};

export const isPasswordValid = (password: string): PasswordValid => ({
  hasNumber: /(?=(?:\D*\d){3})/.test(password),
  hasCasing: /^(.*?[A-Z]){2,}.*$/.test(password) && /^(.*?[a-z]){2,}.*$/.test(password),
  hasSpecialCharacter: /[!@#$%^&*(),.?":;{}|<>]/.test(password),
  validLength: password.length >= 10,
});

export const getNumberValueEndIdxAsPerRfc = (businessType: string): number => {
  return businessType === PERSONA_MORAL ? 9 : 10;
};

export const getValidRfcLength = (taxId: string): number => {
  if (taxId.length < 5) {
    return 13;
  }
  return /^[a-zA-Z]+$/.test(taxId.substring(0, 4).toUpperCase()) ? 13 : 12;
};

export const taxIdStatus = (rfc: string): { formatValid: boolean; businessType: string } => {
  const idx = 13 === rfc.length ? 4 : 3;
  const businessType = rfc.length === 13 ? PERSONA_FISICA : PERSONA_MORAL;

  const result =
    /^[a-zA-Z]+$/.test(rfc.substring(0, idx).toUpperCase()) &&
    !isNaN(rfc.substring(idx, getNumberValueEndIdxAsPerRfc(businessType)) as unknown as number);
  return { formatValid: result, businessType };
};

export const notNumbersReg = /^([^0-9]*)$/;
export const onlyNumbersReg = /^\d+$/;
export const lettersNumbersReg = /^[\w\s]*$/;
