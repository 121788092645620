/* eslint-disable max-len */

export default {
  title: '¡Listo!',
  contpaqi: {
    pf: {
      info:
        'Recibimos tu información y estamos listos para revisar tu solicitud. La revisión podría tomar algún tiempo. En cuanto tengamos noticias, te avisaremos vía correo electrónico sobre el estado de tu solicitud.' +
        '\n\nSi tu cuenta es pre-aprobada, podrás comenzar a cobrar con {{partnerName}} Ligas de Pago y tendrás 15 días naturales para completar tu expediente. ' +
        'Durante este tiempo, un agente se pondrá en contacto contigo para asistirte con este paso.' +
        '\n\nSi deseas adelantar este paso, puedes enviar el resto de tu documentación al siguiente correo: <0>compliance@oyster.io</0>',
      documents: [
        'Constancia de Situación Fiscal con vigencia no mayor a 3 meses.',
        'Comprobante de domicilio con vigencia no mayor a 3 meses.',
        'Constancia de e.firma con vigencia no mayor a 3 meses.',
      ],
      sendInfo:
        'Deberás enviar tus documentos a: <0>compliance@oyster.io</0> para que nuestro equipo los revise y los haga parte de tu perfil.',
    },
    pm: {
      info:
        'Recibimos tu información y estamos listos para revisar tu solicitud. La revisión podría tomar algún tiempo. En cuanto tengamos noticias, te avisaremos vía correo electrónico sobre el estado de tu solicitud.' +
        '\n\nSi tu cuenta es pre-aprobada, podrás comenzar a cobrar con {{partnerName}} Ligas de Pago y tendrás 15 días naturales para completar el expediente de tu empresa. ' +
        'Durante este tiempo, un agente se pondrá en contacto contigo para asistirte con este paso.' +
        '\n\nSi deseas adelantar este paso, puedes enviar el resto de tu documentación al siguiente correo: <0>compliance@oyster.io</0>',
      documents: [
        'Acta Constitutiva.',
        'Registro Público de Comercio.',
        'Constancia de Situación Fiscal.',
        'Comprobante de domicilio con vigencia no mayor a 3 Meses.',
        'Constancia de e.firma con vigencia no mayor a 3 meses.',
        'Identificación Oficial de los socios accionistas que tengan participación mayor al 25%.',
        'Poderes Legales (representante legal), asambleas modificatorias y actas notariales. **',
      ],
      additionalInfo: '** En caso de contar con ellas.',
      sendInfo:
        'Deberás enviar tus documentos a: <0>compliance@oyster.io</0> para que nuestro equipo los revise y los haga parte de tu perfil.',
    },
  },
  pf: {
    info:
      'Recibimos tu información y estamos listos para revisar tu solicitud. La revisión podría tomar algún tiempo. En cuanto tengamos noticias, te avisaremos vía correo electrónico sobre el estado de tu solicitud.' +
      '\n\nSi tu cuenta es pre-aprobada, podrás comenzar a cobrar con {{partnerName}} Ligas de Pago y tendrás 15 días naturales para completar tu expediente. ' +
      'Durante este tiempo, un agente se pondrá en contacto contigo para asistirte con este paso.' +
      '\n\nSi deseas adelantar este paso, puedes enviar el resto de tu documentación al siguiente correo: <0>compliance@oyster.io</0>',
    documents: [
      'Constancia de Situación Fiscal con vigencia no mayor a 3 meses.',
      'Comprobante de domicilio con vigencia no mayor a 3 meses.',
      'Constancia de e.firma con vigencia no mayor a 3 meses.',
    ],
    sendInfo:
      'Deberás enviar tus documentos a: <0>compliance@oyster.io</0> para que nuestro equipo los revise y los haga parte de tu perfil.',
  },
  pm: {
    info:
      'Gracias por enviar tu solicitud. Realizaremos una validación de la información proporcionada y te notificaremos el estado de tu solicitud por medio de correo electrónico en máximo 2 días hábiles.' +
      '\n\nPodrás comenzar a utilizar tu cuenta si no hay observaciones de nuestra parte. Sin embargo, un agente se pondrá en contacto contigo para recabar información adicional para completar el expediente del negocio como lo pide la regulación.',
    documents: [
      'Acta Constitutiva con Registro Público de Comercio.',
      'Constancia de Situación Fiscal con expedición no mayor a 3 meses.',
      'Comprobante de domicilio con vigencia no mayor a 3 meses.',
      'Constancia de e.firma con vigencia no mayor a 3 meses.',
    ],
    additionalInfo: '',
    sendInfo:
      'Es posible que te solicitemos otros documentos dependiendo del negocio. \n\nSi deseas adelantar este paso, puedes enviar el resto de tu documentación al siguiente correo: <0>compliance@oyster.io</0>',
  },
};
