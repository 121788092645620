import utils from '@utils/utils';

interface FinancialBody {
  currentScreen: string;
  nextScreen: string;
  financialOperationQuestionnaire: {
    financialOperationQuestionnaireId?: string;
    businessId: string;
    userId: string;
    bankName: string;
    clabe: string;
    monthlyDepositAmountMin: string;
    monthlyDepositAmountMax: string;
    monthlyDepositAmountCurrency: string;
    monthlyDepositQuantityMin: string;
    monthlyDepositQuantityMax: string;
    monthlyDepositFrequency: string;
    incomeSource?: string[];
    expenditureNature?: string[];
    accountOperationTypes?: string[];
    accountOperationNature?: string[];
    hasBankAccountNumber?: boolean;
    shareholderCount?: string;
    employeeCount?: string;
  };
}

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const getSaveFinancialBody = (data: any): FinancialBody => {
  const {
    businessId,
    userId,
    bankName,
    hasBankAccountNumber,
    clabe,
    monthlyDepositAmount,
    monthlyDepositAmountCurrency,
    monthlyDepositQuantity,
    monthlyDepositFrequency,
    currentScreen,
    nextScreen,
    incomeSource,
    expenditureNature,
    accountOperationNature,
    accountOperationTypes,
  } = data;
  const [monthlyDepositAmountMin, monthlyDepositAmountMax] = utils.getMinMaxFromString(
    monthlyDepositAmount,
    ' - ',
  );
  const [monthlyDepositQuantityMin, monthlyDepositQuantityMax] = utils.getMinMaxFromString(
    monthlyDepositQuantity,
    ' - ',
  );

  const body = {
    currentScreen,
    nextScreen,
    financialOperationQuestionnaire: {
      businessId,
      userId,
      monthlyDepositAmountCurrency,
      monthlyDepositAmountMax: monthlyDepositAmountMax || '0',
      monthlyDepositAmountMin: monthlyDepositAmountMin || '0',
      monthlyDepositFrequency,
      monthlyDepositQuantityMax: monthlyDepositQuantityMax || '0',
      monthlyDepositQuantityMin: monthlyDepositQuantityMin || '0',
      bankName,
      hasBankAccountNumber,
      clabe,
      incomeSource,
      expenditureNature,
      accountOperationNature,
      accountOperationTypes,
    },
  };

  return body;
};

export default getSaveFinancialBody;
