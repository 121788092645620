import axios from 'axios';
import { flow, Instance, types } from 'mobx-state-tree';

import endpoints from '@configs/endpoints';
import PEPQuestionaireStore from '@store/pepQuestions';
import { findDuplicates } from '@utils/hasDuplicates';
import { isRFCFisica, isRFCMoral } from '@utils/taxIdValidator';
import { isEmailValid } from '@utils/validators';
import Common from './common';

export const TAXID_LENGTH = [12, 13];

interface ShareholderRequest {
  name: string;
  email: string;
  taxId: string;
  ownershipPercent: number;
  isOrg: boolean;
  shareCapital: number;
  isInControl: boolean;
  stakeholderId?: string;
  legalRepName?: string;
  legalRepTaxId?: string;
}

export const Shareholder = types
  .compose(
    Common,
    types.model({
      name: '',
      taxId: '',
      email: '',
      stakeholderId: '',
      ownershipPercent: 0,
      shareCapital: 0,
      percentage: 0,
      hasControl: false,
      error: false,
      submitting: false,
      curpStatus: '',
      isTaxIdValid: types.union(types.boolean, types.null),
      legalRepName: '',
      legalRepTaxId: '',
      isLegalRepTaxIdValid: types.union(types.boolean, types.null),
      taxIdStatus: '',
      refId: '',
      legalRepTaxIdStatus: '',
      pepQuestionaire: types.optional(PEPQuestionaireStore, {}),
      isDuplicate: false,
      copyData: false,
      isFetched: false,
      beDisabled: false,
    }),
  )
  .views((self) => ({
    get emailIsAccount(): boolean {
      const { user } = self.globalStore;
      return self.email.trim() === user.email;
    },
    get taxIdIsNotAccount(): boolean {
      const { business } = self.globalStore;
      return self.taxId.trim() !== business.taxId;
    },
    get taxIdLength(): number {
      return self.taxId.length;
    },
  }))
  .views((self) => ({
    get emailValid(): boolean {
      const validEmail = isEmailValid(self.email);
      return self.email.length > 0 ? validEmail : true;
    },
    get isOrg(): boolean {
      return isRFCMoral(self.taxId);
    },
    get taxIdCorrectLength() {
      return TAXID_LENGTH.includes(self.taxIdLength);
    },
  }))
  .views((self) => ({
    get taxIdError(): boolean {
      return !!(self.taxIdCorrectLength ? !self.isTaxIdValid || !self.taxIdIsNotAccount : false);
    },
    get legalRepTaxIdError(): boolean {
      return !!(TAXID_LENGTH.includes(self.legalRepTaxId.length)
        ? !self.isLegalRepTaxIdValid
        : false);
    },
    get percentageStr(): string {
      return `${self.ownershipPercent} %`;
    },
  }))
  .views((self) => ({
    get canSubmit(): boolean {
      const repCanSubmit = self.isOrg
        ? !self.legalRepTaxIdError && self.legalRepName.length >= 2
        : true;
      return !!(
        !self.taxIdError &&
        self.taxIdCorrectLength &&
        self.emailValid &&
        self.email.length >= 2 &&
        self.name.length >= 2 &&
        repCanSubmit
      );
    },
  }))
  .actions((self) => ({
    setLegalRepTaxId: (legalRepTaxId: string) => {
      self.legalRepTaxId = legalRepTaxId.toUpperCase();
      self.isLegalRepTaxIdValid = isRFCFisica(legalRepTaxId);
    },
    setTaxId: (taxId: string) => {
      self.taxId = taxId.toUpperCase();
      self.isTaxIdValid = self.emailIsAccount
        ? isRFCFisica(taxId)
        : isRFCFisica(taxId) || isRFCMoral(taxId);
    },
  }))
  .actions((self) => ({
    copyUserData: () => {
      const { user } = self.globalStore;
      self.name = user.fullName;
      self.email = user.email;
      self.setTaxId(user.taxId);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update: (data: any) => {
      const { business } = self.globalStore;
      self.name = data.name;
      self.email = data.email;
      self.stakeholderId = data.stakeholderId;
      self.shareCapital = +data.shareCapital;
      self.error = data.error;
      self.submitting = data.submitting;
      self.curpStatus = data.curpStatus;
      self.taxIdStatus = data.taxIdStatus;
      self.legalRepName = data.legalRepName;
      self.refId = data.refId;
      self.setTaxId(data.taxId);
      self.pepQuestionaire.updateField('pepExposed', !!data.pepExposed);
      self.pepQuestionaire.updateField('pepRelation', !!data.pepRelation);
      self.pepQuestionaire.updateField('pepLink', !!data.pepLink);
      self.hasControl = !!data.isInControl;
      self.isFetched = true;

      if (data.ownershipPercent) {
        const ownershipPercent = parseInt(data.ownershipPercent, 10);
        self.ownershipPercent = ownershipPercent;
        self.shareCapital = (business.shareCapital / 100) * ownershipPercent;
      }

      if (self.emailIsAccount) {
        self.copyData = true;
      }

      if (data.legalRepTaxId) {
        self.setLegalRepTaxId(data.legalRepTaxId);
      }
    },
  }))
  .actions((self) => ({
    saveShareholder: flow(function* (shares: number) {
      self.submitting = true;
      self.isDuplicate = false;
      const method = self.stakeholderId ? 'patch' : 'post';
      try {
        let body: ShareholderRequest = {
          name: self.name,
          email: self.email,
          taxId: self.taxId,
          ownershipPercent: self.ownershipPercent,
          isOrg: self.isOrg,
          shareCapital: (shares / 100) * self.ownershipPercent,
          isInControl: self.hasControl,
        };
        if (self.legalRepName && self.legalRepTaxId && self.isOrg) {
          body = {
            ...body,
            legalRepName: self.legalRepName,
            legalRepTaxId: self.legalRepTaxId,
          };
        }
        if (self.stakeholderId) {
          body = { ...body, stakeholderId: self.stakeholderId };
        }

        const { data } = yield axios({
          method,
          url: endpoints.shareholder,
          data: body,
        });

        return data;
      } catch (error) {
        if ((error as { errorCode: string })?.errorCode === 'DUPLICATE') {
          self.isDuplicate = true;
          return;
        }
        throw error;
      } finally {
        self.submitting = false;
      }
    }),
    removeShareholder: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield axios.delete(endpoints.deleteShareholder(self.stakeholderId));
        self.resetStore();
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    getStakeholderInfoByTaxId: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield axios.get(endpoints.stakeholderByTaxId(self.taxId));
        if (data?.stakeholder?.name && data?.stakeholder?.email) {
          self.update(data.stakeholder);
          return { ...data?.stakeholder, exists: true };
        }
        return { ...data?.stakeholder, exists: false };
      } catch {
        return { exists: false };
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type ShareholderType = Instance<typeof Shareholder>;

const Shareholders = types
  .compose(
    Common,
    types.model({
      all: types.optional(types.array(Shareholder), []),
      selected: -1,
      submitting: false,
      success: false,
      error: false,
    }),
  )
  .views((self) => ({
    get totalPercentage() {
      return self.all.reduce(
        (accumulator: number, shareholder: ShareholderType) =>
          shareholder.ownershipPercent + accumulator,
        0,
      );
    },
    get allEmails(): string[] {
      return self.all.map((item: ShareholderType) => item.email);
    },
    get allTaxIds(): string[] {
      return self.all.map((item: ShareholderType) => item.taxId);
    },
  }))
  .views((self) => ({
    get shareholderCount() {
      return self.all.length;
    },
    get percentageError() {
      return self.all.length > 0 ? self.totalPercentage !== 100 : false;
    },
  }))
  .views((self) => ({
    get canSubmit() {
      const everyItemCanSubmit = self.all.every((item: ShareholderType) => item.canSubmit);
      return !!(everyItemCanSubmit && self.all.length > 0 && !self.percentageError);
    },
    get hasDuplicate() {
      return self.all.some((item: ShareholderType) => item.isDuplicate);
    },
    get anySubmitting() {
      return self.all.some((item: ShareholderType) => item.submitting);
    },
    get findTaxIdDuplicates(): string[] {
      const taxIds: string[] = [];
      self.all.forEach((item: ShareholderType) => {
        if (item.taxId) taxIds.push(item.taxId);
      });
      return findDuplicates(taxIds);
    },
  }))
  .actions((self) => ({
    sortShareholders: () => {
      const sortedStk = self.all.sort((a, b) => a.ownershipPercent - b.ownershipPercent);
      self.update({ all: sortedStk });
    },
    sendStakeholderEmails: flow(function* () {
      self.submitting = true;
      const { data } = yield axios.post(endpoints.sendStakeholderEmails);
      return data;
    }),
  }))
  .actions((self) => ({
    saveShareholders: flow(function* () {
      self.updateField('submitting', true);

      try {
        const { business, app } = self.globalStore;
        yield business.addShares();

        const promises = self.all.map((item) =>
          item.saveShareholder(business.shareCapital).catch((error: Error) => ({ error })),
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any[] = yield Promise.all(promises);
        const hasError = data.some((rsp: { error: boolean }) => rsp.error);
        if (hasError) throw data;

        data.forEach((row: { stakeholder: { stakeholderId: string } }, idx: number) => {
          if (row?.stakeholder?.stakeholderId) {
            self.all[idx].updateField('stakeholderId', row?.stakeholder?.stakeholderId);
          }
        });

        yield self.sendStakeholderEmails();
        yield app.saveNextScreen();
        return data;
      } finally {
        self.updateField('submitting', false);
      }
    }),
    removeAllShareholders: () => {
      self.update({ all: [] });
    },
    addShareholder: (skipPercentage?: boolean) => {
      self.all.push(
        Shareholder.create({
          ownershipPercent: 100,
          isTaxIdValid: null,
          isLegalRepTaxIdValid: null,
        }),
      );
      if (!skipPercentage) {
        if (self.shareholderCount === 3) {
          self.all[0].ownershipPercent = 34;
          self.all[1].ownershipPercent = 33;
          self.all[2].ownershipPercent = 33;
        } else if (self.shareholderCount > 0) {
          self.all.forEach((shareholder) => {
            shareholder.ownershipPercent = Math.floor(100 / self.shareholderCount);
          });
        }
      }
      self.selected = self.shareholderCount - 1;
    },
    removeLastShareholder: async () => {
      self.all.pop();

      if (self.shareholderCount === 3) {
        self.all[0].ownershipPercent = 34;
        self.all[1].ownershipPercent = 33;
        self.all[2].ownershipPercent = 33;
      } else if (self.shareholderCount > 0) {
        self.all.forEach((shareholder) => {
          shareholder.ownershipPercent = Math.floor(100 / self.shareholderCount);
        });
      }
    },
  }))
  .actions((self) => ({
    removeShareholder: async () => {
      if (self.shareholderCount > 1) {
        const idx = self.shareholderCount - 1;
        const isInBE = !!self.all[idx].stakeholderId;

        if (isInBE) {
          try {
            await self.all[idx].removeShareholder();
          } catch (error) {
            return;
          }
        }
        self.removeLastShareholder();
      }
    },
    getShareholders: flow(function* () {
      self.submitting = true;
      self.removeAllShareholders();
      try {
        const { data } = yield axios.get(endpoints.shareholders);

        data?.stakeholders.forEach((shareholder: ShareholderType, idx: number) => {
          self.addShareholder(true);
          self.all[idx].update(shareholder);
          self.all[idx].updateField('beDisabled', true);
        });
      } catch (error) {
        //
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TShareholdersStore = Instance<typeof Shareholders>;

export default Shareholders;
