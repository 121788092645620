import React, { ReactElement } from 'react';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle, AlertProps } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { TransitionProps } from '@material-ui/core/transitions';

import { ICONS } from '@constants/images';

export interface ToastProps extends AlertProps {
  open: boolean;
  vertical?: 'top' | 'bottom';
  horizontal?: 'center' | 'left' | 'right';
  message?: string;
  type: 'success' | 'error';
}

function SuccessIcon() {
  return <img src={ICONS.CHECKBOX_FILLED} alt="Success" />;
}

function ErrorIcon() {
  return <img src={ICONS.ERROR_FILLED} alt="Error" />;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<typeof Toast> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      {...props}
      direction="down"
      timeout={{
        enter: 300,
        exit: 300,
      }}
      ref={ref}
    />
  );
});

const iconClose = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '30px',
      padding: '0.5rem 0.1rem',
      width: 'auto',
      minWidth: '30px',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: () => ({
      borderRadius: '40px',
      backgroundColor: theme.oysterTheme.cotton,
      color: theme.oysterTheme.obsidian,
      fontSize: '14px',
      fontWeight: 'normal',
      minWidth: '300px',
      maxWidth: '500px',
      boxShadow: 'none',
      filter: 'drop-shadow(0px 16px 24px rgba(143, 148, 153, 0.16))',
      '& .MuiAlertTitle-root': {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
        color: theme.palette.success.main,
      },
      '&.toast-Error': {
        '& .MuiAlertTitle-root': {
          color: theme.palette.error.main,
        },
      },
      '& .MuiAlert-icon': { '& img': { width: 24 } },
    }),
  }),
);

function Toast({
  open,
  type = 'success',
  title = '',
  onClose,
  vertical = 'top',
  horizontal = 'center',
  message = '',
}: ToastProps): ReactElement {
  const classes = useStyles({ type });
  const isError = type === 'error';
  const buttonClasses = iconClose();
  const Icon = isError ? ErrorIcon : SuccessIcon;

  return (
    <Snackbar
      TransitionComponent={Transition}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onClose}
    >
      <Alert
        className={isError ? 'toast-Error' : 'toast-Success'}
        classes={classes}
        elevation={2}
        data-testid="toastComponent"
        variant="filled"
        icon={<Icon />}
        action={
          <Button
            classes={buttonClasses}
            onClick={onClose}
            data-testid="toastAction"
            color="inherit"
            size="small"
          >
            <img src={ICONS.CROSS} alt="Close" />
          </Button>
        }
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
