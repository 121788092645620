import { v4 as uuidv4 } from 'uuid';
import { deviceIdFirstChar, deviceIdSecondChar } from '@configs/variables';

const generateDeviceId = (email: string): string => {
  const skip = [8, 13, 18, 23];
  let deviceId = uuidv4();
  const firstChar = email.charAt(Number(deviceIdFirstChar));
  const secondChar = email.charAt(Number(deviceIdSecondChar));
  let firstCharCode = email.charCodeAt(0) % 36;

  if (skip.includes(firstCharCode)) {
    firstCharCode += 1;
  }

  const secondCharCode = (firstCharCode + 5) % 36;

  deviceId = `${deviceId.substring(0, firstCharCode)}${firstChar}${deviceId.substring(
    firstCharCode + 1,
  )}`.toLowerCase();

  return `${deviceId.substring(0, secondCharCode)}${secondChar}${deviceId.substring(
    secondCharCode + 1,
  )}`.toLowerCase();
};

export default generateDeviceId;
