/* eslint-disable max-len */

export default {
  title: 'Ingresa los datos de tu negocio',
  info: 'Cuéntanos sobre tu negocio, esto nos permitirá ofrecerte mejores opciones y productos en el futuro.',
  rfcAdvice:
    'Si no reconoces este RFC, deberás contactar a la persona que te compartió este enlace para verificar que es correcto.',
  addressInfo: {
    title: 'Domicilio fiscal',
    info: {
      pf: 'Ingresa el domicilio fiscal tal y como aparece en tu cédula de identificación fiscal.',
      pm: 'Ingresa el domicilio fiscal tal y como aparece en la cédula de identificación fiscal.',
    },
  },
};
