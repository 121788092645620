/* eslint-disable max-len */

export default {
  title: 'Información de los socios',
  info: 'Por favor, agrega la información de tus socios. Les enviaremos un correo electrónico para verificar sus identidades y autorizarte como representante de tu negocio.',
  counterLabel: 'Selecciona el número de accionistas incluyéndote a ti',
  shareholderLabel: 'Socio {{idx}}',
  copyData: 'Copiar mis datos',
  hasControl:
    'Esta persona desempeña un rol directivo o tiene control sobre las decisiones de la sociedad.',
  percentage: 'Porcentaje de acciones ',
  companyShares: 'Según tu Acta Constitutiva, indica el total de acciones emitidas inicialmente',
  sharesLabel: 'Total de acciones',
  legalRepName: 'Representante legal',
  legalRepTaxId: 'RFC del representante legal',
  shareholderExist: 'Ya tenemos a tu socio registrado',
  shareholderExistInfo: 'Revisa sus datos para continuar',
  shareholderUser: 'Hemos agregado tus datos como socio',
  errors: {
    emailIsAccount: 'El email debe ser diferente al del usuario actual',
    emailIsDuplicate: 'El correo ya esta registrado a otra cuenta.',
    taxIdDuplicate: 'El RFC ingresado esta duplicado',
    emailIsDuplicateTmp:
      'El correo {{email}} ya esta registrado a otra cuenta, por favor ingresa otro.',
  },
};
