import ReactPixel from 'react-facebook-pixel';
const numberWithComma = (value: number | string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getStringFromMinMax = (min: string, max: string, prefix: string): string => {
  if (!min) {
    return '';
  }
  if (!max) {
    return `${prefix}${numberWithComma(min)} - en adelante`;
  }
  return `${prefix}${numberWithComma(min)} - ${prefix}${numberWithComma(max)}`;
};

const getMinMaxFromString = (value: string, spliter: string): [string, string] => {
  const [min, max] = value.split(spliter);
  const minValue = min && min.replace(/[^0-9]/g, '');
  const maxValue = max && max.replace(/[^0-9]/g, '');

  return [minValue, maxValue];
};

export const sendFBEvent = (eventName: string, data: unknown, custom?: boolean): void => {
  if (custom) {
    ReactPixel.trackCustom(eventName, data);
  } else {
    ReactPixel.track(eventName, data);
  }
};

export const acceptedFileTypes =
  // eslint-disable-next-line max-len
  'audio/aac, video/x-msvideo, image/bmp, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, audio/mpeg, video/mp4, video/mpeg, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, image/png, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, image/svg+xml, image/tiff, text/plain, audio/wav, audio/webm, video/webm, image/webp, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/xml, application/zip, application/x-7z-compressed, application/pkix-cert, application/pkcs8, application/x-pem-file, application/x-x509-ca-cert, video/quicktime, audio/mpeg3, video/x-mpeg';

export const isNotEmpty = (value: string): boolean => !!value.trim();

export const isPatternError = (type: string): boolean => {
  return type === 'pattern' ? true : false;
};

export const monthlyAvgIncomeOption = (
  minMonthlyIncome: number,
  maxMonthlyIncome: number,
): string => {
  const complement =
    +minMonthlyIncome === 1 || +minMonthlyIncome === 30000 ? `- ${maxMonthlyIncome}` : '';
  return `${minMonthlyIncome} ${complement}`.trim();
};

export const numberOfEmployeesOption = (
  minEmployeesNumber: number,
  maxEmployeesNumber: number,
): string => {
  const complement =
    minEmployeesNumber > 1 && maxEmployeesNumber < 51 ? `- ${maxEmployeesNumber}` : '';
  return `${minEmployeesNumber} ${complement}`.trim();
};

export const yearsOfOperatioOption = (
  minYearsOfOperation: number,
  maxYearsOfOperation: number,
): string => {
  const complement =
    minYearsOfOperation > 1 && minYearsOfOperation < 11 ? `- ${maxYearsOfOperation}` : '';
  return `${minYearsOfOperation} ${complement}`.trim();
};

const utils = {
  getStringFromMinMax,
  getMinMaxFromString,
  sendFBEvent,
  isNotEmpty,
  isPatternError,
};

export default utils;
