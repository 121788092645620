/* eslint-disable max-len */

export default {
  pf: { title: 'Ingresa tus datos' },
  pm: { title: 'Datos del representante legal' },
  info: 'A continuación, por favor ingresa tus datos personales.  Si estás registrando una Persona Moral,  se deben ingresar los datos del representante legal.',
  addressInfo: {
    pf: { title: 'Domicilio particular' },
    pm: { title: 'Domicilio particular del representante legal' },
    info: 'Ingresa tu dirección personal; deberá coincidir con tu identificación oficial o comprobante de domicilio.',
  },
};
