/* eslint-disable max-len */
export default {
  title: 'Ayuda',
  footer: 'Lunes a domingo de 8:00 a 20:00 hrs.',
  faq: {
    title: 'Consejos y respuestas',
    description: 'Conoce la información al instante',
    link: 'https://help.oyster.io/es/?utm_source=website&utm_medium=web&utm_campaign=footer&utm_content=help',
  },
  chat: {
    title: 'Chat Oyster',
    description: 'Habla con un agente',
  },
  whatsApp: {
    title: 'WhatsApp',
    description: 'Chatea con un agente',
    link: 'https://wa.me/525588969138',
  },
  call: {
    title: 'Llámanos',
    description: 'Queremos escucharte',
  },
};
