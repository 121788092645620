enum onboardingScreens {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  DISBURSEMENT_INFORMATION_V1 = 'DISBURSEMENT_INFORMATION_V1',
  PAYMENT_LINKS_SUCCESS_V1 = 'PAYMENT_LINKS_SUCCESS_V1',
  AGENT_ONBOARDING_V1 = 'AGENT_ONBOARDING_V1',
  PERSONAL_INFORMATION_V1 = 'PERSONAL_INFORMATION_V1',
  BUSINESS_INFORMATION_V1 = 'BUSINESS_INFORMATION_V1',
  PHONE_VERIFICATION_V1 = 'PHONE_VERIFICATION_V1',
  ONBOARDING_SUCCESS_V1 = 'ONBOARDING_SUCCESS_V1',
}

export default onboardingScreens;
