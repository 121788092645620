import { types } from 'mobx-state-tree';
import moment from 'moment';

export const momentDate = types.custom<string, moment.Moment>({
  name: 'MomentDate',
  fromSnapshot(value: string): moment.Moment {
    const parsedDate = moment(value);
    return parsedDate; // a normal JS Date object
  },
  toSnapshot(value: moment.Moment): string {
    return value.format('D/M/YYYY');
  },
  isTargetType(value) {
    return moment.isMoment(value);
  },
  getValidationMessage() {
    return 'Not a valid MomentDate';
  },
});
