//These are Base Error Code. Errors are defined more in detail with message.
export enum ErrorCode {
  UNKNOWN_ERROR = 0, //Just default value for enum in proto. Should not be used as error code.
  NPE = 4, //When any required input is null.
  ILLEGAL = 5, //Mostly used with validation check.
  DUPLICATE = 6, //For duplicate records.
  FAILED = 7, //When any action fails. Like while saving record on db
  NOTFOUND = 8, //When records are not found.
  INVALID = 9, //For invalid inputs.
  EXCEPTION = 10, //For any runtime exception
  TIMEOUT = 11, //For timeouts on calling any third party services
  AUTH_ERROR = 12, //If authorization failed
  PERMISSION_ERROR = 13, //If user don't have permission
  FORBIDDEN = 14, //If user don't have permission
}
export enum OnboardingState {
  UNKNOWN_ONBOARDING_STATE_V1 = 0,
  USER_ONBOARDED = 1,
  USER_EMAIL_CONFIRMED = 2,
  PHONE_NUMBER_ADDED = 5,
  PHONE_NUMBER_VERIFIED = 10,
  RFC_WITH_ADDRESS_ADDED = 15,
  BUSINESS_INFO_USING_RFC_ADDED = 16,
  HOME_ADDRESS_ADDED = 17,
  LEGAL_ADDRESS_ADDED = 18,
  PERSONAL_ADDRESS_ADDED = 19,
  USER_INFO_USING_CURP_ADDED = 20,
  USER_OCCUPATION_ADDED = 25,
  KYC_DOCUMENT_SUBMITTED = 28,
  KYC_LIVENESS_VIDEO_SUBMITTED = 29,
  KYC_CHECK_SUBMITTED = 30,
  AGREED_TERMS_CONDITIONS = 31,
  APPLICANT_VIDEO_SUBMITTED = 32,
  PROOF_OF_ADDRESS_DOC_SUBMITTED = 35,
  FISICA_FINANCIAL_OPERATION_QUESTIONNAIRE_SUBMITTED = 37,
  FISICA_PEP_QUESTIONNAIRE_SUBMITTED = 38,
  BENEFICIARY_INFO_SUBMITTED = 40,
  AGREED_CACAO_REGULATIONS_TERMS_CONDITIONS = 45,
  USER_SIGNATURE_SUBMITTED = 50,
  VERIFICATION_PIN_ADDED = 55,
  // given below states will only be managed during persona moral
  // flow after completing the main onboarding
  // this state or proof of address state may be maintained at any order.
  CONSTITUTIVE_ACT_DOC_SUBMITTED = 60,
  POWER_OF_ATTORNEY_DOC_SUBMITTED = 65,
  BUSINESS_DOCUMENT_SUBMISSION_COMPLETED = 67,
  BUSINESS_INFO_CONFIRMATION_USER_DECLARATIVE_SIGNATURE_SUBMITTED = 70,
  BUSINESS_PHONE_NUMBER_ADDED = 75,
  TAX_ADDRESS_ADDED = 80,
  BUSINESS_DECLARATION_ACCEPTED = 85,
  FINANCIAL_OPERATION_QUESTIONNAIRE_SUBMITTED = 90,
  STAKEHOLDER_INFO_ADDED = 95,
  PEP_QUESTIONNAIRE_SUBMITTED = 100,
  STAKEHOLDER_KYC_PROCESSING_EMAIL_SENT = 105,
  ACCOUNT_TERMS_ACCEPTANCE_SIGNATURE_SUBMITTED = 110,
  BUSINESS_VERIFICATION_PIN_ADDED = 115,
  STAKEHOLDER_VERIFICATION_INFO_SUBMITTED = 120,
}

export enum BusinessType {
  UNKNOWN_BUSINESS_TYPE = 0,
  PERSONA_FISICA = 1,
  PERSONA_MORALE = 2,
  PERSONA_MORAL = 2,
}

export enum VerificationStatus {
  UNKNOWN_VERIFICATION_STATUS = 0,
  VERIFIED = 3,
  FORMAT_VERIFIED = 4,
  KYC_CHECK_INITIATED = 14,
  VERIFICATION_PENDING = 15,
  EXTERNAL_SERVICE_VERIFIED = 16,
  EXTERNAL_SERVICE_REJECTED = 17,
  CHA_VERIFIED = 18,
  CHA_REJECTED = 19,
  KYC_INFO_CAUTIONED = 25,
  KYC_INFO_SUSPECTED = 26,
  KYC_PROCESS_DOCUMENT_SUBMISSION_SUCCESS = 27,
}

export enum AddressType {
  UNKNOWN_ADDRESS = 0,
  WORK = 1,
  HOME = 2,
  BILLING = 3,
  SHIPPING = 4,
  LEGAL = 5,
  TAX = 6,
  PERSONAL = 7,
}

export enum Gender {
  UNKNOWN_GENDER = 0,
  MALE = 1,
  FEMALE = 2,
}

export enum FinancialOperationQuestionnaireType {
  UNKNOWN_FINANCIAL_OPERATION_QUESTIONNAIRE_TYPE = 0,
  QUESTIONNAIRE_FINANCIAL_INSTITUTION_INFO = 1,
  QUESTIONNAIRE_DEPOSIT_AMOUNT_RANGE_INFO = 5,
  QUESTIONNAIRE_DEPOSIT_QUANTITY_RANGE_INFO = 10,
  QUESTIONNAIRE_DEPOSIT_FREQUENCY_INFO = 15,
  QUESTIONNAIRE_INCOME_SOURCE_INFO = 20,
  QUESTIONNAIRE_EXPENDITURE_NATURE_INFO = 25,
  QUESTIONNAIRE_ACCOUNT_OPERATION_TYPE_INFO = 30,
  QUESTIONNAIRE_ACCOUNT_OPERATION_NATURE_INFO = 35,
  QUESTIONNAIRE_DEPOSIT_AMOUNT_AND_QUANTITY_RANGE_AND_FREQUENCY_INFO = 40,
}

export enum PepQuestionnaireType {
  UNKNOWN_PEP_QUESTIONNAIRE_TYPE = 0,
  PEP_EXPOSED_PERSON = 1,
  RELATED_TO_PEP_EXPOSED_PERSON = 2,
  LINK_TO_PEP_EXPOSED_PERSON = 3,
}

export enum DocumentType {
  PROOF_OF_ADDRESS = 6,
  UNKNOWN_DOCUMENT = 0,
  PASSPORT = 1,
  NATIONAL_IDENTITY_CARD = 3,
  PHOTO = 4,
  ART_OF_INCORPORATION = 7,
  NOTARY_POWERS = 15,
  PROOF_OF_FIEL = 16,
  PUBLIC_REGISTRY_OF_PROPERTY_AND_COMMERCE_PROPERTY = 17,
  FISCAL_IDENTIFICATION = 18,
  EXTERNAL_ACCOUNT_STATEMENT = 25,
  VOTER_ID = 5,
}

export enum SessionType {
  UNKNOWN_SESSION_TYPE = 0,
  NEW_SESSION = 1,
  SUB_SESSION = 2,
}

export enum DocumentFileType {
  DOCUMENT = 1,
  IMAGE = 2,
}

export enum businessTypeNames {
  pm = 'pm',
  pf = 'pf',
}
