/* eslint-disable max-len */

export default {
  street: 'Calle',
  noExt: 'Número Exterior',
  noInt: 'Número Interior (opcional)',
  zipCode: 'C.P.',
  colony: 'Colonia',
  municipality: 'Municipio o Delegación',
  state: 'Estado',
  copyAddress: 'El domicilio fiscal es el mismo que el particular',
};
