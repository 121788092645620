import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translations from '.';

const i18nInit = (): void => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // if not using I18nextProvider
    .init({
      fallbackLng: 'es',
      debug: process.env.ENV !== 'production',
      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      // react i18next special options (optional)
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
      },
      resources: translations,
    });
}; // if not using I18nextProvider

export default i18nInit;
