import axios from 'axios';
import i18n from 'i18next';
import { applySnapshot, flow, getSnapshot, Instance, types } from 'mobx-state-tree';

import endpoints from '@configs/endpoints';
import { validOnboardingVersions } from '@configs/variables';
import { CountryCode } from '@constants/countryCodes';
import { BusinessType, Gender, VerificationStatus } from '@constants/enum';
import PEPQuestionaireStore from '@store/pepQuestions';
import { isRFCFisica, isRFCMoral } from '@utils/taxIdValidator';
import { isCurpValid } from '@utils/validators';
import Common from './common';
import UserDocument from './userDocument';
import { MoraleInfo } from './agentOnboardingDocs';

export const Coordinates = types
  .model({
    lat: '',
    lon: '',
  })
  .actions((self) => ({
    setLat: (lat: string) => (self.lat = lat),
    setLon: (lon: string) => (self.lon = lon),
  }));

export const Occupation = types
  .model({
    occupationId: '',
    occupation: '',
  })
  .actions((self) => ({
    updateOccupation: ({
      occupationId,
      occupation,
    }: {
      occupationId: string;
      occupation: string;
    }) => {
      self.occupationId = occupationId;
      self.occupation = occupation;
    },
  }));

export type TOccupation = Instance<typeof Occupation>;

const User = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      userId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      fullPhoneNumber: '',
      countryCode: 'MX',
      phoneValid: types.union(types.boolean, types.null),
      phoneSubmitting: false,
      country: '',
      gender: 1,
      status: '',
      profilePicUrl: '',
      kycApplicantId: '',
      dob: '',
      createdAt: '',
      updatedAt: '',
      defaultBusinessId: '',
      curp: '',
      curpStatus: '',
      agreeTermsConditions: false,
      occupation: '',
      customOccupation: '',
      occupations: types.array(Occupation),
      mobilePin: '',
      userRefId: '',
      birthState: '',
      birthCountry: 'MEX',
      isCurpValid: types.union(types.boolean, types.null),
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      onboardingCompleted: false,
      txnAction: '',
      taxId: '',
      taxIdStatus: '',
      isTaxIdValid: types.maybeNull(types.boolean),
      nationality: '',
      refUserType: '',
      onboardingState: 0,
      isLoading: false,
      isFetching: false,
      nameFetchedFromCurp: false,
      stateFetchedFromCurp: false,
      emailVerified: false,
      locAuthorized: false,
      settingLocData: false,
      locationData: Coordinates,
      clabe: '',
      submitting: false,
      curpDataFromBE: false,
      poaDocument: UserDocument,
      easDocument: UserDocument,
      passportDocument: UserDocument,
      voterIdFrontDocument: UserDocument,
      voterIdBackDocument: UserDocument,
      kycCompleted: false,
      pepQuestionaire: types.optional(PEPQuestionaireStore, {}),
      onboardingVersionName: '',
      signature: '',
      isLoadingGeolocation: false,
      moraleInfo: MoraleInfo,
    }),
  )
  .views((self) => ({
    get partialName() {
      return `${self.firstName} ${self.middleName}`.trim();
    },
    get fullName() {
      return `${self.firstName} ${self.middleName} ${self.lastName}`.trim();
    },
    get hasLocationData() {
      return Boolean(self.locationData.lat && self.locationData.lon);
    },
    get curpError(): boolean {
      return !!(self.curp.length > 0 ? !self.isCurpValid : false);
    },
    get phoneError(): boolean {
      return !!(typeof self.phoneValid === 'boolean' ? !self.phoneValid : false);
    },
    get errors() {
      return {
        firstName: !(self.firstName.length > 0 ? self.firstName.length >= 2 : true),
        middleName: !(self.middleName.length > 0 ? self.middleName.length >= 2 : true),
        lastName: !(self.lastName.length > 0 ? self.lastName.length >= 2 : true),
      };
    },
    get occupationList() {
      return getSnapshot(self.occupations);
    },
    get canSubmitPersonalInformation() {
      return !!(
        self.firstName.length >= 2 &&
        self.middleName.length >= 2 &&
        self.birthState.length >= 2 &&
        self.dob.length === 10 &&
        self.isCurpValid &&
        self.phoneValid
      );
    },
  }))
  .actions((self) => ({
    setCurp: (curp: string) => {
      self.curpDataFromBE = false;
      self.curp = curp.toUpperCase();
      self.isCurpValid = isCurpValid(curp);
    },
    setGender: (gender: string | number) => {
      self.gender = Gender[gender] || Gender.UNKNOWN_GENDER;
    },
    setTaxId: (taxId: string) => {
      self.taxId = taxId.toUpperCase();
      self.isTaxIdValid = isRFCFisica(taxId);
    },
  }))
  .actions((self) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update: (data: any) => {
      const { app } = self.globalStore;
      const { user, userOnboardingVersionInfo } = data;

      if (user.curp) {
        self.setCurp(user.curp);
        self.curpDataFromBE = true;
        self.isCurpValid = true;
      }

      self.email = user.email;
      self.firstName = user.firstName;
      self.middleName = user.middleName;
      self.lastName = user.lastName;
      self.userId = user.userId;
      self.userRefId = user.userRefId;
      self.kycApplicantId = user.kycApplicantId || '';
      self.emailVerified = !!user.emailVerified;
      self.birthState = user.birthState;
      user.taxId ? self.setTaxId(user.taxId) : (self.taxId = '');
      self.setGender(user.gender);
      if (user.dob) {
        self.dob = user.dob;
        const [year, month, day] = user.dob.split('-');
        self.birthYear = year;
        self.birthMonth = month;
        self.birthDay = day;
      }

      app.setConfig(userOnboardingVersionInfo);

      if (user.phone && user.phone.fullPhoneNumber) {
        self.phoneValid = true;
        self.phone = user.phone.fullPhoneNumber.substring(3);
        self.fullPhoneNumber = user.phone.fullPhoneNumber;
      }
      self.isFetched = user.isFetched || self.isFetched;
    },
  }))
  .actions((self) => {
    let initialState = {};

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        const email = self.email;
        applySnapshot(self, initialState);
        self.poaDocument.resetStore();
        self.email = email;
      },
    };
  })
  .actions((self) => ({
    savePersonalInformationData: flow(function* (bodyData = {}) {
      const { app } = self.globalStore;
      self.submitting = true;
      try {
        const { data } = yield axios.post(endpoints.onboardingData, bodyData);
        if (data.nextSection) {
          app.updateField('nextSection', data.nextSection);
        }

        app.setNextScreen(app.getNextScreen(app.nextScreen));
      } catch (error) {
        // do something?
      } finally {
        self.submitting = false;
      }
    }),
    getDetails: flow(function* () {
      const { auth } = self.globalStore;

      try {
        self.isFetching = true;
        const { data } = yield axios.get(endpoints.getUser);

        if (data.error) {
          auth.logout();
          return;
        }
        const { userOnboardingVersionInfo } = data;

        if (userOnboardingVersionInfo) {
          const onboardingVersionName = userOnboardingVersionInfo?.onboardingVersionName;
          self.onboardingVersionName = onboardingVersionName;
        }

        const hasValidOnboardingVersion = validOnboardingVersions.includes(
          self.onboardingVersionName,
        );

        if (!hasValidOnboardingVersion) {
          auth.logout();
          return;
        }

        if (data.success) {
          self.update(data);
          self.isFetched = true;
        }
      } catch (error) {
        auth.logout();
      } finally {
        self.isFetching = false;
      }
    }),
    validateCurp: flow(function* () {
      try {
        self.nameFetchedFromCurp = false;
        self.stateFetchedFromCurp = false;
        self.isLoading = true;
        const { data } = yield axios.post(endpoints.checkCurp, {
          curp: self.curp,
        });

        if (data.success) {
          const { user } = data;
          self.stateFetchedFromCurp = true;
          user.taxId ? self.setTaxId(user.taxId) : (self.taxId = '');

          if (user.firstName && user.middleName) {
            self.nameFetchedFromCurp = true;
          }
          return data;
        }
      } catch (error) {
        self.isCurpValid = false;
        return undefined;
      } finally {
        self.isLoading = false;
      }
    }),
    validatePhone: flow(function* (countryCode: CountryCode, phone = '') {
      self.phoneValid = null;
      self.phoneSubmitting = true;
      const formattedPhone = (phone || self.phone).replace(/ /g, '');
      const body = {
        phone: formattedPhone,
        countryCode: countryCode.countryCode,
        fullPhoneNumber: `${countryCode.isoCode}${formattedPhone}`,
      };

      try {
        const { data } = yield axios.post(endpoints.validatePhone, {
          validatePhone: { phone: [body] },
        });

        const [{ valid }] = data.isPhoneValid;
        self.fullPhoneNumber = body.fullPhoneNumber;
        self.phoneValid = valid;
      } catch (error) {
        self.phoneValid = false;
      } finally {
        self.phoneSubmitting = false;
      }
    }),
    getOccupations: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.getOccupation);

        data.occupations.forEach((opt: { occupationId: string; occupation: string }) => {
          self.occupations.push(
            Occupation.create({
              occupationId: opt.occupationId,
              occupation: opt.occupation,
            }),
          );
        });
      } catch (error) {
        // empty catch
      }
    }),
    onboardingProgress: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.onboardingProgress);

        const { app } = self.globalStore;

        app.setScreenProgress(data.progress, data.nextSection);
        return data;
      } catch (error) {
        //
      }
    }),
    updateOnboardingProgress: flow(function* (onboardingProgressData) {
      try {
        self.submitting = true;
        const { data } = yield axios.post(endpoints.onboardingProgress, onboardingProgressData);
        return data;
      } catch (error) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    getApplicantId: flow(function* () {
      try {
        self.isLoading = true;
        const { data } = yield axios.post(endpoints.getApplicantId, {});
        if (data.success) {
          self.kycApplicantId = data.kycApplicant.applicantId;
        }
        return data;
      } catch (err) {
        //
      } finally {
        self.isLoading = false;
      }
    }),
    completeKyc: flow(function* () {
      try {
        const { data } = yield axios.put(endpoints.updateKycApplicantStatus);
        if (data.success) {
          self.kycCompleted = true;
        }
      } catch (err) {
        //
      }
    }),
    checkKycStatus: flow(function* () {
      try {
        self.isLoading = true;
        const { data } = yield axios.get(endpoints.getKycApplicantState);
        if (data.success) {
          // Check if the user has already initiated kyc
          if (
            [
              VerificationStatus.EXTERNAL_SERVICE_REJECTED,
              VerificationStatus.EXTERNAL_SERVICE_VERIFIED,
              VerificationStatus.KYC_CHECK_INITIATED,
              VerificationStatus.VERIFICATION_PENDING,
              VerificationStatus.KYC_INFO_CAUTIONED,
              VerificationStatus.KYC_INFO_SUSPECTED,
              VerificationStatus.KYC_PROCESS_DOCUMENT_SUBMISSION_SUCCESS,
            ].includes(VerificationStatus[data.kycStatus] as unknown as number)
          ) {
            self.kycCompleted = true;
          }
        }
      } finally {
        self.isLoading = false;
      }
    }),
    sendLinkInSMS: flow(function* () {
      try {
        const { data } = yield axios.patch(endpoints.sendLinkInSMS, {});
        return data;
      } catch (err) {
        //
      }
    }),
    getGeolocation: () => {
      self.updateField('isLoadingGeolocation', true);

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          self.locationData.setLat(`${position.coords.latitude}`);
          self.locationData.setLon(`${position.coords.longitude}`);
          self.updateField('isLoadingGeolocation', false);
        });
        // If user not enable browser geolocation in 10 seconds we show message error
        setTimeout(() => {
          self.updateField('isLoadingGeolocation', false);
        }, 10000);
      } else {
        const { app } = self.globalStore;
        app.openToast({
          type: 'error',
          title: i18n.t('common.errorTitle'),
          message: i18n.t('termsConditionsScreen.geolocation.error.notSupported'),
          toastDuration: 4000,
        });
        self.updateField('isLoadingGeolocation', false);
      }
    },
    getGeolocationByIp: flow(function* () {
      try {
        const { data } = yield axios.get(endpoints.getGeolocationByIp);
        self.locationData.setLat(`${data?.latitude}`);
        self.locationData.setLon(`${data?.longitude}`);
      } catch (err) {
        //
      }
    }),
    getPartnerInfo: flow(function* () {
      try {
        self.isFetching = true;
        const { data } = yield axios.get(endpoints.partnerBusiness);
        if (data?.success) {
          const { business } = self.globalStore;
          const {
            taxId,
            clabe,
            businessName = business.businessName,
            legalName = business.razonSocial,
          } = data?.detail?.business;
          business.updateField('taxId', taxId);
          const businessType = isRFCMoral(taxId)
            ? BusinessType.PERSONA_MORAL
            : BusinessType.PERSONA_FISICA;
          business.updateField('businessType', businessType);
          business.disbursementDetails.updateField('clabe', clabe);
          businessName && business.updateField('businessName', businessName);
          legalName && business.updateField('razonSocial', legalName);
        }
        return data;
      } catch (err) {
        //
      } finally {
        self.isFetching = false;
      }
    }),
  }));

export type TUserStore = Instance<typeof User>;

export default User;
