import axios from 'axios';
import { flow, Instance, types } from 'mobx-state-tree';
import moment, { Moment } from 'moment';

import endpoints from '@configs/endpoints';
import { OTHER, RelationType } from '@constants/beneficiariesOptions';
import countryCodes, { CountryCode, countryCodeData } from '@constants/countryCodes';
import { momentDate } from '@utils/custom-types';
import Common from './common';

export const mxCountryCode: CountryCode = countryCodes[0];
const format = 'YYYY-MM-DD';

export const Phone = types
  .compose(
    Common,
    types.model({
      phone: '',
      countryCode: '',
      isoCode: '',
      mask: '',
      label2: '',
      validating: false,
      valid: false,
      error: false,
    }),
  )
  .views((self) => ({
    get fullLength(): number {
      return self.mask.length;
    },
    get rawLength(): number {
      return self.mask.replace(/\s/g, '').length;
    },
    get fullPhoneNumber(): string {
      return `${self.isoCode}${self.phone}`;
    },
  }))
  .views((self) => ({
    get canSubmit(): boolean {
      return !!(
        self.phone.replace(/\s/g, '').length === self.rawLength &&
        !self.error &&
        self.valid &&
        !self.validating
      );
    },
  }))
  .actions((self) => ({
    validatePhoneNumber: flow(function* () {
      try {
        const phone = self.phone;
        if (self.fullLength === phone.length) {
          self.valid = false;
          self.validating = true;
          self.error = false;
          const { data } = yield axios.post(endpoints.validatePhone, {
            validatePhone: {
              phone: [
                {
                  phone: phone,
                  countryCode: self.countryCode,
                  fullPhoneNumber: self.fullPhoneNumber,
                },
              ],
            },
          });

          const [{ valid }] = data.isPhoneValid;
          self.valid = !!valid;
          self.error = !valid;
        }
      } catch (error) {
        self.valid = false;
        self.error = true;
      } finally {
        self.validating = false;
      }
    }),
  }));

export const Beneficiary = types
  .compose(
    Common,
    types.model({
      isValid: false,
      relation: types.union(
        types.literal('Esposo/a'),
        types.literal('Padre/Madre'),
        types.literal('Abuelo/a'),
        types.literal('Hermano/a'),
        types.literal('Otro/a'),
        types.literal(''),
      ),
      label: '',
      otherRelation: '',
      firstName: '',
      lastName: '',
      secondLastName: '',
      dateOfBirth: types.maybeNull(momentDate),
      birthdate: '',
      fullAddress: '',
      firstNameError: '',
      inheritancePercent: 0,
      fullAddressError: false,
      isinheritancePercentSet: false,
      phone: Phone,
    }),
  )
  .views((self) => ({
    get fullName(): string {
      return `${self.firstName} ${self.lastName} ${self.secondLastName}`;
    },
    get canSubmit() {
      return !!(
        self.firstName &&
        self.firstName.length >= 2 &&
        self.lastName &&
        self.lastName.length >= 2 &&
        self.dateOfBirth &&
        self.phone.canSubmit &&
        (self.relation === OTHER ? self.otherRelation.length >= 2 : !!self.relation) &&
        self.fullAddress
      );
    },
    get validationErrors() {
      return {
        otherRelation: !!(self.otherRelation && self.otherRelation.length < 2),
        firstName: !!(self.firstName && self.firstName.length < 2),
        lastName: !!(self.lastName && self.lastName.length < 2),
        secondLastName: !!(self.secondLastName && self.secondLastName.length < 2),
        fullAddress: !!(self.fullAddress && self.fullAddress.length < 2),
      };
    },
  }))
  .actions((self) => ({
    updatePhone: (value: string) => {
      self.phone.error = false;
      self.phone.updateField('phone', value.replace(/ /g, ''));
    },
    updateRelation: (value: RelationType) => {
      self.otherRelation = '';
      self.relation = value;
    },
    setPhoneData: (countryData?: CountryCode) => {
      self.phone.updateField('mask', countryData?.mask);
      self.phone.updateField('phone', '');
      self.phone.updateField('isoCode', countryData?.isoCode);
      self.phone.updateField('countryCode', countryData?.countryCode);
      self.phone.updateField('label2', countryData?.countryCode);
    },
    setBirthDate: (date: Moment | null) => {
      self.dateOfBirth = date;
      self.birthdate = moment(date).format(format);
    },
  }));

export type BeneficiaryType = Instance<typeof Beneficiary>;

const Beneficiaries = types
  .compose(
    Common,
    types.model({
      selectedBeneficiary: -1,
      submitting: false,
      success: false,
      error: false,
      all: types.optional(types.array(Beneficiary), []),
    }),
  )
  .views((self) => ({
    get totalPercentage() {
      return self.all.reduce((accumulator: number, beneficiary: BeneficiaryType) => {
        return beneficiary.inheritancePercent + accumulator;
      }, 0);
    },
  }))
  .views((self) => ({
    get beneficiaryCount() {
      return self.all.length || 0;
    },
    get percentageError() {
      return self.all.length > 0 ? self.totalPercentage !== 100 : false;
    },
  }))
  .views((self) => ({
    get canSubmit(): boolean {
      return (
        self.all.every((beneficiary: BeneficiaryType) => beneficiary.canSubmit) &&
        self.all.length > 0 &&
        !self.percentageError &&
        !self.submitting
      );
    },
  }))
  .actions((self) => ({
    startSubmit: () => {
      self.submitting = true;
      self.success = false;
      self.error = false;
    },
    removeAllBeneficiaries: () => {
      self.update({
        all: [],
      });
    },
  }))
  .actions((self) => ({
    toggleBeneficiary: (idx: number) => (self.selectedBeneficiary = idx),
    addBeneficiary: () => {
      if (self.beneficiaryCount < 3) {
        self.all.push(
          Beneficiary.create({
            phone: Phone.create({}),
            relation: '',
          }),
        );
        const selectedBeneficiary = self.beneficiaryCount - 1;
        self.all[selectedBeneficiary].setPhoneData(mxCountryCode);

        if (self.beneficiaryCount === 3) {
          self.all[0].inheritancePercent = 34;
          self.all[1].inheritancePercent = 33;
          self.all[2].inheritancePercent = 33;
        } else if (self.beneficiaryCount > 0) {
          self.all.forEach((shareholder) => {
            shareholder.inheritancePercent = Math.floor(100 / self.beneficiaryCount);
          });
        }
        self.selectedBeneficiary = selectedBeneficiary;
      }
    },
    removeBeneficiary: () => {
      if (self.beneficiaryCount > 1) {
        self.all.pop();

        self.all.forEach((shareholder) => {
          shareholder.inheritancePercent = Math.floor(100 / self.beneficiaryCount);
        });
      }
    },
    getBeneficiaries: flow(function* () {
      self.removeAllBeneficiaries();
      self.startSubmit();
      try {
        const { data } = yield axios.get(endpoints.saveBeneficiaries);
        const { beneficiaries: all } = data;

        all.forEach((benef: BeneficiaryType, i: number) => {
          self.all.push(
            Beneficiary.create({
              phone: Phone.create({}),
              relation: '',
            }),
          );
          self.all[self.beneficiaryCount - 1].setPhoneData(mxCountryCode);

          if (benef.firstName) {
            self.all[i].firstName = benef.firstName;
            self.all[i].lastName = benef.lastName;
            self.all[i].secondLastName = benef.secondLastName;
          } else {
            self.all[i].firstName = benef.fullName;
          }
          self.all[i].dateOfBirth = moment(benef.birthdate);
          self.all[i].birthdate = benef.birthdate;
          self.all[i].fullAddress = benef.fullAddress;

          const countryData: CountryCode =
            countryCodeData(benef.phone.countryCode) || mxCountryCode;
          self.all[i].setPhoneData(countryData);
          self.all[i].phone.updateField('phone', benef.phone.phone);
          self.all[i].inheritancePercent = benef.inheritancePercent;
          self.all[i].relation = benef.relation;
        });

        self.success = true;
      } catch (error) {
        self.error = true;
      } finally {
        self.submitting = false;
      }
    }),
    saveBeneficiaries: flow(function* () {
      try {
        self.startSubmit();
        const {
          app,
          business: { businessId },
        } = self.globalStore;

        const beneficiaries = self.all.map((beneficiary) => ({
          firstName: beneficiary.firstName.trim(),
          lastName: beneficiary.lastName.trim(),
          secondLastName: beneficiary.secondLastName.trim(),
          birthdate: beneficiary.birthdate,
          fullAddress: beneficiary.fullAddress.trim(),
          phone: {
            countryCode: beneficiary.phone.countryCode,
            phone: beneficiary.phone.phone.trim().replace(/\s/g, ''),
          },
          inheritancePercent: beneficiary.inheritancePercent,
          businessId,
          relation: beneficiary.otherRelation ? beneficiary.otherRelation : beneficiary.relation,
        }));
        const currentScreen = app.nextScreen;
        const nextScreen = app.getNextScreen(currentScreen);

        const { data } = yield axios.post(endpoints.onboardingData, {
          currentScreen,
          nextScreen,
          beneficiaries,
        });
        if (data.nextSection) {
          app.updateField('nextSection', data.nextSection);
        }

        if (data?.validationMessage) {
          data?.validationMessage?.forEach(
            (error: { field: string; message: string }, i: number) => {
              if (error.field === 'phone') {
                const message = JSON.parse(error.message);
                if (message.error === 'Número de teléfono inválido') {
                  self.all[i].phone.updateField('error', true);
                }
              }
            },
          );
          return;
        }

        self.success = true;
        app.setNextScreen(nextScreen);
        return data;
      } catch (error) {
        self.error = true;
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TBeneficiariesStore = Instance<typeof Beneficiaries>;

export default Beneficiaries;
