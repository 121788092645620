/* eslint-disable max-len */

export default {
  creditOffer: 'Solicitud de crédito',
  debitActivation: 'Cuenta de débito',
  title: 'Documentos personales',
  info: 'Para continuar con la contratación de tu línea de crédito, envía los siguientes documentos del titular de la cuenta',
  step1: '1. Verificación de identidad',
  step2: '2. Comprobante de domicilio',
  indentityInfo:
    'Para verificar tu identidad graba un video corto siguiendo las instrucciones, luego envía una identificación oficial, puede ser INE o Pasaporte; asegúrate de que esté vigente y que sea legible (en formato JPG o PNG).',
  startIdentityVerif: 'Iniciar Verificación de identidad',
  poaInfo:
    'No mayor a tres meses y que contenga tu dirección fiscal con el nombre del contribuyente',
  kycCompleteText: 'Completado',
};
