/* eslint-disable indent */
import axios from 'axios';
import { values } from 'mobx';
import { flow, Instance, types } from 'mobx-state-tree';
import moment from 'moment';

import endpoints from '@configs/endpoints';
import { PepQuestionnaireType } from '@constants/enum';
import { momentDate } from '@utils/custom-types';
import Common from './common';

export const PEPQuestionaire = types
  .compose(
    Common,
    types.model({
      pepQuestionnaireId: '',
      type: PepQuestionnaireType.UNKNOWN_PEP_QUESTIONNAIRE_TYPE,
      personName: '',
      publicOfficeName: '',
      startPeriod: types.maybeNull(momentDate),
      endPeriod: types.maybeNull(momentDate),
      isPresent: false,
    }),
  )
  .actions(() => ({
    getDate: (date: moment.Moment, month: number, year: number) => {
      if (date) return date;
      if (year && month) {
        return moment(`1/${month}/${year}`, 'D/M/YYYY');
      }
      return null;
    },
  }))
  .actions((self) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update: (data: any) => {
      self.pepQuestionnaireId = data.pepQuestionnaireId || self.pepQuestionnaireId;
      self.personName = data.personName || self.personName;
      self.type = PepQuestionnaireType[data.type] as unknown as number;
      self.publicOfficeName = data.publicOfficeName || self.publicOfficeName;
      self.startPeriod = self.getDate(data.startPeriod, data.startMonth, data.startYear);
      self.endPeriod = self.getDate(data.endPeriod, data.endMonth, data.endYear);
      self.isPresent = !!data.isPresent;
    },
  }));

export type PEPQuestionaireKey =
  | 'pepQuestionnaireId'
  | 'type'
  | 'personName'
  | 'publicOfficeName'
  | 'startPeriod'
  | 'endPeriod'
  | 'isPresent';

export type PepFields = 'pepExposed' | 'pepRelation' | 'pepLink';

const PEPQuestionaireStore = types
  .compose(
    Common,
    types.model({
      questionaires: types.array(PEPQuestionaire),
      tempQuestionaire: types.maybeNull(PEPQuestionaire),
      pepExposed: types.maybeNull(types.boolean),
      pepRelation: types.maybeNull(types.boolean),
      pepLink: types.maybeNull(types.boolean),
      isLoading: false,
    }),
  )
  .views((self) => ({
    get canSubmit() {
      const fields: PEPQuestionaireKey[] = ['publicOfficeName', 'startPeriod', 'endPeriod'];
      if (!self.tempQuestionaire) {
        return true;
      }
      if (self.tempQuestionaire?.type !== PepQuestionnaireType.PEP_EXPOSED_PERSON) {
        fields.push('personName');
      }
      return fields.every((field) => {
        return self.tempQuestionaire && self.tempQuestionaire[field];
      });
    },
  }))
  .actions((self) => ({
    createQuestionaire: (type: PepQuestionnaireType) => {
      self.tempQuestionaire = PEPQuestionaire.create({});
      self.tempQuestionaire.type = type;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updatePepQuestionaire: (data: any) => {
      self.questionaires.clear();
      if (data.pepQuestionnaires) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data.pepQuestionnaires || []).map((pep: any) => {
          const newPEP = PEPQuestionaire.create({});
          newPEP.update(pep);
          self.questionaires.push(newPEP);
        });
      }
    },
  }))
  .actions((self) => ({
    getQuestionaire: flow(function* (
      questionaireType: PepQuestionnaireType,
      shareholderId?: string,
    ) {
      try {
        self.isLoading = true;
        const { data } = yield axios.get(
          shareholderId
            ? endpoints.getStakeholderPepQuestionaire(shareholderId, questionaireType)
            : endpoints.getPepQuestionaire(questionaireType),
        );
        self.updatePepQuestionaire(data);
        return data.pepQuestionnaires && !!data.pepQuestionnaires.length;
      } catch (error) {
        console.warn(error);
      } finally {
        self.isLoading = false;
      }
    }),

    addQuestionaire: (type: PepQuestionnaireType) => {
      if (self.tempQuestionaire) {
        // assign temporary id before updating to backend
        const tempId = self.questionaires.length + 1;
        self.tempQuestionaire.pepQuestionnaireId = `temp-${tempId}`;
        self.questionaires.push(
          PEPQuestionaire.create({
            pepQuestionnaireId: self.tempQuestionaire.pepQuestionnaireId,
            type: self.tempQuestionaire.type,
            personName: self.tempQuestionaire.personName,
            publicOfficeName: self.tempQuestionaire.publicOfficeName,
            startPeriod: self.tempQuestionaire.startPeriod,
            endPeriod: self.tempQuestionaire.endPeriod,
            isPresent: self.tempQuestionaire.isPresent,
          }),
        );
      }
      self.createQuestionaire(type);
    },
    submitQuestionaire: flow(function* (type: number, stakeholderId?: string) {
      if (self.tempQuestionaire) {
        self.questionaires.push(
          PEPQuestionaire.create({
            pepQuestionnaireId: self.tempQuestionaire.pepQuestionnaireId,
            type: self.tempQuestionaire.type,
            personName: self.tempQuestionaire.personName,
            publicOfficeName: self.tempQuestionaire.publicOfficeName,
            startPeriod: self.tempQuestionaire.startPeriod,
            endPeriod: self.tempQuestionaire.endPeriod,
            isPresent: self.tempQuestionaire.isPresent,
          }),
        );
        self.tempQuestionaire = null;
      }
      try {
        self.isLoading = true;
        const { data } = yield axios.post(
          stakeholderId
            ? endpoints.updateStakeholderPepQuestionaire
            : endpoints.updatePepQuestionaire,
          {
            stakeholderId,
            pepQuestionnaireType: type,
            pepQuestionnaires: values(self.questionaires)
              .filter((q) => q.type === type)
              .map((question) => ({
                // pepQuestionnaireId: question.pepQuestionnaireId,
                //[Note] questionaireId not needed on creation
                personName: question.personName,
                publicOfficeName: question.publicOfficeName,
                // add 1 month to match backend requirement as moment starts month from 0
                startMonth: question.startPeriod ? moment(question.startPeriod).month() + 1 : null,
                startYear: question.startPeriod ? moment(question.startPeriod).year() : null,
                isPresent: question.isPresent,
                endMonth: question.endPeriod ? moment(question.endPeriod).month() + 1 : null,
                endYear: question.endPeriod ? moment(question.endPeriod).year() : null,
              })),
          },
        );
        self.updatePepQuestionaire(data);
      } catch (error) {
        console.warn(error);
      } finally {
        self.isLoading = false;
      }
    }),
    removePEPQuestionaire: flow(function* (
      type: number,
      pepQuestionnaire: Instance<typeof PEPQuestionaire>,
      stakeholderId?: string,
    ) {
      try {
        // check temporary questionaireid to figure out if api call is need
        if (!pepQuestionnaire.pepQuestionnaireId.includes('temp-')) {
          yield axios.delete(
            stakeholderId
              ? endpoints.removeStakeholderPepQuestionaire
              : endpoints.removePepQuestionaire,
            {
              data: {
                stakeholderId,
                pepQuestionnaireId: pepQuestionnaire?.pepQuestionnaireId,
                type,
              },
            },
          );
        }
        if (pepQuestionnaire?.pepQuestionnaireId) {
          self.questionaires.remove(pepQuestionnaire);
        }
      } catch (error) {
        console.warn(error);
      }
    }),
    removeAllPEPQuestionaire: flow(function* (type: number, stakeholderId?: string) {
      try {
        self.isLoading = true;
        yield axios.delete(
          stakeholderId
            ? endpoints.removeStakeholderPepQuestionaire
            : endpoints.removePepQuestionaire,
          {
            data: {
              type,
              stakeholderId,
            },
          },
        );
      } catch (error) {
        console.warn(error);
      } finally {
        self.isLoading = false;
      }
    }),
    unsetTempQuestionaire: () => {
      self.tempQuestionaire = null;
    },
  }));

export type TPEPQuestionaireStore = Instance<typeof PEPQuestionaireStore>;

export default PEPQuestionaireStore;
