import loadable from '@loadable/component';
import Loader from '@components/loader';

const PartnerDashboard = loadable(
  () =>
    import(
      /* webpackChunkName: "PartnerDashboard" */
      './PartnerDashboard'
    ),
  {
    fallback: <Loader />,
  },
);

export default PartnerDashboard;
